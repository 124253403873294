import { Snackbar, SnackbarContent, Theme } from '@mui/material';
import React from 'react';
// import theme from '../../theme';
import { makeStyles } from 'tss-react/mui';

interface NotificationProps {
  message?: string;
  open: boolean;
  onClose?: () => void;
  variant: Variant;
}

type Variant = 'primary' | 'secondary' | 'error';

const getVariant = (variant: Variant, theme: Theme) => {
  switch (variant) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.green.main;
    case 'error':
      return theme.palette.secondary.main;
    default:
      return theme.palette.primary.main;
  }
};

const useStylesContent = makeStyles<NotificationProps>()(
  (theme, { variant }) => ({
    root: {
      backgroundColor: 'white',
      color: getVariant(variant, theme),
      borderRadius: '24px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: getVariant(variant, theme),
      fontWeight: 500,
      padding: '6px 20px',
      zIndex: 10000,
    },
  })
);

const Notification = (props: NotificationProps): React.ReactElement => {
  const { open, message, onClose } = props;

  const { classes: contentClasses } = useStylesContent(props);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      key={message}
      autoHideDuration={5000}
    >
      <SnackbarContent message={message} classes={contentClasses} />
    </Snackbar>
  );
};

export default Notification;
