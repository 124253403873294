import { getComparator, stableSort } from './sort';
import { Booking } from '../store/activity/types';
import { TZ } from './date';
import { utcToZonedTime } from 'date-fns-tz';

/**
 * Takes the last of the list to get the closest upcoming booking
 */
export const getNextBooking = (bookings: Booking[] | null): Booking | null => {
  const sortedBookings = getUpcomingBookings(bookings);

  return sortedBookings && sortedBookings.length > 0
    ? sortedBookings[sortedBookings.length - 1]
    : null;
};

/**
 * Filters old bookings
 * Sorts bookings by seconds in descending order
 */
export const getUpcomingBookings = (
  bookings: Booking[] | null
): Booking[] | null => {
  if (bookings && bookings.length > 0) {
    const comparator = getComparator('desc', 'AlkuaikaSeconds');
    const upcomingBookings = bookings
      .map((v) => ({
        ...v,
        AlkuaikaSeconds: v.Alkuaika?.seconds || 0,
        Kesto: (v.Alkuaika?.seconds || 0) - (v.Loppuaika?.seconds || 0),
      }))
      .filter(
        (v) =>
          v.AlkuaikaSeconds + v.Kesto >
          utcToZonedTime(Date.now(), TZ).getTime() / 1000
      );

    const sortedBookings = stableSort(upcomingBookings, comparator);
    return sortedBookings.map((v) => {
      const { ...rest } = v as Booking & {
        AlkuaikaSeconds: number;
        Kesto: number;
      };
      return rest as Booking;
    });
  }
  return null;
};
