import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  'mui-drawer-paper': {
    backgroundColor: theme.palette.secondary.main,
  },
  'menu-container': {
    display: 'flex',
    overflowY: 'auto',
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    width: '354px',
    visibility: 'hidden',
    transition: theme.transitions.create('visibility', {
      easing: theme.transitions.easing.sharp,
      delay: theme.transitions.duration.leavingScreen,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.nav-open': {
      visibility: 'visible',
      transition: theme.transitions.create('visibility', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  'drawer-ul': {
    paddingLeft: '0px',
    '& ul': {
      paddingLeft: '0px',
    },
    '& li': {
      listStyleType: 'none',
      textDecoration: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      '& span': {
        paddingLeft: '32px',
        paddingRight: '16px',
      },
    },
    '& li.selected': {},
  },
  button: {
    color: '#000450',
    border: '1px solid #000450',
    borderRadius: '23px',
  },
  top: {
    borderBottom: `1px solid ${theme.palette.secondary.dark} `,
    paddingBottom: '32px',
  },
  footer: {
    marginTop: 'auto',
    paddingLeft: '32px',
    paddingBottom: '48px',
  },
  omat_tiedot: {
    cursor: 'pointer',
  },
  signout: {
    fontFamily: 'Scto Grotesk A',
    fontSize: '16px',
    display: 'flex',
    '& img': {
      marginRight: '8px',
    },
    marginLeft: '32px',
  },
}));

export default useStyles;
