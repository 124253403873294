import React, { forwardRef } from 'react';
import Input, { InputProps } from '@mui/material/Input';

import useStyles from './styles';

const TextArea = forwardRef((props: InputProps, ref): React.ReactElement => {
  const { color } = props;
  const { classes } = useStyles(color);
  return (
    <Input
      multiline={true}
      rows={8}
      disableUnderline={true}
      classes={classes}
      {...props}
      ref={ref}
    />
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
