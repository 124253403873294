import React from 'react';
import Spinner from '../LoadingIndicator';

import useStyles from './styles';

/**
 * Mask your component with a loading spinner
 * Parent component needs to have css position: 'relative'
 */
const LoadingOverlay = (): React.ReactElement => {
  const { classes } = useStyles();
  return (
    <div className={classes['overlay']}>
      <div className="background" />
      <div className="content">
        <Spinner variant="medium" />
      </div>
    </div>
  );
};

export default LoadingOverlay;
