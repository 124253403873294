import firebase from 'firebase/app';
import { DataSource, DataSourceUpdateType } from '../dataSource';
import { store } from '../../store';
import { setOffices } from '../../store/content/actions';
import { setContentOfficesLoaded } from '../../store/loaded/actions';
import { DataSubscription } from '../dataSubscription';
import { Office } from '../../types/abc';

export class OfficesDataSource extends DataSource {
  name: DataSourceUpdateType = 'offices';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const doc = db.collection('bookings').doc('offices');

    const unsubscribe = doc.onSnapshot(
      async (snapshot) => {
        if (!snapshot.exists) return;

        console.log('Received offices snapshot');
        const document = snapshot.data() as { offices: Office[] };

        store.dispatch(setOffices(document.offices));
        store.dispatch(setContentOfficesLoaded(true));
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return new DataSubscription(this.name, unsubscribe);
  }
}
