import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { RootState } from '../../store';
import ActivityFeed from '../../components/ActivityFeed';
import useStyles from './styles';
import { setActivityFeed } from '../../store/ui/actions';

const Feed = (): React.ReactElement => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const feedRef = React.useRef<HTMLDivElement>(null);
  const isActivityFeedOpen = useSelector(
    (state: RootState) => state.ui.isActivityFeedOpen
  );
  const isNavOpen = useSelector(
    (state: RootState) => state.ui.isNavigationOpen
  );
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUser);

  const handleClickAway = () => {
    const feedWidth = feedRef.current?.clientWidth;
    feedWidth &&
      feedWidth > 0 &&
      isActivityFeedOpen &&
      dispatch(setActivityFeed(false));
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={cx(classes.activity)}>
        <div
          ref={feedRef}
          className={cx(classes['activity-feed'], {
            'feed-open': isActivityFeedOpen && !isNavOpen,
            'nav-open': isNavOpen,
            'viewed-user': !!viewedUser,
          })}
        >
          <ActivityFeed showHeader={false} />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Feed;
