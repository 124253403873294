import firebase from 'firebase/app';
import { DataSource, DataSourceUpdateType } from '../dataSource';
import { store } from '../../store';
import { setBookings } from '../../store/activity/actions';
import { DataSubscription } from '../dataSubscription';
import { Booking } from '../../store/activity/types';
import { FSAdvisor } from '../../types/firestore';
import { checkIsRegistered } from '../../utils/firebase';

export class UserBookingsDataSource extends DataSource {
  name: DataSourceUpdateType = 'user-bookings';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const doc = db.collection('userBookings').doc(uid);
    const fsUser = store.getState().user.fsUser;
    const isRegistered = checkIsRegistered(fsUser);

    this.getIdToken().then((idToken) => {
      if (idToken && isRegistered) {
        this.requestUpdate({ target: 'user-bookings', uid }, idToken);
      }
    });

    const unsubscribe = doc.onSnapshot(
      async (snapshot) => {
        if (!snapshot.exists) return;

        console.log('Received bookings snapshot');
        const document = snapshot.data() as { bookings: Booking[] };

        // Get unique advisor ids
        const uniqueAdvisorIds = document.bookings
          .map((booking) => booking.NeuvonantajaID)
          .filter((x, i, a) => a.indexOf(x) === i);

        // Fetch advisors for those ids
        const advisors = (
          await Promise.all(
            uniqueAdvisorIds.map(async (id) => {
              const advisorDoc = await db
                .collection('advisors')
                .doc(id.toString())
                .get();
              return advisorDoc.data() as FSAdvisor | undefined;
            })
          )
        ).reduce(
          (prev, curr) => {
            if (curr !== undefined) {
              prev[curr.NeuvonantajaID.toString()] = curr;
            }
            return prev;
          },
          {} as Record<string, FSAdvisor>
        );

        // Set advisors to the bookings
        const bookings = document.bookings.map((booking) => ({
          ...booking,
          Neuvonantaja: advisors[booking.NeuvonantajaID],
        }));

        store.dispatch(setBookings(bookings));
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return new DataSubscription(this.name, unsubscribe);
  }
}
