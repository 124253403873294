import { Reducer } from '@reduxjs/toolkit';
import { State, ActionTypes as actions, Actions } from './types';

const INITIAL_STATE: State = {
  abcReports: [],
  utmostReports: [],
};

export const reportsReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_ABC_REPORTS:
      return {
        ...state,
        abcReports: action.payload,
      };
    case actions.SET_UTMOST_REPORTS:
      return {
        ...state,
        utmostReports: action.payload,
      };
    case 'LOGOUT':
    case 'CHANGE_USER':
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
