import React from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../store';

import Feed from '../Feed';

import LeftNav from '../LeftNav';
import Top from '../Top';

import useStyles from './styles';

const Main = (props: { children?: JSX.Element }): React.ReactElement => {
  const { classes, cx } = useStyles();
  const isNavigationOpen = useSelector(
    (state: RootState) => state.ui.isNavigationOpen
  );

  return (
    <div className={cx(classes.root, { 'nav-open': isNavigationOpen })}>
      <LeftNav />
      <Top />
      {props.children}
      <Feed />
    </div>
  );
};
export default Main;
