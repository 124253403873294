import type { Purchase } from '../types/abc';

export type ProductCategory = {
  id: number;
  code: string;
  name: string;
  parent: number;
  survey: boolean;
  surveyCategory: number;
  surveyName: string;
  childs: Record<string, ProductCategory>;
};

export const WAITING_FOR_PAYMENT = 'signed';

export type CommissionState =
  | 'created'
  | 'signed'
  | 'paid'
  | 'ready'
  | 'cancelled';

export type Comission = Purchase & {
  state: CommissionState;
  product: {
    imgUrl: string;
  };
};
