import React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from 'react-router-dom';

import useStyles from './styles';
import { Tooltip, Typography, useTheme } from '@mui/material';

interface INavItem {
  selected: boolean;
  selectedInPath?: boolean;
  open?: boolean;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onIconClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  label: string;
  drawer?: boolean;
  submenu?: boolean;
  icon?: React.ReactElement;
  stickToRight?: boolean;
  disabled?: string;
}

const NavItem = (props: INavItem): React.ReactElement => {
  const { classes, cx } = useStyles();
  const {
    label,
    to,
    onClick,
    onIconClick,
    selected,
    selectedInPath,
    open,
    drawer,
    submenu,
    icon,
    stickToRight,
    disabled,
  } = props;

  const theme = useTheme();

  return (
    <ListItem
      className={cx(
        classes['nav-item'],
        drawer && 'drawer',
        selected && 'selected',
        selectedInPath && 'selected-path',
        open && 'open',
        submenu && 'submenu',
        stickToRight && 'stick-to-right'
      )}
      disabled={!!disabled}
    >
      <span>
        {disabled ? (
          <Tooltip title={disabled}>
            <Typography
              sx={{ color: theme.palette.grey[600], cursor: 'pointer' }}
            >
              {label}
            </Typography>
          </Tooltip>
        ) : (
          <Link to={to} onClick={onClick}>
            {label}
          </Link>
        )}

        {icon && (
          <ListItemIcon className={'nav-icon'} onClick={onIconClick}>
            {props.icon}
          </ListItemIcon>
        )}
      </span>
    </ListItem>
  );
};

export default NavItem;
