import React from 'react';
import { Fade, Backdrop, Modal as MuiModal } from '@mui/material';

const fadeTime = 500;
const Modal = (props: {
  open: boolean;
  onClose: () => void;
  children: React.ReactElement;
}) => {
  const { open, onClose, children } = props;

  const getBackDrop = () => <Backdrop timeout={fadeTime} open={open} />;

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: getBackDrop }}
    >
      <Fade in={open}>{children}</Fade>
    </MuiModal>
  );
};

export default Modal;
