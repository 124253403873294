import React, { useState, Children, ReactElement, useEffect } from 'react';
import MuiSelect, {
  SelectChangeEvent,
  SelectProps,
} from '@mui/material/Select';
import { MenuItem } from '@mui/material';

import { useStylesSelect, useStylesMenu, useStylesMenuList } from './styles';

import { ReactComponent as DownIcon } from '../../images/svg/down_small.svg';

type CustomSelectProps = SelectProps<string[] | string> & {
  /** This is called with the updated values when they update */
  onUpdate?(values: [] | string): void;
  multiple?: boolean;
  children: React.ReactNode;
  placeholder: string;
  value: string[] | string;
};

const Select = ({
  children,
  multiple,
  onUpdate,
  placeholder,
  value,
  ...otherProps
}: CustomSelectProps): React.ReactElement => {
  const {
    classes: { root, select, icon },
  } = useStylesSelect();
  const {
    classes: { paper, list },
  } = useStylesMenu();
  const {
    classes: { root: menuListroot },
  } = useStylesMenuList();
  const [selectedItems, setSelectedItems] = useState<string[]>(
    (value as string[]) ?? []
  );
  const [selectedItem, setSelectedItem] = useState<string>(
    (value as string) ?? ''
  );

  useEffect(() => {
    if (multiple) setSelectedItems(value as string[]);
    else setSelectedItem(value as string);
  }, [value]);

  const handleChange = (event: SelectChangeEvent<string[] | string>) => {
    if (multiple) {
      setSelectedItems(event.target.value as unknown as []);
      onUpdate?.(event.target.value as unknown as []);
    } else {
      setSelectedItem(String(event.target.value));
      onUpdate?.(String(event.target.value));
    }
  };

  return (
    <MuiSelect<string[] | string>
      classes={{
        root,
        select,
        icon,
      }}
      disableUnderline={true}
      displayEmpty
      IconComponent={(iconProps) => <DownIcon {...iconProps} />}
      MenuProps={{
        classes: { paper, list },
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {
          classes: {
            root: menuListroot,
          },
        },
      }}
      multiple={multiple}
      onChange={handleChange}
      renderValue={(selected) => {
        if (!multiple && !selected) {
          return placeholder;
        }
        if (!multiple) {
          const _children = Children.map(children, (child) => {
            const childElement = child as ReactElement;
            return {
              name: childElement.props.children,
              value: childElement.props.value,
            };
          });
          const correctChild = _children?.find((x) => x.value === selected);
          return String(correctChild?.name);
        }
        if ((selected as unknown as [])?.length === 0) {
          return placeholder;
        }

        return `${(selected as unknown as [])?.length} valittu`;
      }}
      value={multiple ? selectedItems : selectedItem}
      {...otherProps}
    >
      <MenuItem disabled value="">
        {placeholder}
      </MenuItem>
      {children}
    </MuiSelect>
  );
};

export default Select;
