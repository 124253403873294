import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

export const getColor = (color: string | undefined, theme: Theme) => {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
      break;
    case 'secondary':
      return theme.palette.secondary.main;
    default:
      return theme.palette.primary.main;
  }
};

export const getBorder = (color: string | undefined, theme: Theme) => {
  switch (color) {
    case 'primary':
      return `1px solid ${theme.palette.primary.main}`;
    case 'secondary':
      return `1px solid ${theme.palette.secondary.main}`;
    default:
      return `1px solid ${theme.palette.primary.main}`;
  }
};

export const useStyles = makeStyles<string | undefined>()((theme, color) => ({
  root: {
    fontFamily: 'Scto Grotesk A',
    fontSize: '16px',
    height: '46px',
    color: getColor(color, theme),
    background: 'transparent',
    border: getBorder(color, theme),
    borderRadius: '23px',
    opacity: 1,
    padding: '0',
    width: '322px',
    '&:hover': {
      background: getColor(color, theme),
      color: theme.palette.white,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
}));

export default useStyles;
