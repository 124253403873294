import React from 'react';
import { useMediaQuery, useTheme, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as AlexandriaLogo } from '../../../images/svg/alexandria_pun.svg';
import { ReactComponent as AlexandriaLogo156 } from '../../../images/svg/alexandria_pun_156.svg';

import useStyles from './styles';

const Header = (): React.ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );

  return (
    <div className={classes.header}>
      {matchesXS ? <AlexandriaLogo156 /> : <AlexandriaLogo />}
      <div className={classes.buttons}>
        <Button component={Link} className={classes.button} to="/">
          Kirjaudu
        </Button>
      </div>
    </div>
  );
};

export default Header;
