import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import Header from './Header';
import { ActivityFeedItem } from '../ActivityFeedItem';
import useStyles from './styles';
import { activityToFeedItem, bookingToFeedItem } from './utils';
import { useDataLoader } from '../../data-loader';
import { getUpcomingBookings } from '../../utils/booking';

const ActivityFeed = (props: {
  children?: React.ReactNode;
  showHeader?: boolean;
}): React.ReactElement => {
  const { classes, cx } = useStyles();
  const open = useSelector((state: RootState) => state.ui.isActivityFeedOpen);
  const activities = useSelector(
    (state: RootState) => state.activity.activities
  );
  const bookings = useSelector((state: RootState) => state.activity.bookings);
  const offices = useSelector((state: RootState) => state.content.offices);

  const upcomingBookings = getUpcomingBookings(bookings) || [];
  const feedItems = [
    ...activities.map(activityToFeedItem),
    ...upcomingBookings.map(bookingToFeedItem(offices)),
  ];

  useDataLoader('user-bookings', 'activities');

  return (
    <div className={classes['feed-container']}>
      <div className={cx(classes.feed, open ? 'open' : 'closed')}>
        {props.showHeader === false ? '' : <Header />}
        <div className={cx(classes['feed-body'], open ? 'open' : 'closed')}>
          <div className={classes['feed-items-gradient']}></div>
          <div
            className={cx(
              classes['feed-items'],
              open ? 'open' : 'closed',
              feedItems.length > 0 ? 'has-items' : ''
            )}
          >
            {feedItems.length > 0 ? (
              feedItems.map((feedItem, i) => {
                return (
                  <ActivityFeedItem {...feedItem} key={`feed-item-${i}`} />
                );
              })
            ) : (
              <div className={classes['no-items']}>Ei näytettäviä kohteita</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityFeed;
