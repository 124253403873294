import firebase from 'firebase/app';
import { store } from '../../store';
import { setControllableUsers } from '../../store/user/actions';
import { FSUser } from '../../types/firestore';
import { getControllableUsers } from '../../utils/firebase';
import { DataSource, DataSourceUpdateType } from '../dataSource';
import { DataSubscription } from '../dataSubscription';

export class AccessToUsersDataSource extends DataSource {
  name: DataSourceUpdateType = 'user-access-to-users';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const docs = db.collection('users').doc(uid);

    const unsubscribe = docs.onSnapshot(
      async (snapshot) => {
        const fsUser = snapshot.data() as FSUser | undefined;

        // Get controllable users only if uid === ownUid (logged in user is viewing itself)
        if (uid === ownUid && fsUser?.accessToUsers) {
          getControllableUsers(fsUser).then((controllableUsers) => {
            store.dispatch(setControllableUsers(controllableUsers));
          });
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
    return new DataSubscription(this.name, unsubscribe);
  }
}
