import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

// Nav variants
import { Horizontal } from './Horizontal';
import { Drawer } from './Drawer';

import menuFactoryDrawer from './Drawer/menuFactory';
import menuFactoryHorizontal from './Horizontal/menuFactory';
import { isUserInGroup, useUserHasIncentiveClass } from '../../utils/user';

import { NavSpec } from '../../types/local';
import { filterByIncentiveClassStatus } from '../../routes/navItems';
import { useIsAdUser, useIsImpersonating } from '../../hooks';
import { filterNavItemsForAdUser } from './utils';

const Nav = (props: {
  type: 'horizontal' | 'vertical';
  navItems: NavSpec[];
}): React.ReactElement => {
  const { type, navItems } = props;
  const user = useSelector((state: RootState) => state.user.fsUser);
  const isAdUser = useIsAdUser();
  const incentiveClassActive = useUserHasIncentiveClass();
  const isImpersonating = useIsImpersonating();

  const filterByincentiveClassStatus = filterByIncentiveClassStatus(
    incentiveClassActive,
    navItems
  );

  const filteredNavItems = filterNavItemsForAdUser(
    filterByincentiveClassStatus.filter((item) => {
      if (item.requiredRoles?.length) {
        return item.requiredRoles.every(
          (role) => user && isUserInGroup(user, role)
        );
      }
      return true;
    }),
    isImpersonating,
    isAdUser
  );

  const children =
    type === 'vertical'
      ? menuFactoryDrawer({
          navItems: filteredNavItems,
        })
      : menuFactoryHorizontal({
          navItems: filteredNavItems,
        });

  return (
    <>
      {type === 'horizontal' && <Horizontal>{children}</Horizontal>}
      {type === 'vertical' && <Drawer>{children}</Drawer>}
    </>
  );
};

export default Nav;
