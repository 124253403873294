import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  'mui-drawer-paper': {
    backgroundColor: theme.palette.secondary.main,
    border: '0px',
  },
  'menu-container': {
    display: 'flex',
  },
  'drawer-container': {
    width: '354px',
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    position: 'fixed',
    overflow: 'hidden',
    [theme.breakpoints.down(400)]: {
      width: 'calc(100% - 35px)',
    },
    '&:after': {
      content: '""',
      display: 'block',
      width: '43px',
      margin: '88px 0px 88px 0px',
      backgroundColor: '#C62936',
      borderRadius: '46px 0px 0px 46px',
    },
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
  shadow: {
    width: '43px',
    margin: '88px 0px 88px 0px',
    backgroundColor: '#C62936',
    borderRadius: '46px 0px 0px 46px',
  },
  'drawer-ul': {
    paddingLeft: '0px',
    '& ul': {
      paddingLeft: '0px',
    },
    '& li': {
      listStyleType: 'none',
      textDecoration: 'none',
      paddingLeft: '32px',
    },
    '& li.selected': {},
  },
  button: {
    color: '#000450',
    border: '1px solid #000450',
    borderRadius: '23px',
  },
  footer: {
    marginTop: 'auto',
    paddingLeft: '32px',
    paddingBottom: '48px',
  },
  signout: {
    fontFamily: 'Scto Grotesk A',
    fontSize: '16px',
    display: 'flex',
    '& img': {
      marginRight: '8px',
    },
  },
}));

export default useStyles;
