import React, { forwardRef } from 'react';
import MuiButton, { ButtonProps } from '@mui/material/Button';

import useStyles from './styles';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const { onClick, className, children, ...otherProps } = props;
    const { classes } = useStyles(otherProps.color);
    return (
      <MuiButton
        onClick={onClick}
        variant="text"
        className={className}
        classes={classes.root}
        {...otherProps}
        ref={ref}
      >
        {children}
      </MuiButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
