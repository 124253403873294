import {
  RahamyllyAccount,
  RahamyllyBalance,
  RahamyllyCashBalance,
  RahamyllyDepositInfo,
  RahamyllyLoan,
  RahamyllyLoanReport,
  RahamyllyLoanStatus,
  RahamyllyScheduledWithdrawal,
} from '../../../types/rahamylly';
import { Logout, ChangeUser } from '../../base';

export enum ActionTypes {
  SET_INVESTMENTS = 'SET_CORPORATE_FINANCING_INVESTMENTS',
  SET_BALANCE = 'SET_CORPORATE_FINANCING_BALANCE',
  SET_LOAN_STATUSES = 'SET_CORPORATE_FINANCING_LOAN_STATUSES',
  ADD_LOAN_STATUS = 'ADD_CORPORATE_FINANCING_LOAN_STATUS',
  SET_LOAN_REPORTS = 'SET_CORPORATE_FINANCING_LOAN_REPORTS',
  ADD_LOAN_REPORTS = 'ADD_CORPORATE_FINANCING_LOAN_REPORTS',
  SET_BALANCE_TIMESTAMP = 'SET_BALANCE_TIMESTAMP',
  SET_INVESTMENTS_TIMESTAMP = 'SET_INVESTMENTS_TIMESTAMP',
  SET_ACCOUNT = 'SET_CORPORATE_FINANCING_ACCOUNT',
  SET_CASH_BALANCE = 'SET_CORPORATE_FINANCING_CASH_BALANCE',
  SET_DEPOSIT_INFO = 'SET_CORPORATE_FINANCING_DEPOSIT_INFO',
  SET_SCHEDULED_WITHDRAWAL_AMOUNT = 'SET_SCHEDULED_WITHDRAWAL_AMOUNT',
}

export interface CorporateFinancingState {
  investments: RahamyllyLoan[] | null;
  balance: RahamyllyBalance | null;
  loanStatuses: RahamyllyLoanStatus[];
  loanReports: RahamyllyLoanReport[];
  balanceTimestamp?: number;
  investmentsTimestamp?: number;
  account: RahamyllyAccount | null;
  cashBalance: RahamyllyCashBalance | null;
  depositInfo: RahamyllyDepositInfo | null;
  scheduledWithdrawalAmount: RahamyllyScheduledWithdrawal | null;
}

interface SetInvestments {
  type: typeof ActionTypes.SET_INVESTMENTS;
  payload: CorporateFinancingState['investments'];
}

interface SetBalance {
  type: typeof ActionTypes.SET_BALANCE;
  payload: CorporateFinancingState['balance'];
}

interface SetLoanStatuses {
  type: typeof ActionTypes.SET_LOAN_STATUSES;
  payload: RahamyllyLoanStatus[];
}

interface AddLoanStatus {
  type: typeof ActionTypes.ADD_LOAN_STATUS;
  payload: RahamyllyLoanStatus;
}

interface SetLoanReports {
  type: typeof ActionTypes.SET_LOAN_REPORTS;
  payload: RahamyllyLoanReport[];
}

interface AddLoanReports {
  type: typeof ActionTypes.ADD_LOAN_REPORTS;
  payload: RahamyllyLoanReport[];
}

interface SetBalanceTimestamp {
  type: typeof ActionTypes.SET_BALANCE_TIMESTAMP;
  payload: CorporateFinancingState['balanceTimestamp'];
}

interface SetInvestmentsTimestamp {
  type: typeof ActionTypes.SET_INVESTMENTS_TIMESTAMP;
  payload: CorporateFinancingState['investmentsTimestamp'];
}

interface SetAccount {
  type: typeof ActionTypes.SET_ACCOUNT;
  payload: CorporateFinancingState['account'];
}

interface SetCashBalance {
  type: typeof ActionTypes.SET_CASH_BALANCE;
  payload: CorporateFinancingState['cashBalance'];
}

interface SetDepositInfo {
  type: typeof ActionTypes.SET_DEPOSIT_INFO;
  payload: CorporateFinancingState['depositInfo'];
}

interface SetScheduledWithdrawalAmount {
  type: typeof ActionTypes.SET_SCHEDULED_WITHDRAWAL_AMOUNT;
  payload: CorporateFinancingState['scheduledWithdrawalAmount'];
}

export type Actions =
  | Logout
  | ChangeUser
  | SetInvestments
  | SetBalance
  | SetLoanStatuses
  | AddLoanStatus
  | SetLoanReports
  | AddLoanReports
  | SetBalanceTimestamp
  | SetInvestmentsTimestamp
  | SetAccount
  | SetCashBalance
  | SetDepositInfo
  | SetScheduledWithdrawalAmount;
