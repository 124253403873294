import React from 'react';

import { News } from './News';
import { Milestone } from './Milestone';
import { Funds } from './Funds';
import { Meeting } from './Meeting';

type ContentProps = ContentItemProps & {
  type: string;
};

export interface ContentItemProps {
  title: string;
  header: string;
  body: React.ReactElement | string | null;
  linkTo: string;
}

export const Content = (props: ContentProps): React.ReactElement => {
  const { type, ...content } = props;
  switch (type) {
    case 'news': {
      return <News {...content} />;
    }
    case 'funds': {
      return <Funds {...content} />;
    }
    case 'meeting': {
      return <Meeting {...content} />;
    }
    case 'milestone': {
      return <Milestone {...content} />;
    }
    default: {
      return <News {...content} />;
    }
  }
};
