import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import {
  Routes as RoutesDOM,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import MainLayout from '../layouts/Main';
import MainRoutes from './main';
import { Login } from '../views/Login';
import CalendarAnonymous from '../views/CalendarAnonymous';
import EmailHandler from '../views/EmailHandler';
import ContactUs from '../views/ContactUs';
import Loading from '../views/Loading';
import { selectTab, setNavigation, setRedirect } from '../store/ui/actions';
import { LocationState } from '../types/local';
import { setHash } from '../store/calendar/actions';
import { isOnPublicRoute } from '../utils/routes';
import BackofficeLogin from '../views/BackofficeLogin/index';

const App = (): React.ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const redirect = useSelector((state: RootState) => state.ui.redirect);

  useEffect(() => {
    if (redirect) {
      dispatch(setRedirect(null));
      navigate(redirect, { replace: true });
    }
  }, [redirect]);

  useEffect(() => {
    dispatch(selectTab(`nav-item${location.pathname.replace(/\//g, '-')}`));
    dispatch(setNavigation(false));
  }, [location]);

  return (
    <MainLayout>
      <MainRoutes />
    </MainLayout>
  );
};

export const Routes = (): React.ReactElement => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.ui.isLoading);
  const userData = useSelector((state: RootState) => state.user.user);
  const userAuth = firebase.auth().currentUser;
  const user = userData && userAuth;
  const location = useLocation();
  const state = location.state as LocationState;
  if (state) {
    dispatch(setRedirect(state.from));
  }

  useEffect(() => {
    // Grab hash parameter from query and save it to state
    const hash = new URLSearchParams(location.search).get('hash');
    if (hash) {
      dispatch(setHash(hash));
    }
  }, [location]);

  return isLoading ? (
    <Loading />
  ) : (
    <RoutesDOM>
      {/* Routes that need special handling when user is not logged in */}
      <Route path="/ajan_varaus" element={<Navigate to="/ajanvaraus" />} />
      <Route path="/ajanvaraus" element={<CalendarAnonymous />} />
      <Route path="/vahvistus" element={<EmailHandler />} />
      <Route path="/yhteydenotto" element={<ContactUs anonymous={true} />} />
      <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
      <Route
        path="/backoffice_login"
        element={user ? <Navigate to="/" /> : <BackofficeLogin />}
      />
      {/* Main routes are under App */}
      <Route
        path="/*"
        element={
          user || isOnPublicRoute() ? <App /> : <Navigate to={'/login'} />
        }
      />
    </RoutesDOM>
  );
};

export default Routes;
