import useSWR from 'swr';
import { getProductDetails, type ProductDetails } from '../utils/productData';
import { SWR_OPTIONS } from './common';

export const useProductDetails = (id: string) => {
  const { data, isLoading, error } = useSWR<ProductDetails>(
    id,
    getProductDetails,
    { ...SWR_OPTIONS }
  );

  return {
    data: data,
    isLoading,
    error,
  };
};
