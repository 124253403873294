import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { store } from './store';
import { App } from './App';
import ReactGA, { InitializeOptions } from 'react-ga';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import getCspValue from './utils/csp';
import { featureIsEnabled, FeatureName } from './utils/featureFlags';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { fi } from 'date-fns/locale';

const GA_TRACKING_ID = process.env.GA_TRACKING_ID || 'test';
const gaOptions: InitializeOptions = {
  debug: process.env.NODE_ENV !== 'production',
  testMode: GA_TRACKING_ID === 'test',
};
ReactGA.initialize(GA_TRACKING_ID, gaOptions);

const nonce = (Math.random() + 1).toString(36).substring(2);
const csp = getCspValue(nonce);

if (process.env.GTM_ID) {
  console.log('Initialize GTM');
  TagManager.initialize({
    gtmId: process.env.GTM_ID,
    // TODO what is this nonce: nonce,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

featureIsEnabled(FeatureName.Sentry) &&
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || '0'),
  });

root.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
      <CssBaseline />
      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content={csp} />
      </Helmet>
      <Provider store={store}>
        <PersistGate persistor={persistStore(store)}>
          <App />
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  </ThemeProvider>
);
