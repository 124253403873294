import React from 'react';
import { List } from '@mui/material';
import useStyles from './styles';

export const Horizontal = (props: {
  children: React.ReactElement[];
}): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <List className={classes.ul}>{props.children}</List>
    </div>
  );
};
