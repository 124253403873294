import useSWR from 'swr';
import { get } from './http';
import { SWR_OPTIONS } from './common';

export const useKnowledgeLevel = () => {
  const { data, isLoading, error, mutate } = useSWR<{ knowledgeLevel: number }>(
    `${process.env.REACT_APP_API_URL}/store/knowledge`,
    get,
    {
      ...SWR_OPTIONS,
      suspense: false,
    }
  );

  return {
    refresh: () => mutate(),
    level: data?.knowledgeLevel ?? 0,
    isLoading,
    error,
  };
};
