import { useTheme, useMediaQuery, Theme } from '@mui/material';
import { RahamyllyTransactionResponse } from '../../types/rahamylly';
import { fetchWithErrors } from '../../utils/api';
import { getIdToken } from '../../utils/firebase';
import { Method } from './types';

/** Restrict table columns to amount that fits the viewport  */
export const getMaxColumns = (): number => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const matchesSMPLUS = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.smPlus)
  );
  const matchesMD = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const matchesTABLETPLUS = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.tabletPlus)
  );

  const match1400 = useMediaQuery(theme.breakpoints.down(1400));

  const match1580 = useMediaQuery(theme.breakpoints.down(1580));
  const match1690 = useMediaQuery(theme.breakpoints.down(1690));
  const matchesXL = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xl)
  );

  if (matchesSM) return 1;
  else if (matchesSMPLUS) return 2;
  else if (matchesMD) return 3;
  else if (matchesTABLETPLUS) return 4;
  else if (match1400) return 5;
  else if (match1580) return 6;
  else if (match1690) return 7;
  else if (matchesXL) return 8;
  return 10;
};

export const fetchUrl = async (url: string, method: Method) => {
  const idToken = await getIdToken();
  return await fetchWithErrors(url, {
    method: method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((data) => data as RahamyllyTransactionResponse);
};

export const getColor = (color: string | undefined, theme: Theme) =>
  color === 'secondary'
    ? theme.palette.secondary.light
    : theme.palette.grey[100];
