import { FSUser } from './firestore';
import firebase from 'firebase/app';

export type ProductType =
  | 'funds'
  | 'structures'
  | 'insurances'
  | 'corporate-financing';

export interface NavSpec {
  /** link path */
  to: string;
  /** text shown on ui */
  label: string;
  /** child navigation items */
  children?: NavSpec[];
  /** roles needed to access a path */
  requiredRoles?: string[];
  disabled?: string;
  /** hide from nav if true and user is AD user */
  hideIfAdUserAndNotImpersonating?: boolean;
  /** hide from settings nav if true and user is impersonating */
  hideIfImpersonating?: boolean;
}

/** used by router Location */
export type LocationState = {
  from: Location;
};

export type ViewableUser = {
  uid: string;
  user: FSUser;
};

export type Timestamp = Pick<
  firebase.firestore.Timestamp,
  'seconds' | 'nanoseconds'
>;

/** Check values from ABC */
export enum BookingChannel {
  Office = 1,
  Phone = 3,
  Video = 4,
}

export enum BookingView {
  Date = 1,
  Expert = 2,
}

export interface AdvisorBookingChannel {
  Kanava_Puhelin: boolean;
  Kanava_Toimisto: boolean;
  Kanava_Video: boolean;
}

export interface BookingPersonal {
  firstnames: string;
  surname: string;
  phone: string;
  email: string;
  messageForAdvisor: string;
}

/** used by calendar view (aka. varaa_aika page) */
export interface Booking {
  officeId: number | undefined;
  time: string;
  date: Date | undefined;
  advisorId: number | undefined;
  availableChannels: AdvisorBookingChannel | undefined;
  /** minutes */
  meetingDuration: string;
  channel: BookingChannel;
  view: BookingView;
  personal: BookingPersonal;
  phone: string;
}

export enum ContactPreference {
  Puhelimessa = 'phone',
  Toimistolla = 'office',
  Videotapaamisella = 'video',
  Sähköpostilla = 'email',
}

export interface ContactUs {
  personal: ContactUsPersonal;
  /** info@alexandria.fi or advisor's email */
  recipient: string;
  contactPreference: ContactPreference;
}

export interface ContactUsPersonal {
  firstnames: string;
  surname: string;
  phone: string;
  email: string;
  message: string;
}

export enum IsOrNot {
  YES = 'Kyllä',
  NO = 'En',
}

export type FormattedDateToYearMap = {
  [key: string]: number;
};
