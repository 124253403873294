/**
 * Firebase & features
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { store } from '../store';
import { setAccessToken } from '../store/user/actions';
import { FSGroups, FSUser } from '../types/firestore';
import { DataSubscription } from '../data-loader/dataSubscription';
import { GlobalContentDataSource } from '../data-loader/dataSources';
import { KYCOptions } from '../types/kyc';

export const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
    case 'auth/user-disabled':
      return 'Virheellinen käyttäjätunnus tai salasana.';
    case 'auth/too-many-requests':
      return 'Liian monta virheellistä kirjautumisyritystä. Yritä hetken päästä uudelleen.';
    default:
      return undefined;
  }
};

export const passwordSignIn = async (
  username: string,
  password: string
): Promise<{ response: Response; result: firebase.auth.UserCredential }> => {
  return new Promise((resolve, reject) => {
    const signOut = firebase.auth().signOut();
    const setPersistence = firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const signIn = firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then((result) => {
        resolve({
          response: new Response(
            JSON.stringify({ status: 200, statusText: 'OK' })
          ),
          result: result,
        });
      })

      .catch((error) => {
        reject({
          status: error.code || error.message,
          response: error,
          statusText: 'Error',
        });
      });
    signOut.then(() => setPersistence).then(() => signIn);
  });
};

/**
 *
 * @param username used as login_hint
 */
export const azureSignin = async (
  username: string
): Promise<{ response: Response; result: firebase.auth.UserCredential }> => {
  return new Promise((resolve, reject) => {
    const signOut = firebase.auth().signOut();
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: process.env.REACT_APP_AZURE_TENANT,
      login_hint: username,
      prompt: 'login',
    });
    const setPersistence = firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);

    const signIn = firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        if (result.user !== null && result.credential) {
          const accessToken =
            'accessToken' in result.credential
              ? result.credential['accessToken']
              : '';

          store.dispatch(setAccessToken(accessToken as string));
          resolve({
            response: new Response(
              JSON.stringify({ status: 200, statusText: 'OK' })
            ),
            result: result,
          });
        }
      })
      .catch((error) => {
        reject({
          status: error.code || error.message,
          response: error,
          statusText: 'Error',
        });
      });

    signOut.then(() => setPersistence).then(() => signIn);
  });
};

export const getFileUrl = async (filePath: string): Promise<never> => {
  const storageRef = firebase.storage().ref();
  const fileRef = storageRef.child(filePath);
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await fileRef.getDownloadURL();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } catch (error: never) {
    throw new Error(error.message);
  }
};

export const getControllableUsers = async (
  user: FSUser
): Promise<{ uid: string; user: FSUser }[]> => {
  const users = await Promise.all(
    (user.accessToUsers || []).map(async (uid) => ({
      uid,
      user: (
        await firebase.firestore().collection('users').doc(uid).get()
      ).data() as FSUser,
    }))
  );
  return users;
};

/**
 * Search users from firestore's users-collection.
 * The users needs to have group: Extranetadmin or
 * have access to every user (which is not likely) for this to work.
 * @param query The query to search with
 */
export const searchControllableUsers = async (
  query: string
): Promise<{ uid: string; user: FSUser }[]> => {
  const result = await firebase
    .firestore()
    .collection('users')
    .where('keywords', 'array-contains', query.toLowerCase())
    .orderBy('sukunimi')
    .limit(10)
    .get();

  return result.docs
    .map((user) => ({
      uid: user.id,
      user: user.data() as FSUser,
    }))
    .filter((user) => !user.user.groups?.includes(FSGroups.admin));
};

export const getViewedUser = async (
  uid: string
): Promise<{ uid: string; user: FSUser } | null> => {
  try {
    const result = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get();
    if (!result.exists) return null;
    return { uid: uid, user: result.data() as FSUser };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } catch (e: never) {
    throw new Error(e);
  }
};

/**
 * Get the idToken from firebase/auth and refresh it if necessary
 */
export const getIdToken = async (): Promise<string | undefined> => {
  return firebase.auth().currentUser?.getIdToken();
};

// Expose getIdToken in development so the token
// can be used for local api testing
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (window as never).getIdToken = getIdToken;
}

export const getKYCOptions = async (): Promise<KYCOptions | null> => {
  try {
    const result = await firebase
      .firestore()
      .collection('kycOptions')
      .doc('all')
      .get();
    if (!result.exists) return null;
    return result.data() as KYCOptions;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
};

/**
 * Get content for page not requiring login
 */
export const subscribeToContentIfAnonymous = () => {
  let globalContent = null as null | DataSubscription;

  const user = firebase.auth().currentUser;

  if (!user) {
    globalContent = new GlobalContentDataSource().subscribe();
  }

  return () => {
    if (globalContent) globalContent.unsubscribe(true);
  };
};

export const checkIsRegistered = (user: FSUser | undefined): boolean =>
  !!user?.userFinishedRegistrationTS;

export const isSuperAdmin = (user: FSUser | undefined) => {
  if (!user?.groups) return false;
  return user.groups.includes(FSGroups.admin);
};

// should only be used for UI purposes, does not secure anything
export const checkIsAlexandriaEmployee = (
  user: FSUser | undefined
): boolean => {
  if (!user?.groups) return false;
  const { groups } = user;
  return (
    isSuperAdmin(user) ||
    groups.includes(FSGroups.advisor) ||
    groups.includes(FSGroups.superAdvisor)
  );
};
