import { Reducer } from '@reduxjs/toolkit';
import { FundsState as State, Actions, ActionTypes as actions } from './types';

const INITIAL_STATE: State = {
  positions: null,
  transactions: null,
  securityDetail: [],
  securityInfo: [],
};

export const fundsReducer: Reducer<State, Actions> = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case actions.SET_POSITION:
      return {
        ...state,
        positions: action.payload,
      };
    case actions.SET_TRANSACTION:
      return {
        ...state,
        transactions: action.payload,
      };
    case actions.SET_SECURITY_DETAILS:
      return {
        ...state,
        securityDetail: action.payload,
      };
    case actions.ADD_SECURITY_DETAILS: {
      const oldIds = state.securityDetail.map((v) => v?.Id ?? null);
      const newSecurityDetail = action.payload.filter(
        (v) => !oldIds.includes(v?.Id ?? null)
      );
      return {
        ...state,
        securityDetail: [...state.securityDetail, ...newSecurityDetail],
      };
    }
    case actions.SET_SECURITY_INFO:
      return {
        ...state,
        securityInfo: action.payload,
      };
    case actions.ADD_SECURITY_INFO: {
      const oldIds = state.securityInfo.map((v) => v.sys.id);
      const newSecurityInfo = action.payload.filter(
        (v) => !oldIds.includes(v.sys.id)
      );
      return {
        ...state,
        securityInfo: [...state.securityInfo, ...newSecurityInfo],
      };
    }
    case actions.CLEAR_FUNDS:
    case 'LOGOUT':
    case 'CHANGE_USER':
      return INITIAL_STATE;
    default:
      return state;
  }
};
