import useSWR from 'swr';
import { get } from './http';
import { SWR_OPTIONS } from './common';
import { useProductListing } from './useProductListing';
import type { Purchase } from '../types/abc';
import type { Comission, CommissionState } from './types';

const resolveState: (purcase: Purchase) => CommissionState = (purchase) => {
  if (purchase.Loppuunkasitelty) {
    return 'ready';
  }
  if (purchase.AsiakasHaluaaPerua || purchase.Peruttu) {
    return 'cancelled';
  }
  if (purchase.Maksettu) {
    return 'paid';
  }
  if (purchase.Valmis) {
    return 'signed';
  }
  return 'created';
};

export const useComissions = () => {
  const { data: products, isLoading: productsLoading } = useProductListing();

  const { data, mutate, isLoading } = useSWR<Comission[]>(
    !productsLoading && `${process.env.REACT_APP_API_URL}/store/purchase`,
    get,
    {
      ...SWR_OPTIONS,
      onSuccess: (data) =>
        data?.forEach((purchase) => {
          const product = products.find(
            (product) => product.isin === purchase.ISIN
          );
          purchase.product = {
            imgUrl:
              product?.image.url ??
              '/static/images/alexandria_placeholder_icon.png',
          };
          purchase.state = resolveState(purchase);
        }),
    }
  );

  return {
    comissions: data || [],
    isLoading,
    mutate,
  };
};
