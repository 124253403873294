import { ActionTypes, Actions, InsurancesState } from './types';

export function setInsurancesOverview(
  activity: InsurancesState['overviews']
): Actions {
  return {
    type: ActionTypes.SET_OVERVIEW,
    payload: activity,
  };
}

export function setInsurancesPosition(
  activity: InsurancesState['positions']
): Actions {
  return {
    type: ActionTypes.SET_POSITION,
    payload: activity,
  };
}

export function clearInsurances(): Actions {
  return { type: ActionTypes.CLEAR_INSURANCES };
}

export function setInsuranceSecurityInfo(
  info: InsurancesState['securityInfo']
): Actions {
  return {
    type: ActionTypes.SET_INSURANCE_SECURITY_INFO,
    payload: info,
  };
}
