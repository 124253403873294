import { GraphQLClient, gql } from 'graphql-request';

const client = (() => {
  const baseUrl = process.env.CONTENTFUL_GRAPHQL_URL;
  const spaceId = process.env.PRODUCT_CONTENTFUL_SPACE_ID;
  const token = process.env.PRODUCT_CONTENTFUL_GRAPHQL_TOKEN;
  const environment = process.env.PRODUCT_CONTENTFUL_ENVIRONMENT;

  const url = `${baseUrl}/${spaceId}${environment ? '/environments/' : ''}${environment ?? ''}`;

  return new GraphQLClient(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    errorPolicy: 'all',
  });
})();

const FALLBACK_IMAGE = '/static/images/alexandria_placeholder_icon.png';

type JSONField = {
  valueString: string;
  valueNumber: number;
  title: string;
  label: string;
  information: string;
};

type FundProductListing = {
  __typename: 'FundProduct';
  minimumInvestmentAmount: JSONField;
};

type StructuredInvestmentProductListing = {
  __typename: 'StructuredInvestmentProduct';
  minimumSubscriptionUnits: number;
  salesEndDate: string;
  salesStartDate: string;
  subscriptionPrice: number;
};

type ConcreteProductListing = (
  | FundProductListing
  | StructuredInvestmentProductListing
) & {
  riskRating: JSONField;
};

interface Concrete {
  __typename: string;
}

interface Core {
  sys: {
    id: string;
  };
  isin: string;
  knowledgeProductGroupId: number;
  shortDescription: string;
  subtype: number;
  title: string;
  type: number;
  image: {
    url: string;
    title: string;
  };
  instrument: {
    data: Concrete[];
  };
}

interface Result {
  id: string;
  __typename: string;
  title: string;
  image: {
    url: string;
    title: string;
  };
}

type CoreProductListing = Core & {
  instrument: {
    data: ConcreteProductListing[];
  };
};

type FundProductDetails = {
  __typename: 'FundProduct';
  benchmarkIndex: string;
  entryFee: JSONField;
  esgClassification: JSONField;
  equityWeight: JSONField;
  exitFee: JSONField;
  investmentStrategy: string;
  managementFee: JSONField;
  managers: string;
  minimumInvestmentAmount: JSONField;
  nextRedemptionDate: JSONField;
  nextSubscriptionDate: JSONField;
};

type StructuredInvestmentProductDetails = {
  __typename: 'StructuredInvestmentProduct';
  clearing: string;
  issuer: string;
  listing: string;
  minimumInvestment: JSONField;
  minimumSubscriptionUnits: number;
  salesPeriod: JSONField;
  subscriptionFee: number;
  subscriptionPrice: number;
  underlyingAssetDescription: JSONField;
};

type ConcreteProductDetails = (
  | FundProductDetails
  | StructuredInvestmentProductDetails
) & {
  expectedReturnPercent: JSONField;
  investmentTime: JSONField;
  issueDate: string;
  riskRating: JSONField;
};

type Document = {
  sys: {
    id: string;
  };
  title: string;
  fileName: string;
  sendToSignicat: boolean;
  indexInSignicat: number;
  viewRequiredInSignicat: boolean;
  signatureRequiredInSignicat: boolean;
  visibleOnSite: boolean;
  isHighlighted: boolean;
  location: {
    url: string;
  };
};

type CoreProductDetails = Core & {
  body: string;
  currency: string;
  orderConfirmationText: string;
  productNotes: string;
  productNotesTitle: string;
  requiredKnowledge: number;
  instrument: {
    data: ConcreteProductDetails[];
  };
  document: {
    files: Document[];
  };
};

type GlobalDocuments = {
  generic: {
    files: Document[];
  };
  fund: {
    files: Document[];
  };
  structured: {
    files: Document[];
  };
};

type ProductListItem = { id: string } & Omit<
  CoreProductListing,
  'sys' | 'instrument'
> &
  ConcreteProductListing;

type ProductDetails = { id: string } & Omit<
  CoreProductDetails,
  'sys' | 'instrument'
> &
  ConcreteProductDetails;

/**
 * Sanity check whether product should be listed or not
 */
const visibilityFilter = <T extends Core>(product: T): boolean =>
  !!product.instrument.data.length &&
  !!product.knowledgeProductGroupId &&
  !!(product.type || product.subtype) &&
  !!product.isin;

/**
 * Avoid showing products without required data (price details)
 */
const sanityCheck = (product: ProductListItem | ProductDetails) =>
  product.__typename === 'FundProduct'
    ? !!product?.minimumInvestmentAmount.valueNumber
    : !!product.subscriptionPrice && !!product.minimumSubscriptionUnits;

const mapper = <T extends Core, R extends Result>(products: T[]): R[] =>
  products
    .filter(visibilityFilter)
    .map(
      ({
        sys: { id },
        instrument: {
          data: [concreteFields],
        },
        ...coreFields
      }) =>
        ({
          id,
          ...coreFields,
          ...concreteFields,
        }) as unknown as R
    )
    .map(
      ({ image, ...rest }) =>
        ({
          image: image ?? { url: FALLBACK_IMAGE, title: rest.title },
          ...rest,
        }) as unknown as R
    );

const getProductListing = async (category: string = '0') => {
  const type = +category;
  const CATEGORY = type < 1 ? null : type;

  const query = gql`
    fragment fundListingFields on FundProduct {
      minimumInvestmentAmount
      riskRating
    }

    fragment structuredInvestmentListingFields on StructuredInvestmentProduct {
      minimumSubscriptionUnits
      riskRating
      salesEndDate
      salesStartDate
      subscriptionPrice
    }

    query getProductListing($CATEGORY: Int) {
      product: coreProductCollection(
        where: {
          AND: [
            { subscribableOnline: true }
            { OR: [{ type: $CATEGORY }, { subtype: $CATEGORY }] }
          ]
        }
        order: sortIndex_ASC
      ) {
        data: items {
          sys {
            id
          }
          type
          subtype
          isin
          currency
          title
          shortDescription
          knowledgeProductGroupId
          image {
            url
            title
          }
          instrument: metaProductCollection(limit: 1) {
            data: items {
              __typename
              ... on FundProduct {
                ...fundListingFields
              }
              ... on StructuredInvestmentProduct {
                ...structuredInvestmentListingFields
              }
            }
          }
        }
      }
    }
  `;
  const result = await client.request<{
    product: {
      data: CoreProductListing[];
    };
  }>(query, { CATEGORY });

  return mapper<CoreProductListing, ProductListItem>(
    result.product.data
  ).filter(sanityCheck);
};

const getProductDetails = async (ID: string): Promise<ProductDetails> => {
  const query = gql`
    fragment fundDetailsFields on FundProduct {
      benchmarkIndex
      entryFee
      esgClassification
      equityWeight
      exitFee
      expectedReturnPercent
      investmentStrategy
      investmentTime
      issueDate
      managementFee
      managers
      minimumInvestmentAmount
      nextRedemptionDate
      nextSubscriptionDate
      riskRating
    }

    fragment structuredInvestmentDetailsFields on StructuredInvestmentProduct {
      clearing
      expectedReturnPercent
      investmentTime
      issueDate
      issuer
      listing
      minimumInvestment
      minimumSubscriptionUnits
      riskRating
      salesPeriod
      subscriptionFee
      subscriptionPrice
      underlyingAssetDescription
    }

    fragment productFileDetails on ProductDocument {
      sys {
        id
      }
      title
      fileName
      sendToSignicat
      indexInSignicat
      viewRequiredInSignicat
      signatureRequiredInSignicat
      visibleOnSite
      isHighlighted
      location: document {
        url
      }
    }

    query getProductDetails($ID: String) {
      product: coreProductCollection(where: { sys: { id: $ID } }, limit: 1) {
        data: items {
          sys {
            id
          }
          body
          currency
          isin
          knowledgeProductGroupId
          requiredKnowledge
          orderConfirmationText
          productNotes
          productNotesTitle
          shortDescription
          subtype
          title
          type
          image {
            url
            title
          }
          instrument: metaProductCollection(limit: 1) {
            data: items {
              __typename
              ... on FundProduct {
                ...fundDetailsFields
              }
              ... on StructuredInvestmentProduct {
                ...structuredInvestmentDetailsFields
              }
            }
          }
          document: documentsCollection(
            where: { visibleOnSite: true }
            order: indexInSignicat_ASC
          ) {
            files: items {
              ...productFileDetails
            }
          }
        }
      }

      global: globalDocumentsCollection {
        documents: items {
          generic: genericDocumentsCollection(
            where: { visibleOnSite: true }
            order: indexInSignicat_ASC
          ) {
            files: items {
              ...productFileDetails
            }
          }
          fund: fundDocumentsCollection(
            where: { visibleOnSite: true }
            order: indexInSignicat_ASC
          ) {
            files: items {
              ...productFileDetails
            }
          }
          structured: structuredDocumentsCollection(
            where: { visibleOnSite: true }
            order: indexInSignicat_ASC
          ) {
            files: items {
              ...productFileDetails
            }
          }
        }
      }
    }
  `;
  const result = await client.request<{
    product: {
      data: CoreProductDetails[];
    };
    global: {
      documents: GlobalDocuments[];
    };
  }>(query, { ID });

  const product = mapper<CoreProductDetails, ProductDetails>(
    result.product.data
  ).filter(sanityCheck)[0];

  if (!product) {
    throw new Error(`Product not found: ${ID}`);
  }

  if (
    product.__typename === 'FundProduct' &&
    result.global.documents[0]?.fund.files.length
  ) {
    product.document.files = [
      ...product.document.files,
      ...result.global.documents[0].fund.files,
    ];
  }

  if (
    product.__typename === 'StructuredInvestmentProduct' &&
    result.global.documents[0]?.structured.files.length
  ) {
    product.document.files = [
      ...product.document.files,
      ...result.global.documents[0].structured.files,
    ];
  }

  if (result.global.documents[0]?.generic.files.length) {
    product.document.files = [
      ...product.document.files,
      ...result.global.documents[0].generic.files,
    ];
  }

  return product;
};

export {
  JSONField,
  Document,
  ProductListItem,
  ProductDetails,
  getProductListing,
  getProductDetails,
};
