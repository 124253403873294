import { Link, Typography } from '@mui/material';
import React from 'react';

const ErrorView = (): React.ReactElement => {
  return (
    <div>
      <Typography variant="h1">Tapahtui virhe!</Typography>
      <Typography variant="body1">Sivua ei voida juuri nyt näyttää</Typography>
      <Link href="/">Palaa etusivulle</Link>
    </div>
  );
};

export default ErrorView;
