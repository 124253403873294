import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  root: {
    width: '100vw',
    maxWidth: theme.breakpoints.values.fullSize,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    padding: '4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
    '& .container': {
      flexWrap: 'wrap',
      display: 'flex',
      maxWidth: 1024,
    },
    '& a': {
      color: 'white',
      letterSpacing: '0.1rem',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: '1rem',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .logo': {
      [theme.breakpoints.down('sm')]: {
        flex: '1 1 100%',
      },
    },
    '& .nav': {
      flex: '1 1 auto',
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '4rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '1rem',
      },
    },
    '& .nav-section': {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 1',
      margin: '1rem 4rem 0 0',
    },
    '& .nav-section.other': {
      marginRight: 0,
    },
    '& .legal': {
      alignItems: 'flex-end',
      flex: '0 1 auto',
      display: 'flex',
      flexDirection: 'column',
      margin: '1rem 0',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%',
        alignItems: 'flex-start',
      },
    },
    '& .info': {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '5rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: '3rem',
      },
      '& div': {
        margin: '1rem',
      },
    },
  },
}));
