import React from 'react';

import { Typography } from '@mui/material';
import { Button } from '../../Button';

import useStyles from '../../styles';
import { ContentItemProps } from '..';

export const Funds = (props: ContentItemProps): React.ReactElement => {
  const { classes, cx } = useStyles();
  const { title, body, header, linkTo } = props;
  return (
    <div className={classes['content-container']}>
      <div className={cx(classes.content, 'funds')}>
        <div className={cx('header', classes.header)}>{header}</div>
        <div className="title">
          <Typography variant="h1">{title}</Typography>
        </div>
        <div className="body">{body}</div>
      </div>
      <div
        className={cx(classes.button, 'funds')}
        onClick={() => {
          console.log('clicked a feed item');
        }}
      >
        <Button to={linkTo} />
      </div>
    </div>
  );
};
