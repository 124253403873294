import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 9,
    top: 0,
    left: 0,
    '& .background': {
      background: theme.palette.grey['100'],
      opacity: 0.5,
      width: '100%',
      height: '100%',
      borderRadius: '23px 23px 23px 0px',
    },
    '& .content': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      '-ms-transform': 'translate(-50%,-50%)',
    },
  },
}));

export default useStyles;
