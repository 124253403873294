import React from 'react';
import Radio, { RadioProps } from '@mui/material/Radio';

import { ReactComponent as Checked } from '../../images/svg/radio-button-checked.svg';
import { ReactComponent as Unchecked } from '../../images/svg/radio-button-unchecked.svg';

const StyledRadio: React.FC<RadioProps> = (props) => (
  <Radio checkedIcon={<Checked />} icon={<Unchecked />} {...props} />
);

export default StyledRadio;
