import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  'nav-item': {
    fontFamily: 'Scto Grotesk A, Medium',
    fontSize: '16px',
    listStyle: 'none',
    listStyleType: 'none',
    textTransform: 'uppercase',
    '&.stick-to-right': {
      position: 'absolute',
      top: 0,
      right: '8px',
      maxWidth: '200px',
      width: 'auto',
      height: '100%',
    },
    '& a': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      width: '100%',
      height: '100%',
      whiteSpace: 'nowrap',
    },
    '&.selected': {
      '&.stick-to-right': {
        right: 0,
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      '& a': {
        fontWeight: 500,
      },
      '&.submenu': {
        height: '46px',
        borderRadius: '23px',
        background: '#EEEEEE',
        boxShadow:
          'inset 2px 2px 1px #F7F7F7, -3px -3px 4px rgba(255, 255, 255, 0.7), 1px 3px 6px rgba(0, 0, 0, 0.08), 0px 16px 10px -10px rgba(0, 0, 0, 0.1)',
        '& a': {
          color: theme.palette.text.primary,
        },
      },
    },

    '&.drawer': {
      paddingTop: 0,
      paddingBottom: 0,
      '& span': {
        width: '100%',
        height: '100%',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '&.selected-path': {
        background: theme.palette.secondary.light,
        borderRadius: '0 36px 36px 0',

        transition: theme.transitions.create('border-radius', {
          easing: theme.transitions.easing.sharp,
          delay: theme.transitions.duration.leavingScreen,
          duration: theme.transitions.duration.leavingScreen,
        }),
        '&:before': {
          content: '""',
          borderBottom: '1px solid #01094E',
          bottom: '50%',
          position: 'absolute',
          width: '16px',
        },
        '&.open': {
          borderRadius: '0 36px 0 0',
          transition: theme.transitions.create('border-radius', {
            easing: theme.transitions.easing.sharp,
            delay: 0,
            duration: 0,
          }),
          zIndex: 1,
        },
        '& a': {
          fontWeight: 500,
        },
      },
      '&.selected': {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '0 36px 36px 0',
        '&:before': {
          content: '""',
          borderBottom: '1px solid #01094E',
          bottom: '50%',
          position: 'absolute',
          width: '16px',
        },
        '& span': {
          backgroundColor: theme.palette.secondary.light,
          borderRadius: '0px 23px 23px 0px',
          boxShadow:
            ' -3px -3px 4px rgba(255, 255, 255, 0.7), 1px 3px 6px rgba(0, 0, 0, 0.08), 0px 16px 10px -10px rgba(0, 0, 0, 0.1)',
        },
        '& a': {
          fontWeight: 500,
          color: theme.palette.primary.main,
        },
      },
    },
    '& .nav-icon': {
      paddingLeft: '16px',
      minWidth: '16px',
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
