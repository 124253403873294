import React from 'react';
import { CalendarAction } from '../../../Calendar/Form/state';

import Summary from '../Summary';
import SetInfo from '../SetInfo';

import { Booking } from '../../../../types/local';

import useStyles from '../styles';

const Step3 = (props: {
  state: Booking;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const { classes } = useStyles(false);
  const { state, dispatch } = props;
  return (
    <div className={classes.step3}>
      <Summary value={state} details={true} />
      <SetInfo value={state.personal} dispatch={dispatch} />
    </div>
  );
};

export default Step3;
