import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import firebase from 'firebase/app';

import { RootState } from '../../../store';

import Avatar from '../../Avatar';

import { setNavigation } from '../../../store/ui/actions';

import useStyles from './styles';

import logoutUrl from '../../../images/svg/log_out.svg';

const Vertical = (props: {
  children: React.ReactElement[];
}): React.ReactElement => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state.ui.isNavigationOpen);
  const toggleDrawer = (openState: boolean): void => {
    dispatch(setNavigation(openState));
  };

  const navigate = useNavigate();
  return (
    <div className={cx(classes.drawer, { 'nav-open': open })}>
      <div className={classes.top}>
        <Link
          component={RouterLink}
          to="/omat_tiedot"
          className={classes.omat_tiedot}
        >
          {/*
            there is no prop call photoUrl in Avatar component
            <Avatar photoUrl={user?.photoURL} />*/}
          <Avatar />
        </Link>
        <Link
          component="button"
          className={classes.signout}
          onClick={() => {
            toggleDrawer(false);
            navigate('/', { replace: true });
            firebase.auth().signOut();
          }}
        >
          <img src={logoutUrl} alt="" />
          Kirjaudu ulos
        </Link>
      </div>
      <div className={classes['menu-container']}>
        <List className={classes['drawer-ul']}>{props.children}</List>
      </div>
    </div>
  );
};

export default Vertical;
