/**
 * Returns if user is on a route that allows unauthenticated use
 * */
export const isOnPublicRoute = () => {
  const { pathname } = window.location;

  return [
    '/ajanvaraus',
    '/vahvistus',
    '/yleista/tietosuoja',
    '/yleista/kayttoehdot',
    '/yleista/saavutettavuus',
  ].includes(pathname);
};

export const isOnPage = (page: string) => {
  const { pathname } = window.location;
  return pathname.substring(1) === page;
};
