import { Logout } from '../base';
import { Report as ABCReport } from '../../types/abc';
import { UtmostReport } from '../../types/utmost';

export enum ActionTypes {
  SET_UTMOST_REPORTS = 'SET_UTMOST_REPORTS',
  SET_ABC_REPORTS = 'SET_ABC_REPORTS',
  CHANGE_USER = 'CHANGE_USER',
}

export interface State {
  abcReports?: ABCReport[];
  utmostReports?: UtmostReport[];
}

interface setAbcReports {
  type: typeof ActionTypes.SET_ABC_REPORTS;
  payload: State['abcReports'];
}

interface setUtmostReports {
  type: typeof ActionTypes.SET_UTMOST_REPORTS;
  payload: State['utmostReports'];
}

interface changeUser {
  type: typeof ActionTypes.CHANGE_USER;
}

export type Actions = Logout | changeUser | setAbcReports | setUtmostReports;
