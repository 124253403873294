import React from 'react';
import { Typography } from '@mui/material';
import { CalendarAction } from '../../../Calendar/Form/state';
import SelectAdvisor from '../SelectAdvisor';
import { ChannelToLabel } from '../SelectChannel';
import { FormatTime } from '../../../../utils/date';

import Summary from '../Summary';
import SelectDayForAdvisor from '../SelectDayForAdvisor';
import SelectTimeForAdvisor from '../SelectTimeForAdvisor';

import { Booking } from '../../../../types/local';

import useStyles from '../styles';

const Step2 = (props: {
  state: Booking;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const { classes } = useStyles(false);
  const { state, dispatch } = props;

  const weekday = [
    'sunnuntai',
    'maanantai',
    'tiistai',
    'keskiviikko',
    'torstai',
    'perjantai',
    'lauantai',
  ];

  const convertToDateStrings = (date: Date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const weekdayIndex = dateObj.getDay();
    return `${day}.${month}. ${weekday[weekdayIndex]}`;
  };

  return (
    <div className={classes.step2}>
      {state.view === 1 ? (
        <>
          <Typography variant="h1" className={classes['field-heading']}>
            <span style={{ fontStyle: 'italic' }}>Varaa</span> aika
            sijoitusneuvontaan
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {ChannelToLabel(state.channel)},{' '}
            {state.date && convertToDateStrings(state.date)} klo{' '}
            {state.time && FormatTime(state.time, true)}
          </Typography>
          <div className={classes['form-item']}>
            <Typography variant="body1" className={classes['field-subheading']}>
              Valitse asiantuntija
            </Typography>
            <SelectAdvisor
              state={state}
              selectedOffice={state.officeId}
              value={state.advisorId}
              dispatch={dispatch}
            />
          </div>
        </>
      ) : (
        <>
          <div className="right">
            <Summary value={state} details={false} />
            <div className={classes['form-item']}>
              <SelectDayForAdvisor state={state} dispatch={dispatch} />
            </div>
            <div className={classes['form-item']}>
              <SelectTimeForAdvisor state={state} dispatch={dispatch} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Step2;
