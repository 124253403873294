export interface FaContact {
  juridical: FaJuridical;
  addresses: FaAddress[];
  figures: string;
  memo: string;
  language: FaLanguage;
  figureTargetType: FaFigureTargetType[];
  type: string;
  reportPortfolios: unknown[];
  represents: FaRepresentative[];
  figuresAsObject: FaFiguresAsObject;
  id: number;
  tagMap: FaFigures;
  contactIdentity: FaJuridical;
  contactId: string;
  portfolios: FaPortfolio[];
  externalId: string;
  transactionExtIDLevel: number;
  classification: FaJuridical;
  tagList: unknown[];
  nationality: FaJuridical;
  extContactIdList: unknown[];
  name: string;
  taxCountry: FaJuridical;
  contactExchanges: unknown[];
  assetManagerPortfolios: unknown[];
  representatives: unknown[];
  status: string;
}
export interface FaPortfolio {
  tagList: string[];
  name: string;
  extId: string;
  id: number;
  tagMap: FaTagMap;
  shortName: string;
  type: FaType;
}

export interface FaType {
  code: string;
  name: string;
}

export interface FaTagMap {
  Raportointi: string;
}
export interface FaFiguresAsObject {
  figures: FaFigures;
}

export interface FaFigures {
  asd: string; // This is a dummy
}

export interface FaRepresentative {
  id: number;
  name: string;
  contactId: string;
}

export interface FaFigureTargetType {
  bytes: string;
}

export interface FaLanguage {
  handler: string;
  code: string;
  name: string;
  id: number;
  locale: string;
}

export interface FaAddress {
  zipCode: string;
  country: string;
  address2: string;
  city: string;
  address1: string;
  contact: string;
  phone2: string;
  name: string;
  id: number;
  fax: string;
  email: string;
  phone1: string;
}

export interface FaJuridical {
  handler: string;
  code: string;
  name: string;
  id: number;
}

export interface FaPortfolioOverview {
  columns: FaColumns;
  aggregation: FaAggregation;
  format: FaFormat;
  rows: FaPosition[];
}

export interface FaPosition {
  security: string;
  type: string;
  key: string;
  include: number;
  currency: string;
  amount: number;
  posUnitPriceRow: number;
  code: string;
  securityTradeCode: string;
  securityUpdatecode1: string;
  securityUpdatecode2: string;
  securityUpdatecode3: string;
  securityCountry?: unknown;
  securityIssuer?: unknown;
  securityIssuerName?: unknown;
  securityIssuerDescription?: unknown;
  securityTag: string;
  securityIssueDate: string;
  securityMarketplace: string;
  securitySettlementPlace?: unknown;
  securityMaturityDate: string;
  securityMaturityPrice: number;
  securityClassType1?: unknown;
  securityClassType2?: unknown;
  securityClassType3: string;
  securityClassType4: string;
  securityClassType5?: unknown;
  securityTotalOutstandingAmount: number;
  securityRating: string;
  securityTypeName: string;
  securityTypeCode: string;
  securityCurrencyName: string;
  securityIsinCode: string;
  securityCurrencyCode: string;
  securitySource: string;
  blockSize: number;
  multiplier1: number;
  multiplier2: number;
  invert: string;
  couponCalendar: string;
  couponFrequency?: unknown;
  firstCouponDate?: unknown;
  securityStatus: string;
  underlySecurity?: unknown;
  securitySubType?: unknown;
  linkedPortfolio?: unknown;
  url: string;
  url2: string;
  issuePrice: number;
  callDate?: unknown;
  priceFromSecurity?: unknown;
  securityDescription: string;
  id: number;
  coupon: number;
  currencyExp: string;
  purchaseUnitPrice: number;
  purchaseUnitPriceCurr: number;
  purchaseValue: number;
  purchaseValueWithoutCost: number;
  purchaseUnitPriceWithoutCost: number;
  purchaseUnitPriceWithoutCostCurr: number;
  recPurchaseUnitPrice: number;
  recPurchaseUnitPriceCurr: number;
  recPurchaseValue: number;
  marketUnitPrice: number;
  marketUnitPriceCurr: number;
  marketUnitPriceDate: string;
  marketValue: number;
  change: number;
  changeRelative: number;
  recChange: number;
  recChangeRelative: number;
  representative: string;
  shareOfPortfolio: number;
  days: number;
  accruedInterest?: unknown;
}

export interface FaFormat {
  posUnitPriceRow: string;
  purchaseUnitPrice: string;
  purchaseUnitPriceCurr: string;
  changeRelative: string;
  shareOfPortfolio: string;
  marketUnitPriceDate: string;
  securityIssueDate: string;
  securityMaturityDate: string;
  firstCouponDate: string;
  callDate: string;
}

export interface FaAggregation {
  purchaseValue: string;
  marketValue: string;
}

export interface FaColumns {
  security: string;
  type: string;
  key: string;
  include: string;
  currency: string;
  amount: string;
  posUnitPriceRow: string;
  code: string;
  securityTradeCode: string;
  securityUpdatecode1: string;
  securityUpdatecode2: string;
  securityUpdatecode3: string;
  securityCountry: string;
  securityIssuer: string;
  securityIssuerName: string;
  securityIssuerDescription: string;
  securityTag: string;
  securityIssueDate: string;
  securityMarketplace: string;
  securitySettlementPlace: string;
  securityMaturityDate: string;
  securityMaturityPrice: string;
  securityClassType1: string;
  securityClassType2: string;
  securityClassType3: string;
  securityClassType4: string;
  securityClassType5: string;
  securityTotalOutstandingAmount: string;
  securityRating: string;
  securityTypeName: string;
  securityTypeCode: string;
  securityCurrencyName: string;
  securityIsinCode: string;
  securityCurrencyCode: string;
  securitySource: string;
  blockSize: string;
  multiplier1: string;
  multiplier2: string;
  invert: string;
  couponCalendar: string;
  couponFrequency: string;
  firstCouponDate: string;
  securityStatus: string;
  underlySecurity: string;
  securitySubType: string;
  linkedPortfolio: string;
  url: string;
  url2: string;
  issuePrice: string;
  callDate: string;
  priceFromSecurity: string;
  securityDescription: string;
  id: string;
  coupon: string;
  currencyExp: string;
  purchaseUnitPrice: string;
  purchaseUnitPriceCurr: string;
  purchaseValue: string;
  purchaseValueWithoutCost: string;
  purchaseUnitPriceWithoutCost: string;
  purchaseUnitPriceWithoutCostCurr: string;
  recPurchaseUnitPrice: string;
  recPurchaseUnitPriceCurr: string;
  recPurchaseValue: string;
  marketUnitPrice: string;
  marketUnitPriceCurr: string;
  marketUnitPriceDate: string;
  marketValue: string;
  change: string;
  changeRelative: string;
  recChange: string;
  recChangeRelative: string;
  representative: string;
  shareOfPortfolio: string;
  days: string;
  accruedInterest: string;
}

export interface FaTransaction {
  tags?: string;
  id: number;
  version: number;
  type: FaTypeV2;
  typeName: string;
  extId: string;
  intId: string;
  status: string;
  orderStatus?: string;
  transactionDate: string;
  settlementDate: string;
  maturityDate?: string;
  paymentDate?: string;
  terminationDate?: string;
  security?: FaSecurity;
  baseType: string;
  securityCode: string;
  securityName: string;
  account: FaAccount;
  parentPortfolio: FaPortfolioV2;
  secondPortfolio?: FaPortfolioV2;
  amount: number;
  unitPrice: number;
  tradeAmount: number;
  tradeAmountInPortfolioCurrency: number;
  tradeAmountInAccountCurrency: number;
  fxRate: number;
  accountFxRate: number;
  reportFxRate: number;
  currency: FaCurrency;
  tax: number;
  taxType?: unknown;
  tax2: number;
  taxType2?: unknown;
  cost: number;
  cost2: number;
  origTransactionDate?: unknown;
  origUnitPrice: number;
  origTradeAmount: number;
  origTradeAmountInPortfolioCurrency: number;
  origTradeAmountInAccountCurrency: number;
  origFxRate: number;
  accruedInterest: number;
  accruedInterestInPortfolioCurrency: number;
  accruedInterestInAccountCurrency: number;
  accruedInterestInEffective: number;
  yield: number;
  reference: string;
  counterPortfolio?: FaCounterPortfolio;
  extInfo: string;
  intInfo: string;
  basis: string;
  priority: number;
  security2?: unknown;
  ratio: string;
  tradeTime: string;
  currencyCode: string;
  portfolioShortName: string;
  marketPlace?: FaMarketPlace;
  settlementPlace?: unknown;
  swiftMessage?: string;
  processOutput?: unknown;
  processOutputSeparator?: unknown;
  unitPriceView: number;
  origUnitPriceView: number;
  fxRateView: number;
  reportFxRateView: number;
  origFxRateView: number;
  accountFxRateView: number;
  bookTransactionDate: string;
  bookFxRate: number;
  bookFxRateView: number;
  bookUnitPrice: number;
  bookUnitPriceView: number;
  bookTradeAmount: number;
  bookCost: number;
  bookCost2: number;
  bookTax: number;
  bookTax2: number;
  bookAccruedInterest: number;
  coefficient: number;
  formatDecimal: number;
  costType1?: FaCostType1;
  costType2?: FaCostType1;
  compCost1?: unknown;
  compCost2?: string;
  cashflow: number;
  cashflowWithoutCheckAccount: number;
  cashflowInPortfolioCurrency: number;
  cashflowInAccountCurrency: number;
  cashflowWithoutCheckAccountInPortfolioCurrency: number;
  cashflowWithoutCheckAccountInAccountCurrency: number;
  price: number;
  priceInPortfolioCurrency: number;
  priceInAccountCurrency: number;
  grossPrice: number;
  grossPriceInPortfolioCurrency: number;
  grossPriceInAccountCurrency: number;
  tradeAmountInEffective: number;
  tradeAmountInPortfolioEffective: number;
  grossPriceInEffective: number;
  cost1InEffective: number;
  cost2InEffective: number;
  taxInEffective: number;
  tax2InEffective: number;
  transactionDateInEffective: string;
  settlementDateInEffective: string;
  fxRateInEffective: number;
  exPostCostCat1: number;
  exPostCostCat2: number;
  exPostCostCat3: number;
  exPostCostCat4: number;
  exPostCostCat5: number;
  exPostCostCat6: number;
  exPostCostCat7: number;
  exPostCostCat8: number;
  exPostCostCat9: number;
  exPostCostCat10: number;
  exPostCostCat1InPortfolioCurrency: number;
  exPostCostCat2InPortfolioCurrency: number;
  exPostCostCat3InPortfolioCurrency: number;
  exPostCostCat4InPortfolioCurrency: number;
  exPostCostCat5InPortfolioCurrency: number;
  exPostCostCat6InPortfolioCurrency: number;
  exPostCostCat7InPortfolioCurrency: number;
  exPostCostCat8InPortfolioCurrency: number;
  exPostCostCat9InPortfolioCurrency: number;
  exPostCostCat10InPortfolioCurrency: number;
  exPostCostCat1InAccountCurrency: number;
  exPostCostCat2InAccountCurrency: number;
  exPostCostCat3InAccountCurrency: number;
  exPostCostCat4InAccountCurrency: number;
  exPostCostCat5InAccountCurrency: number;
  exPostCostCat6InAccountCurrency: number;
  exPostCostCat7InAccountCurrency: number;
  exPostCostCat8InAccountCurrency: number;
  exPostCostCat9InAccountCurrency: number;
  exPostCostCat10InAccountCurrency: number;
  exPostSecCostCat1: number;
  exPostSecCostCat2: number;
  exPostSecCostCat3: number;
  exPostSecCostCat4: number;
  exPostSecCostCat5: number;
  exPostSecCostCat6: number;
  exPostSecCostCat7: number;
  exPostSecCostCat8: number;
  exPostSecCostCat9: number;
  exPostSecCostCat10: number;
  exPostSecCostCat1InPortfolioCurrency: number;
  exPostSecCostCat2InPortfolioCurrency: number;
  exPostSecCostCat3InPortfolioCurrency: number;
  exPostSecCostCat4InPortfolioCurrency: number;
  exPostSecCostCat5InPortfolioCurrency: number;
  exPostSecCostCat6InPortfolioCurrency: number;
  exPostSecCostCat7InPortfolioCurrency: number;
  exPostSecCostCat8InPortfolioCurrency: number;
  exPostSecCostCat9InPortfolioCurrency: number;
  exPostSecCostCat10InPortfolioCurrency: number;
  exPostSecCostCat1InAccountCurrency: number;
  exPostSecCostCat2InAccountCurrency: number;
  exPostSecCostCat3InAccountCurrency: number;
  exPostSecCostCat4InAccountCurrency: number;
  exPostSecCostCat5InAccountCurrency: number;
  exPostSecCostCat6InAccountCurrency: number;
  exPostSecCostCat7InAccountCurrency: number;
  exPostSecCostCat8InAccountCurrency: number;
  exPostSecCostCat9InAccountCurrency: number;
  exPostSecCostCat10InAccountCurrency: number;
  exPost3rdCostCat1: number;
  exPost3rdCostCat2: number;
  exPost3rdCostCat3: number;
  exPost3rdCostCat4: number;
  exPost3rdCostCat5: number;
  exPost3rdCostCat6: number;
  exPost3rdCostCat7: number;
  exPost3rdCostCat8: number;
  exPost3rdCostCat9: number;
  exPost3rdCostCat10: number;
  exPost3rdCostCat1InPortfolioCurrency: number;
  exPost3rdCostCat2InPortfolioCurrency: number;
  exPost3rdCostCat3InPortfolioCurrency: number;
  exPost3rdCostCat4InPortfolioCurrency: number;
  exPost3rdCostCat5InPortfolioCurrency: number;
  exPost3rdCostCat6InPortfolioCurrency: number;
  exPost3rdCostCat7InPortfolioCurrency: number;
  exPost3rdCostCat8InPortfolioCurrency: number;
  exPost3rdCostCat9InPortfolioCurrency: number;
  exPost3rdCostCat10InPortfolioCurrency: number;
  exPost3rdCostCat1InAccountCurrency: number;
  exPost3rdCostCat2InAccountCurrency: number;
  exPost3rdCostCat3InAccountCurrency: number;
  exPost3rdCostCat4InAccountCurrency: number;
  exPost3rdCostCat5InAccountCurrency: number;
  exPost3rdCostCat6InAccountCurrency: number;
  exPost3rdCostCat7InAccountCurrency: number;
  exPost3rdCostCat8InAccountCurrency: number;
  exPost3rdCostCat9InAccountCurrency: number;
  exPost3rdCostCat10InAccountCurrency: number;
  hidden: boolean;
  overrideFifoTransactionId?: string;
  overrideFifoTransactionExtId?: string;
  contractAuditInfo?: unknown;
  settlement?: FaMarketPlace;
  tradeOrder: boolean;
  cost1Data?: unknown;
  cost2Data?: FaCost2Datum[];
  tradeAmountBase: number;
  earliestDate: string;
  prePaidOrder: boolean;
  extIdLevel: string;
  transaction: boolean;
  ratio1: number;
  ratio2: number;
  ratioMultiplier: number;
  effectivePaymentDate: string;
  profit: number;
  effectiveReportFxRate: number;
  reversedRatioMultiplier: number;
  unitPriceMultiplier: number;
  intIdReal: string;
  linkedBuyingTransactionId?: string;
  transactionId: string;
  effectiveCoefficient: number;
  effectiveUnitPrice: number;
  effectiveTransactionDate: string;
  effectiveTradeAmount: number;
  effectiveFxRate: number;
  effectiveFxRateCorrected: number;
  fxRateCorrected: number;
  bookFxRateCorrected: number;
  totalCost: number;
  effectiveTradeAmountExAccruedInterest: number;
  tradeAmountExAccruedInterest: number;
  tradeAmountExAccruedInterestAndTax: number;
  tradeAmountExTax: number;
  tradeAmountExTaxCashflow: number;
  taxCashflow: number;
  combinedCashflow: number;
  transactionDateAndTimeInXMLFormat: string;
  tradeTimeString: string;
  intInfoAsMaps: Map<string, string>;
  cost1DataTotalValue: number;
  cost2DataTotalValue: number;
  contract: boolean;
  valid: boolean;
  tagsAsSet: string[];
}

export enum FATransactionStatus {
  Ok = 'OK',
  Deleted = 'DEL',
  NotFinished = 'NF',
}

interface FaCost2Datum {
  costTypeValue: number;
  costTypeCode: string;
}

interface FaCostType1 {
  id: number;
  version: number;
  name: string;
  type: string;
  category: number;
  categoryGroup: number;
  names?: unknown;
  namesAsMap: FaHandler;
  handler: FaHandler;
  hibernateLazyInitializer: FaHandler;
}

interface FaMarketPlace {
  id: number;
  version: number;
  code: string;
  name: string;
  exchangeType?: unknown;
  exchangeCountry?: unknown;
  exchangeMic?: unknown;
  operatingMic?: unknown;
  handler: FaHandler;
  hibernateLazyInitializer: FaHandler;
}

interface FaCounterPortfolio {
  name: string;
  extId?: string;
  id: number;
  shortName: string;
  tags: string;
}

interface FaAccount {
  tags?: unknown;
  id: number;
  version: number;
  number: string;
  currency: FaCurrency;
  name: string;
  cashAccount: boolean;
  visibility: boolean;
  shared: boolean;
  hideZero: boolean;
  excludeBalance: boolean;
  sharedAll: boolean;
  portfolio: FaPortfolioV2;
  priority: number;
  bic: string;
  number2?: unknown;
  category?: unknown;
  mandateReference?: unknown;
  calendar?: unknown;
  fixing: number;
  baseInstrument?: unknown;
  spread: number;
  handler: FaHandler;
  tagsAsSet: unknown[];
  hibernateLazyInitializer: FaHandler;
}

interface FaHandler {
  dummy?: string;
}

interface FaPortfolioV2 {
  name: string;
  extId: string;
  id: number;
  shortName: string;
  tags: string;
}

interface FaCurrency {
  name: string;
  securityCode: string;
  id: number;
  isinCode?: unknown;
  tags?: unknown;
  type: FaType2;
}

interface FaCurrency2 {
  id: number;
  name: string;
  tagList: [];
  securityCode: string;
  tagMap: Record<string, unknown>;
}

interface FaExchange {
  code: string;
  id: number;
  name: string;
  handler: string;
}
interface FaSecurity {
  name: string;
  securityCode: string;
  id: number;
  isinCode: string;
  tags: string;
  type: FaType2;
}

interface FaType2 {
  code: string;
  name: string;
  baseType: string;
}

interface FaType3 {
  baseType: string;
  code: string;
  decimalCount: number;
  futureContract: number;
  groupByType: number;
  id: number;
  includeInTotal: number;
  name: string;
  orderNumber: number;
  subTypes: string[];
}

interface FaTypeV2 {
  typeName: string;
  typeCode: string;
  shortName: string;
  purchaseUnitPriceEffect: number;
  marketValueEffect: number;
  costEffect: number;
  cost2Effect: number;
  cost1Effect: number;
  realizedProfitEffect: number;
  tax2Effect: number;
  netCashflowEffect: number;
  cashflowEffect: number;
  amountEffect: number;
  groupByType: number;
  commitment: number;
  costCategory: number;
  costCategoryGroup: number;
  removeSecurityEffect: number;
  profitEffect: number;
  ratioEffect: number;
  fifoEffect: number;
  taxEffect: number;
  dateEffect: number;
}

interface FaClassType3 {
  value: string;
  valuesAsMap: Record<string, unknown>;
  handler: string;
  id: number;
  code: string;
}

interface FaClassType4 {
  code: string;
  handler: string;
  id: number;
  value: string;
  valuesAsMap: Record<string, unknown>;
}

export interface FaMarketData {
  closeMan: string | number;
  close5: string | number;
  close4: string | number;
  close1: number;
  obsDate: string;
  close: number;
  close3: string;
  close2: number;
}

export interface FaSecurityInfo {
  accrualCalendar: string;
  allocations: [];
  amountDecimalCount: number;
  blockSize: number;
  businessDayConvention: string;
  classType3: FaClassType3;
  classType4: FaClassType4;
  coefficientCalculation: number;
  convexity: number;
  coupon: number;
  coupons: [];
  currency: FaCurrency2;
  description: string;
  exAnteSecCostCat1: number;
  exAnteSecCostCat2: number;
  exAnteSecCostCat3: number;
  exAnteSecCostCat4: number;
  exAnteSecCostCat5: number;
  exAnteSecCostCat6: number;
  exAnteSecCostCat7: number;
  exAnteSecCostCat8: number;
  exAnteSecCostCat9: number;
  exAnteSecCostCat10: number;
  exCouponPeriod: number;
  exPostSecCostCat1: number;
  exPostSecCostCat2: number;
  exPostSecCostCat3: number;
  exPostSecCostCat4: number;
  exPostSecCostCat5: number;
  exPostSecCostCat6: number;
  exPostSecCostCat7: number;
  exPostSecCostCat8: number;
  exPostSecCostCat9: number;
  exPostSecCostCat10: number;
  exchange: FaExchange;
  figureTargetType: { bytes: string }[];
  figures: string;
  figuresAsObject: { figures: Record<string, unknown> };
  holidayCalendar: string;
  id: number;
  indexBaseValue: number;
  invert: number;
  isinCode: string;
  issueDate: string;
  issuePrice: number;
  kickbackPercentage: number;
  macaulayDuration: number;
  managementFee: number;
  managementFeePercentage: number;
  market: string;
  maturityDate: string;
  maturityPrice: number;
  maxManagementFee: number;
  maxPaymentFee: number;
  minManagementFee: number;
  minPaymentFee: number;
  minTradeAmount: number;
  modifiedDuration: number;
  multiplier: number;
  multiplierView: number;
  name: string;
  offsetDays: number;
  paymentFee: number;
  paymentFeePercentage: number;
  rating: string;
  redemptionFrequency: string;
  redemptions: [];
  referenceIndexCalculationMode: string;
  referenceIndexConvention: string;
  referenceIndexlag1: number;
  referenceIndexlag2: number;
  securityCode: string;
  securityExCostMap: Record<string, unknown>;
  securityExCosts: [];
  settlementDateOffset: number;
  share: number;
  source: string;
  spread: number;
  startUpFee: number;
  status: string;
  stubConvention: string;
  tagList: [];
  tagMap: Record<string, unknown>;
  tradeCode: string;
  type: FaType3;
  typeOrder: number;
  updateCode1: string;
  updateCode2: string;
  updateCode3: string;
  url: string;
  url2: string;
  yieldConvention: string;
  ytm: number;
}
