import React from 'react';

import Form from './Form';

import useStyles from './styles';
import LoginHelp from '../loginHelp';

const LoginBox = (): React.ReactElement => {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes['login-container']}>
        <Form />
      </div>
      <div className={classes.loginHelp}>
        <LoginHelp />
      </div>
    </div>
  );
};

export default LoginBox;
