import { DataUpdates } from '../../types/firestore';

export enum ActionTypes {
  SET_DATA_UPDATES = 'SET_DATA_UPDATES',
}

export type State = Partial<DataUpdates>;

interface SetDataUpdatesAction {
  type: typeof ActionTypes.SET_DATA_UPDATES;
  payload: State;
}

export type Actions = SetDataUpdatesAction;
