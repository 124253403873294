import React from 'react';
import firebase from 'firebase/app';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import Response from './Response';

export function handleResetPassword() {
  return (
    <Response success={true}>
      <div className="body">
        Ole hyvä ja tee salasanan asettaminen vahvan tunnistautumisen kautta.
      </div>
      <Link to={'/auth/auth'}>
        <Button>Siirry tunnistautumaan</Button>
      </Link>
    </Response>
  );
}

export async function handleRecoverEmail(
  auth: firebase.auth.Auth,
  actionCode: string
) {
  try {
    const info = await auth.checkActionCode(actionCode);
    const restoredEmail = info.data.email;
    await auth.applyActionCode(actionCode);
    return (
      <Response success={true}>
        <div className="title">Sähköpostiosoitteen palauttaminen onnistui</div>
        <div className="body">
          <div>Osoite: {restoredEmail}.</div>
          <div>
            Tilillesi pyydettiin sähköpostiosoitteen vaihtoa. Siirry vahvan
            tunnistautumisen kautta päivittämään kirjautumistietosi.
          </div>
          <div>
            Mikäli et ole pyytänyt sähköpostiosoitteen vaihtoa, tämä viesti on
            aiheeton.
          </div>
        </div>
        <Link to={'/auth/auth'}>
          <Button>Siirry tunnistautumaan</Button>
        </Link>
      </Response>
    );
  } catch (e) {
    return (
      <Response success={false}>
        <div className="title">Virheellinen koodi.</div>
      </Response>
    );
  }
}

export async function handleVerifyEmail(
  auth: firebase.auth.Auth,
  actionCode: string
): Promise<React.ReactElement> {
  try {
    await auth.applyActionCode(actionCode);
    return (
      <Response success={true}>
        <div className="title">Sähköpostin vahvistus onnistui</div>
        <div className="body">
          Voit sulkea tämän ikkunan ja siirtyä palveluun tunnistautumissivulta
          tai alla olevasta painikkeesta.
        </div>
        <Link to="/">
          <Button>Kirjaudu palveluun</Button>
        </Link>
      </Response>
    );
  } catch (e) {
    return (
      <Response success={false}>
        <div className="title">Vahvistus on vanhentunut</div>
        <div className="body">
          Lähetä uusi vahvistusviesti sähköpostiisi ja yritä uudelleen.
        </div>
      </Response>
    );
  }
}
