/**
 * Scto Grotesk A Imports
 */
import SctoGroteskARegularWoff2 from './SctoGroteskARegular.woff2';
import SctoGroteskARegularWoff from './SctoGroteskARegular.woff';
import SctoGroteskARegularItalicWoff2 from './SctoGroteskARegularItalic.woff2';
import SctoGroteskARegularItalicWoff from './SctoGroteskARegularItalic.woff';
import SctoGroteskAMediumWoff2 from './SctoGroteskAMedium.woff2';
import SctoGroteskAMediumWoff from './SctoGroteskAMedium.woff';
import SctoGroteskAMediumItalicWoff2 from './SctoGroteskAMediumItalic.woff2';
import SctoGroteskAMediumItalicWoff from './SctoGroteskAMediumItalic.woff';

/**
 * SaolText imports
 */
import SaolTextBookWoff2 from './SaolText-Book.woff2';
import SaolTextBookWoff from './SaolText-Book.woff';
import SaolTextBookItalicWoff2 from './SaolText-BookItalic.woff2';
import SaolTextBookItalicWoff from './SaolText-BookItalic.woff';
import SaolTextSemiboldWoff2 from './SaolText-Semibold.woff2';
import SaolTextSemiboldWoff from './SaolText-Semibold.woff';
import SaolTextSemiboldItalicWoff2 from './SaolText-SemiboldItalic.woff2';
import SaolTextSemiboldItalicWoff from './SaolText-SemiboldItalic.woff';

/**
 * SaolDisplay
 */
import SaolDisplaySemiboldWoff2 from './SaolDisplay-Semibold.woff2';
import SaolDisplaySemiboldWoff from './SaolDisplay-Semibold.woff';
import SaolDisplaySemiboldItalicWoff2 from './SaolDisplay-SemiboldItalic.woff2';
import SaolDisplaySemiboldItalicWoff from './SaolDisplay-SemiboldItalic.woff';

/**
 *  Scto Grotesk A font-face
 */
export const sctoRegular = `
@font-face {
  font-family: Scto Grotesk A;
  src:
    local(Scto Grotesk A),
    local(Scto Grotesk A Regular),
    url(${SctoGroteskARegularWoff}) format(woff),
    url(${SctoGroteskARegularWoff2}) format(woff2);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
`;

export const sctoItalic = `
@font-face {
  font-family: Scto Grotesk A;
  src: 
    local(Scto Grotesk A),
    local(Scto Grotesk A Regular Italic),
    url(${SctoGroteskARegularItalicWoff}) format(woff),
    url(${SctoGroteskARegularItalicWoff2}) format(woff2);
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
`;

export const sctoMedium = `
@font-face {
  font-family: Scto Grotesk A;
  src: 
    local(Scto Grotesk A),
    local(Scto Grotesk A Medium),
    url(${SctoGroteskAMediumWoff}) format(woff),
    url(${SctoGroteskAMediumWoff2}) format(woff2);
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
`;

export const sctoMediumItalic = `
@font-face {
  font-family: Scto Grotesk A;
  src: 
    local(Scto Grotesk A),
    local(Scto Grotesk A Medium Italic),
    url(${SctoGroteskAMediumItalicWoff}) format(woff),
    url(${SctoGroteskAMediumItalicWoff2}) format(woff2);
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}
`;

/**
 * SaolText font-face
 */
export const saoltextBook = `
@font-face {
  font-family: Saol Text;
  src: 
  local(Saol Text),
  local(Saol Text Book),
    url(${SaolTextBookWoff}) format(woff),
    url(${SaolTextBookWoff2}) format(woff2);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
`;

export const saoltextBookItalic = `
@font-face {
  font-family: Saol Text;
  src:
    local(Saol Text),
    local(Saol Text Book Italic),
    url(${SaolTextBookItalicWoff}) format(woff),
    url(${SaolTextBookItalicWoff2}) format(woff2);
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
`;

export const saoltextSemibold = `
@font-face {
  font-family: Saol Text;
  src:
    local(Saol Text),
    local(Saol Text Semibold),
    url(${SaolTextSemiboldWoff}) format(woff),
    url(${SaolTextSemiboldWoff2}) format(woff2);
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
`;

export const saoltextSemiboldItalic = `
@font-face {
  font-family: Saol Text;
  src:
    local(Saol Text),
    local(Saol Text Semibold Italic),
    url(${SaolTextSemiboldItalicWoff}) format(woff),
    url(${SaolTextSemiboldItalicWoff2}) format(woff2);
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}`;

/**
 * SaolDisplay font-face
 */
export const saolDisplaySemibold = `
@font-face {
  font-family: Saol Display;
  src:
    local(Saol Display),
    local(Saol Text Semibold),
    url(${SaolDisplaySemiboldWoff}) format(woff),
    url(${SaolDisplaySemiboldWoff2}) format(woff2);
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
`;

export const saolDisplaySemiboldItalic = `
@font-face {
  font-family: Saol Display;
  src:
    local(Saol Display),
    local(Saol Text Semibold Italic),
    url(${SaolDisplaySemiboldItalicWoff}) format(woff),
    url(${SaolDisplaySemiboldItalicWoff2}) format(woff2);
  font-style: italic;
}
`;

export default [
  sctoRegular,
  sctoItalic,
  sctoMedium,
  sctoMediumItalic,
  saolDisplaySemibold,
  saolDisplaySemiboldItalic,
  saoltextBook,
  saoltextBookItalic,
  saoltextSemibold,
  saoltextSemiboldItalic,
].join('\r\n');
