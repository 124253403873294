import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  'login-container': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '45px',
    backgroundColor: theme.palette.secondary.main,
    width: '320px',
    borderRadius: '23px',
    padding: '48px 32px 32px 32px',
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      marginTop: '32px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: '48px 16px 32px 16px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(400)]: {
      width: '90%',
      padding: '24px 16px 0 16px',
    },
  },
  'form-container': {
    marginBottom: '16px',
    minHeight: '300px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      minHeight: '200px',
    },
  },
  'form-heading': {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '2rem',
    },
  },
  'info-container': {
    marginRight: '32px',
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      marginRight: '0px',
    },
  },
  'info-buttons': {
    width: '100%',
    textTransform: 'none',
    marginTop: '24px',
    '&.opposite': {
      background: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  'login-actions-container': {
    '& form': {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginTop: '78px',
      [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
        marginTop: '45px',
        display: 'block',
      },
      [theme.breakpoints.down(400)]: {
        marginTop: '10px',
      },
    },
  },
  'login-fields-container': {
    width: '290px',
    '& > div:not(:first-child)': {
      marginTop: '16px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      width: '100%',
    },
  },
  'login-fields-content': {
    marginTop: '32px',
    [theme.breakpoints.down(400)]: {
      marginTop: '10px',
    },
  },
  input: {
    backgroundColor: theme.palette.secondary.light,
  },
  buttonText: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonIcon: {
    marginRight: '6px',
  },
  loginHelp: {
    maxWidth: '320px',
    padding: '32px 32px 8px',
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      padding: '32px 0px 0px 0px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      textAlign: 'center',
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
