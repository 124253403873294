import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { RootState } from '../store';
import { isUserInGroup } from '../utils/user';
import Loading from '../views/Loading';

interface PrivateRouteProps {
  exact?: boolean;
  component: React.ComponentType<unknown>;
  roles: string[];
}

// this is simple trick updating react router from 5 to 6 to keep using path params as props
const ComponentWrapper = (Component: React.ComponentType<unknown>) => {
  const { ...props } = useParams();
  return <Component {...props} />;
};

const PrivateRoute = ({
  component,
  roles,
}: PrivateRouteProps): React.ReactElement => {
  const user = useSelector((state: RootState) => state.user.fsUser);

  return user ? (
    user && roles?.every((role) => isUserInGroup(user, role)) ? (
      ComponentWrapper(component)
    ) : (
      <Navigate to="/sivua-ei-löytynyt" />
    )
  ) : (
    <Loading />
  );
};

export default PrivateRoute;
