import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    borderRadius: '10px 10px 0 0',
    borderBottom: '1px solid' + theme.palette.primary.main,
    textTransform: 'none',
    height: '34px',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',

    '& span': {
      display: 'flex',
      gap: '8.5px',

      '& svg': {
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },

    '&.active': {
      borderBottom: 'none',
      borderTop: '1px solid' + theme.palette.primary.main,
      borderLeft: '1px solid' + theme.palette.primary.main,
      borderRight: '1px solid' + theme.palette.primary.main,
      flex: '1 1 auto',
    },
  },
}));

export default useStyles;
