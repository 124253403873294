import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivacyPolicy from '../views/General/PrivacyPolicy';
import TermsOfUse from '../views/General/TermsOfUse';
import Accessibility from '../views/General/Accessibility';

const GeneralRoute = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/yleista/tietosuoja" element={<PrivacyPolicy />}></Route>
      <Route path="/yleista/kayttoehdot" element={<TermsOfUse />}></Route>
      <Route path="/yleista/saavutettavuus" element={<Accessibility />}></Route>
    </Routes>
  );
};

export default GeneralRoute;
