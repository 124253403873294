import { makeStyles } from 'tss-react/mui';

export const useStylesSelect = makeStyles()((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '23px',
    color: theme.palette.primary.main,
    minWidth: '100px',
    backgroundColor: theme.palette.grey[100],
    paddingLeft: '32px',
    paddingRight: '40px !important',
    height: '31px',
  },
  select: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    top: 'calc(50% - 5px)',
    right: '16px',
  },
}));

export const useStylesMenu = makeStyles()(() => ({
  paper: {
    borderRadius: '23px',
  },
  list: {
    padding: '0',
  },
}));

export const useStylesMenuList = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '23px',
    '& li': {
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    '& li:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      height: '46px',
    },
    '& li:hover': {
      backgroundColor: `${theme.palette.grey[400]}`,
    },
    '& li:first-child': {
      borderTopLeftRadius: '23px',
      borderTopRightRadius: '23px',
    },
    '& li:last-child': {
      borderBottomLeftRadius: '23px',
      borderBottomRightRadius: '23px',
    },
    '& li.Mui-disabled': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '& li[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '& li[aria-selected="true"]:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  },
}));
