import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Page from '../Page';
import RichText from '../../components/RichText';
import { subscribeToContentIfAnonymous } from '../../utils/firebase';

const PrivacyPolicy = (): React.ReactElement => {
  const privacyPolicy = useSelector(
    (state: RootState) => state.content.globalContent?.privacyPolicy
  );

  useEffect(() => {
    subscribeToContentIfAnonymous();
  }, []);

  return (
    <Page title="Tietosuoja">
      <RichText
        body={privacyPolicy?.body.json}
        links={privacyPolicy?.body.links}
      />
    </Page>
  );
};

export default PrivacyPolicy;
