import useSWR from 'swr';
import { get } from './http';
import { SWR_OPTIONS } from './common';
import type { SecurityDetail } from '../types/morningstar';

export const useMorningStarData = (isin: string) => {
  const swr = useSWR<SecurityDetail[]>(
    `${process.env.REACT_APP_API_URL}/securityDetail/${isin}`,
    get,
    SWR_OPTIONS
  );

  return swr;
};
