import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100%',
  },
  'app-center': {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    '&.login': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  'app-content-area': {
    maxWidth: theme.breakpoints.values.fullSize,
    display: 'flex',
    flex: 1,
  },
  'viewed-user': {
    height: '30px',
    color: 'white',
    // textTransform: 'uppercase',
    textAlign: 'center',
    padding: '5px',
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default useStyles;
