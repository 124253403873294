import React from 'react';
import { azureSignin } from '../../utils/firebase';
import { Button, useTheme } from '@mui/material';
const BackofficeLogin = () => {
  const theme = useTheme();

  return (
    <div>
      <Button
        // browsers do not like a pop-up without button trigger, so we cannot use useEffect
        onClick={() => azureSignin('')}
        sx={{
          width: '250px',
          height: '100px',
          backgroundColor: theme.palette.secondary.main,
          margin: '20px',
          color: theme.palette.primary.main,
        }}
        variant="contained"
      >
        Microsoft Login
      </Button>
    </div>
  );
};

export default BackofficeLogin;
