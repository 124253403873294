import firebase from 'firebase/app';
import { DataSource } from '../dataSource';
import { store } from '../../store';
import {
  setInsurancesOverview,
  setInsurancesPosition,
  setInsuranceSecurityInfo,
} from '../../store/investments/insurances/actions';
import {
  addFundsSecurityInfo,
  addSecurityDetails,
} from '../../store/investments/funds/actions';
import {
  addStructuresSecurities,
  addStructuresSecurityInfo,
} from '../../store/investments/structures/actions';
import { UpdateType } from '../../types/api';
import {
  FSInsuranceOverview,
  FSInsurancePosition,
} from '../../types/firestore';
import { DataSubscription } from '../dataSubscription';
import { SecurityDetail } from '../../types/morningstar';
import { getMorningStarDataWithToken, getFundsContentfulData } from './funds';
import { getSecurityData, getStructuresContentfulData } from './structures';
import { Tuotetyyppi } from '../../types/abc';
import { Insurance } from '../../types/contentful';
import { getInsurancesInfoAll } from '../../utils/contentful';
import { utcToZonedTime } from 'date-fns-tz';
import { TZ } from '../../utils/date';

export const getInsurancesContentfulData = async (): Promise<Insurance[]> => {
  return await getInsurancesInfoAll();
};

export class InsurancesOverviewDataSource extends DataSource {
  name: UpdateType = 'insurances-overview-positions';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const overviewsRef = db
      .collection('insurances')
      .doc(uid)
      .collection('overviews')
      .doc(utcToZonedTime(Date.now(), TZ).toISOString().split('T')[0]);

    const firestoreUnsubscribe = overviewsRef.onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as FSInsuranceOverview | undefined;

        const idToken = await this.getIdToken();

        if (data) {
          store.dispatch(setInsurancesOverview(data.positions));
        } else if (idToken) {
          this.requestUpdate(
            { target: 'insurances-overview-positions', parameters: null, uid },
            idToken
          );
        }

        if (!store.getState().investments.insurances.securityInfo.length) {
          getInsurancesContentfulData().then((v) =>
            store.dispatch(setInsuranceSecurityInfo(v))
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubscribe = () => {
      firestoreUnsubscribe();
    };

    return new DataSubscription(this.name, unsubscribe);
  }
}

export class InsurancesPositionDataSource extends DataSource {
  name: UpdateType = 'insurances-positions';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const positionsRef = db
      .collection('insurances')
      .doc(uid)
      .collection('positions')
      .doc(utcToZonedTime(Date.now(), TZ).toISOString().split('T')[0]);

    const firestoreUnsubscribe = positionsRef.onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as FSInsurancePosition | undefined;
        const idToken = await this.getIdToken();

        if (data) {
          store.dispatch(setInsurancesPosition(data.positions));
        } else if (idToken) {
          this.requestUpdate(
            { target: 'insurances-positions', parameters: null, uid },
            idToken
          );
        }

        if (data && idToken) {
          /** Additional data for Funds */
          const fundIsins = data.positions
            .filter((v) => v.Tuotetyyppi === Tuotetyyppi.Rahastot)
            .map((p) => p.ISIN);

          getMorningStarDataWithToken(idToken, fundIsins).then(
            (v: SecurityDetail[][]) => {
              const securities = v.map((s) => s[0]);
              store.dispatch(addSecurityDetails(securities));
            }
          );
          getFundsContentfulData(fundIsins).then((v) =>
            store.dispatch(addFundsSecurityInfo(v))
          );

          /** Additional data for Structures */
          const structuresIsins = data.positions
            .filter((v) => v.Tuotetyyppi === Tuotetyyppi.Strukturoidut)
            .map((p) => p.ISIN);

          getSecurityData(db, structuresIsins).then((v) => {
            store.dispatch(addStructuresSecurities(v));
          });
          getStructuresContentfulData(structuresIsins).then((v) =>
            store.dispatch(addStructuresSecurityInfo(v))
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );

    const unsubscribe = () => {
      firestoreUnsubscribe();
    };

    return new DataSubscription(this.name, unsubscribe);
  }
}
