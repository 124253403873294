import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import Vertical from '../Vertical';
import useStyles from './styles';

export const Drawer = (props: {
  children: React.ReactElement[];
}): React.ReactElement => {
  const { classes, cx } = useStyles();
  const open = useSelector((state: RootState) => state.ui.isNavigationOpen);

  return (
    <div className={cx(classes['drawer-container'], { 'nav-open': open })}>
      <Vertical>{props.children}</Vertical>
    </div>
  );
};
