import { Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NotFound = (): React.ReactElement => {
  return (
    <div className="not-found-element">
      <Typography variant="h1">Sivua ei löytynyt</Typography>
      <Typography variant="body1">Valitsemaasi sivua ei löytynyt</Typography>
      <Link component={RouterLink} to="/">
        Palaa etusivulle
      </Link>
    </div>
  );
};

export default NotFound;
