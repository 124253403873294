import React from 'react';
import { RadioGroup, FormControlLabel, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Radio from '../../../../components/Radio';

import { ContactUsAction } from '../state';

interface RecipientSelection {
  value: string;
  label: string;
}

const SelectTo = (props: {
  value: string;
  dispatch: React.Dispatch<ContactUsAction>;
}) => {
  const { value, dispatch } = props;
  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const advisors = useSelector((state: RootState) => state.content.advisors);
  const personalAdvisor =
    fsUser && advisors.find((v) => fsUser.neuvonantajaID === v.NeuvonantajaID);
  const advisorSelection = personalAdvisor && {
    label: `Neuvonantajasi: ${personalAdvisor.NeuvonantajaNimi}`,
    value: personalAdvisor.Sahkopostiosoite,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'set-to',
      payload: (event.target as HTMLInputElement).value,
    });
  };

  const helpDesk = {
    value: 'omasalkku@alexandria.fi',
    label: 'Asiakaspalvelu',
  };

  const options: RecipientSelection[] = advisorSelection
    ? [helpDesk, advisorSelection]
    : [helpDesk];

  return (
    <>
      <Typography style={{ textTransform: 'uppercase' }}>
        Viestin vastaanottaja
      </Typography>

      <RadioGroup
        aria-label="Vastaanottaja"
        name="recipient"
        value={value}
        onChange={handleChange}
      >
        {options.map((v) => {
          return (
            <FormControlLabel
              key={v.value}
              value={v.value}
              control={<Radio />}
              label={v.label}
            />
          );
        })}
      </RadioGroup>
    </>
  );
};

export default SelectTo;
