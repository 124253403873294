import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import validator from 'validator';
import { RootState } from '../../../../store';
import TextField from '../../../../components/TextField';
import TextArea from '../../../../components/TextArea';
import { Typography } from '@mui/material';
import { BookingPersonal } from '../../../../types/local';
import { CalendarAction } from '../../../Calendar/Form/state';
import { isMobilePhone } from '../../../../utils/common';

import { ReactComponent as Error } from '../../../../images/svg/error.svg';

import useStyles from './styles';

const InfoToLabel = (info: keyof BookingPersonal) => {
  switch (info) {
    case 'firstnames':
      return 'Etunimi *';
    case 'surname':
      return 'Sukunimi *';
    case 'email':
      return 'Sähköposti *';
    case 'phone':
      return 'Puhelinnumero *';
    case 'messageForAdvisor':
      return 'Lisätietoa tai toiveesi tapaamiselle';
    default:
      return '';
  }
};

const Validate = (k: keyof BookingPersonal, value: string) => {
  switch (k) {
    case 'firstnames':
      return validator.isEmpty(value) && 'Puuttuva tieto';
    case 'surname':
      return validator.isEmpty(value) && 'Puuttuva tieto';
    case 'email':
      if (validator.isEmpty(value)) return 'Puuttuva tieto';
      else if (!validator.isEmail(value)) return 'Tarkista kenttä';
      else return '';
    case 'phone':
      if (validator.isEmpty(value)) return 'Puuttuva tieto';
      else if (value.length > 0 && !isMobilePhone(value))
        return 'Tarkista kenttä';
      else return '';
    default:
      return '';
  }
};

const SetInfo = (props: {
  value: BookingPersonal;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const { value, dispatch } = props;
  const { classes, cx } = useStyles();
  const [selectedInput, setSelectedInput] = useState('');
  const [blurredFields, setBlurredFields] = useState<
    Record<keyof BookingPersonal, boolean>
  >({
    firstnames: false,
    surname: false,
    email: false,
    phone: false,
    messageForAdvisor: false,
  });

  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const user = useSelector((state: RootState) => state.user.user);

  const handleFocus = (field: string) => {
    setSelectedInput(
      field === 'messageForAdvisor' ? 'messageForAdvisor' : field
    );
  };

  const handleBlur = (field: keyof BookingPersonal, value: string) => {
    value.length === 0 && setSelectedInput('');
    setBlurredFields((prevBlurredFields) => ({
      ...prevBlurredFields,
      [field]: true,
    }));
  };

  useEffect(() => {
    dispatch({
      type: 'set-personal',
      payload: {
        ...value,
        firstnames: fsUser?.etunimet || value.firstnames,
        surname: fsUser?.sukunimi || value.surname,
        email: user?.email || value.email,
      },
    });
  }, [user, fsUser]);

  return (
    <div className={classes['personal-container']}>
      <div className={classes.header}>
        <Typography variant="body1" className={classes.title}>
          Täytä tietosi
        </Typography>
        <Typography>* pakollinen kenttä</Typography>
      </div>
      <div className={classes.fields}>
        {Object.keys(value).map((v) => {
          const k = v as keyof BookingPersonal;
          const inputValue = value[k];
          const hasValue = inputValue.trim() !== '';
          const validationError = Validate(k, value[k]);

          /** For message we use multirow textfield */
          if (k === 'messageForAdvisor') {
            return (
              <div className={classes.textField} key={k}>
                <Typography
                  className={cx(
                    classes.fieldPlaceholder,
                    { area: true },
                    { focused: selectedInput === k },
                    { filled: hasValue }
                  )}
                >
                  {InfoToLabel(k)}
                </Typography>
                <TextArea
                  key={k}
                  className={cx(classes.textArea, classes.white, {
                    calendar: true,
                  })}
                  //disableUnderline={true}
                  //classes={{
                  //  root: classes.TextAreaRoot,
                  //  focused: classes.TextAreaFocused,
                  //}}
                  //multiline={true}
                  //rows={8}
                  value={value.messageForAdvisor}
                  onFocus={() => handleFocus(k)}
                  onBlur={(e) => handleBlur(k, e.target.value)}
                  onChange={(event) =>
                    dispatch({
                      type: 'set-personal',
                      payload: { ...value, [k]: event.target.value },
                    })
                  }
                />
              </div>
            );
          }

          return (
            <div className={classes.textField} key={k}>
              <Typography
                className={cx(
                  classes.fieldPlaceholder,
                  { focused: selectedInput === k },
                  { filled: hasValue }
                )}
              >
                {InfoToLabel(k)}
              </Typography>
              <TextField
                key={k}
                className={cx(classes.field, classes.white, {
                  calendar: true,
                })}
                name={k}
                value={value[k]}
                autoFocus={selectedInput === k}
                onFocus={() => handleFocus(k)}
                onBlur={(e) => handleBlur(k, e.target.value)}
                onChange={(event) =>
                  dispatch({
                    type: 'set-personal',
                    payload: { ...value, [k]: event.target.value },
                  })
                }
              />
              <div className={classes.errorContainer}>
                {blurredFields[k] && validationError && (
                  <div className={classes.errorContent}>
                    <Typography className={classes.error}>
                      {validationError}
                    </Typography>
                    <Error className={classes.errorIcon} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SetInfo;
