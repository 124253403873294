import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  stepper: {
    background: 'transparent',
    padding: 0,
    width: '68px',
    position: 'absolute',
    margin: '10px auto 0 auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      width: '44px',
    },
    '& .MuiStepConnector-root': {
      display: 'none',
    },
  },
  step: {
    padding: '1px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    '& span': {
      '& span': {
        '& svg': {
          fillOpacity: 1,
          color: theme.palette.white,
          border: '1px solid' + theme.palette.secondary.main,
          borderRadius: '100%',
          '& path': {
            fill: theme.palette.pink.main,
          },
          width: '16px',
          height: '16px',
          [theme.breakpoints.down('xs')]: {
            width: '10px',
            height: '10px',
          },
        },
      },
    },
    '&.active': {
      '& span': {
        '& span': {
          '& svg': {
            fillOpacity: 1,
            fill: theme.palette.secondary.main,
          },
        },
      },
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        display: 'block',
      },
    },
    '& .MuiStepIcon-text': {
      display: 'none',
    },
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 15px 0 15px',
      marginBottom: '20px',
    },
    [theme.breakpoints.down(320)]: {
      padding: '15px 10px 0 10px',
    },
  },
  buttons: {
    display: 'flex',
    borderTop: '1px solid' + theme.palette.primary.main,
    justifyContent: 'space-between',
    margin: '0 30px',
    paddingTop: '24px',
    position: 'sticky',
    bottom: '0',
    backgroundColor: theme.palette.white,
    '&.loose': {
      boxShadow: theme.shadows['5'],
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 15px',
    },
  },
  button: {
    width: '204px',
    height: '45px',
    border: '2px solid' + theme.palette.secondary.main,
    marginBottom: '18px',
    '& .button-label': {
      marginLeft: 'auto',
      marginRight: 'auto',
      textTransform: 'none',
      lineHeight: '12px',
    },
    '& .button-icon': {
      stroke: theme.palette.primary.main,
      marginRight: '14px',
      '&.back': {
        marginRight: '0px',
        marginLeft: '14px',
      },
      [theme.breakpoints.down(320)]: {
        transform: 'scale(0.8)',
        marginRight: '6px',
        '&.back': {
          marginRight: '0px',
          marginLeft: '6px',
        },
      },
    },
    '&:hover': {
      '& .button-icon': {
        stroke: theme.palette.white,
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '130px',
      height: '35px',
      fontSize: '14px',
    },
    [theme.breakpoints.down(400)]: {
      width: '110px',
      fontSize: '13px',
    },
    [theme.breakpoints.down(320)]: {
      width: '100px',
    },
  },
}));

export default useStyles;
