import firebase from 'firebase/app';
import { endOfYesterday, isBefore } from 'date-fns';
// import { useAppDispatch } from '../../store';
import { setAbcReports } from '../../store/reports/actions';
import { store } from '../../store';
import { FSReports } from '../../types/firestore';
import { DataSource, DataSourceUpdateType } from '../dataSource';
import { DataSubscription } from '../dataSubscription';
import { utcToZonedTime } from 'date-fns-tz';
import { TZ } from '../../utils/date';
export class ReportsDataSource extends DataSource {
  name: DataSourceUpdateType = 'reports';
  subscribe(
    uid: string,
    ownUid: string,
    db: firebase.firestore.Firestore
  ): DataSubscription {
    const docs = db.collection('reports').doc(uid);
    const dispatch = store.dispatch;

    const unsubscribe = docs.onSnapshot(
      async (snapshot) => {
        console.log('Received reports snapshot');
        const fsReports = snapshot.data() as FSReports | undefined;
        const idToken = await this.getIdToken();
        /**
         * TODO: fsReports.reports.length might be 0
         * update reports if the data is older than one day?
         */
        if (!fsReports && idToken) {
          this.requestReportUpdate(idToken, uid);
        }

        // Combine Rahamylly investorReports with Firestore CorporateFinancing reports
        // try {
        //   const investorReports: CFInvestorReport[] = await getInvestorReports(
        //     idToken,
        //     uid
        //   );
        //   fsReports?.reports.forEach((r: Report) => {
        //     if (
        //       r.Tuoteryhma === Tuoteryhma.CorporateFinancing &&
        //       r.KulutJaVeloitukset
        //     ) {
        //       const foundInvestorReport = investorReports.find(
        //         (ir: CFInvestorReport) => ir.year === r.Vuosi
        //       );
        //       if (foundInvestorReport) {
        //         r.RahamyllyKulutJaVeloituksetUrl =
        //           foundInvestorReport.downloadUrl;
        //       }
        //     }
        //   });
        // } catch (error) {
        //   console.error('Failed to fetch investor reports', error);
        // }

        if (fsReports) {
          dispatch(setAbcReports(fsReports.reports));

          if (fsReports && this.shouldUpdate(fsReports.t) && idToken)
            this.requestReportUpdate(idToken, uid);
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    return new DataSubscription(this.name, unsubscribe);
  }
  shouldUpdate(t: number | undefined): boolean {
    const lastUpdated = t
      ? utcToZonedTime(t, TZ)
      : utcToZonedTime(Date.now(), TZ);
    return isBefore(lastUpdated, endOfYesterday());
  }
  requestReportUpdate(idToken: string, uid?: string): void {
    if (idToken)
      this.requestUpdate(
        { target: 'reports', parameters: { productGroup: -1 }, uid },
        idToken
      );
  }
}
