/**
 * Logger that logs everything in development and only errors in production.
 * Logging can be forced on by setting `omasalkku.Logging.<logger name>` or `omasalkku.Logging.all` to `true`.
 */
class Logger {
  private name: string;
  constructor(name: string) {
    this.name = name;
  }
  private forceLogging(name: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const config = (window as never).omasalkku;
    return config?.Logging && (config.Logging['all'] || config.Logging[name]);
  }
  private logOrNot() {
    return (
      process.env.NODE_ENV === 'development' || this.forceLogging(this.name)
    );
  }
  log(...args: unknown[]): void {
    if (this.logOrNot()) {
      console.log(`${this.name}:`, ...args);
    }
  }

  error(...args: unknown[]): void {
    console.error(`${this.name}:`, ...args);
  }

  warn(...args: unknown[]): void {
    if (this.logOrNot()) {
      console.warn(`${this.name}:`, ...args);
    }
  }
}

export const useLogger = (name: string): Logger => new Logger(name);
