import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, List } from '@mui/material';
import { AppDispatch, RootState } from '../../../store';
import { addOpenMenu, removeOpenMenu } from '../../../store/ui/actions';
import NavItem from '../../NavItem';
import MenuIcon from '../MenuIcon';
import { NavSpec } from '../../../types/local';
import useStyles from '../styles';
import { filterNavItemsForImpersonating } from '../utils';
import { useIsImpersonating } from '../../../hooks';

const openMenu = (menu: string, dispatch: AppDispatch) => {
  dispatch(addOpenMenu(menu));
};

const closeMenu = (menu: string, dispatch: AppDispatch) => {
  dispatch(removeOpenMenu(menu));
};

const MenuFactory = (props: {
  navItems: NavSpec[];
  isChild?: boolean;
  parentKey?: string;
  parentTo?: string;
}): React.ReactElement[] => {
  // keep hooks at the top level to maintain a consistent hook order
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const selectedNav = useSelector((state: RootState) => state.ui.selectedTab);
  const openMenus = useSelector((state: RootState) => state.ui.openMenus);
  const isImpersonating = useIsImpersonating();

  // helper function to build the menu items recursively without calling hooks
  const buildMenuItems = (
    items: NavSpec[],
    parentKey = '',
    parentTo = ''
  ): React.ReactElement[] => {
    return items.map((item, index) => {
      const to = `${parentTo ? parentTo + item.to : item.to}`;
      const itemKey = `${parentKey ? parentKey : 'nav-item'}${item.to.replace(
        '/',
        '-'
      )}`;
      const childMenu =
        item.children && item.children.length > 0
          ? buildMenuItems(
              filterNavItemsForImpersonating(item.children, isImpersonating),
              itemKey,
              to
            )
          : undefined;

      const selectedInPath =
        selectedNav.includes(itemKey) && childMenu !== undefined;
      const isSelected = selectedNav === itemKey;
      const isOpen = openMenus.indexOf(itemKey) > -1;

      return (
        <React.Fragment key={itemKey + index}>
          <NavItem
            label={item.label}
            to={to}
            onClick={() => {
              if (childMenu && !isOpen) {
                openMenu(itemKey, dispatch);
              }
            }}
            onIconClick={() => {
              if (childMenu) {
                isOpen
                  ? closeMenu(itemKey, dispatch)
                  : openMenu(itemKey, dispatch);
              }
            }}
            selectedInPath={selectedInPath}
            selected={isSelected}
            drawer={true}
            open={isOpen}
            icon={
              childMenu ? (
                <MenuIcon open={isOpen} selected={isSelected} />
              ) : undefined
            }
          />
          {childMenu && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List
                className={cx(
                  classes['child-menu'],
                  'drawer',
                  selectedInPath && classes['selected-path']
                )}
              >
                {childMenu}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return buildMenuItems(props.navItems);
};

export default MenuFactory;
