import { Logout } from '../base';

export enum ActionTypes {
  SET_CONTENT_OFFICES_LOADED = 'SET_OFFICES_LOADED',
  SET_CONTENT_CONTENTS_LOADED = 'SET_CONTENT_CONTENTS_LOADED',
  SET_CONTENT_ADVISORS_LOADED = 'SET_ADVISORS_LOADED',
}

export interface State {
  content: {
    offices: boolean;
    advisors: boolean;
    content: boolean;
  };
}

interface LoadedAction {
  payload: boolean;
}

interface SetContentContentsLoaded extends LoadedAction {
  type: typeof ActionTypes.SET_CONTENT_CONTENTS_LOADED;
}

interface SetContentAdvisorsLoaded extends LoadedAction {
  type: typeof ActionTypes.SET_CONTENT_ADVISORS_LOADED;
}

interface SetContentOfficesLoaded extends LoadedAction {
  type: typeof ActionTypes.SET_CONTENT_OFFICES_LOADED;
}

export type Actions =
  | Logout
  | SetContentAdvisorsLoaded
  | SetContentContentsLoaded
  | SetContentOfficesLoaded;
