import React from 'react';
import { MenuItem, useMediaQuery, useTheme } from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';
import NavItem from '../NavItem';
import Select from '../Select';

import { NavSpec } from '../../types/local';

import useStyles from './styles';

const Nav = (props: {
  items: NavSpec[];
  submenu?: boolean;
  stickLastToRight?: boolean;
}) => {
  const { items, submenu, stickLastToRight } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const matchesSM = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const navItems = items.map((nav, i) => {
    const isSelected = location.pathname == nav.to;

    return matchesSM ? (
      <MenuItem key={nav.to} value={nav.to}>
        {nav.label}
      </MenuItem>
    ) : (
      <NavItem
        key={nav.to}
        label={nav.label}
        to={nav.to}
        selected={isSelected}
        submenu={submenu}
        stickToRight={stickLastToRight && i == items.length - 1}
        disabled={nav.disabled}
      />
    );
  });

  const navigation =
    matchesSM && navItems.length > 1 ? (
      <Select
        className={classes.select}
        value={
          items.find((nav) => nav.to == location.pathname)
            ? location.pathname
            : ''
        }
        placeholder="Valitse"
        onUpdate={(value: string) => navigate(value)}
      >
        {navItems}
      </Select>
    ) : (
      navItems
    );
  return <div className={classes.nav}>{navigation}</div>;
};

export default Nav;
