import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  button: {
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    cursor: 'pointer',
  },
}));

export default useStyles;
