import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  'feed-container': {
    height: '100%',
  },
  feed: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    visibility: 'visible',
    '&.open': {},
    '&.closed': {
      transition: theme.transitions.create('visibility', {
        delay: theme.transitions.duration.leavingScreen,
      }),
      visibility: 'hidden',
    },
  },
  'feed-body': {
    position: 'relative',
    borderRadius: '46px 0px 0px 0px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.closed': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  refresh: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    color: theme.palette.info.main,
  },
  'feed-items-gradient': {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '50px',
    backgroundImage: `-webkit-gradient(linear, left top, left bottom, from(${theme.palette.primary.main}), to(transparent))`,
    borderRadius: '46px 0px 0px 0px',
  },
  'feed-items': {
    height: '100%',
    overflowY: 'auto',
    padding: '50px 50px 0px 50px',
    borderRadius: '46px 0px 0px 0px',
    '&.has-items': {
      padding: '50px 50px 100px 50px',
    },
  },
  'no-items': {
    color: 'white',
    textAlign: 'center',
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('xs')]: {
    'feed-items': {
      padding: '50px 16px 0px 16px',
      '&.has-items': {
        padding: '50px 16px 70px 16px',
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    'feed-items': {
      padding: '50px 50px 0px 50px',
      '&.has-items': {
        padding: '50px 50px 70px 50px',
      },
    },
  },
}));

export default useStyles;
