import { NavSpec } from '../../types/local';

export const filterNavItemsForAdUser = (
  items: NavSpec[],
  isImpersonating: boolean,
  isAdUser: boolean
): NavSpec[] => {
  return items.filter(
    (item) =>
      !(item.hideIfAdUserAndNotImpersonating && isAdUser && !isImpersonating)
  );
};

export const filterNavItemsForImpersonating = (
  items: NavSpec[],
  isImpersonating: boolean
): NavSpec[] => {
  return items.filter((item) => !(item.hideIfImpersonating && isImpersonating));
};
