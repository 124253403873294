import React from 'react';
import useStyles from './styles';

export interface SpinnerProps {
  variant: 'large' | 'medium' | 'small';
  color?: string;
  className?: string;
}

const Spinner = (props: SpinnerProps): React.ReactElement => {
  const { variant, className } = props;
  const { classes, cx } = useStyles(props);
  return (
    <div className={cx(classes.container, variant, className)}>
      <div className={cx('lds-bg-ring', variant)}></div>
      <div className={cx('lds-roller', variant)}>
        {Array(160)
          .fill(null)
          .map((_v, index) => (
            <div key={`${variant}-frame-${index}`} />
          ))}
      </div>
    </div>
  );
};

export default Spinner;
