import {
  Booking,
  BookingChannel,
  BookingView,
  BookingPersonal,
  AdvisorBookingChannel,
} from '../../../types/local';

export const INITIAL_STATE: Booking = {
  officeId: undefined,
  time: '',
  date: undefined,
  advisorId: undefined,
  availableChannels: undefined,
  meetingDuration: '60',
  phone: '',
  channel: BookingChannel.Office,
  view: BookingView.Date,
  personal: {
    firstnames: '',
    surname: '',
    phone: '',
    email: '',
    messageForAdvisor: '',
  },
};

export type CalendarAction =
  | {
      type: 'set-office';
      payload: number | undefined;
    }
  | { type: 'set-duration'; payload: string }
  | { type: 'set-date'; payload: Date }
  | { type: 'set-time'; payload: string }
  | {
      type: 'set-available-channels';
      payload: AdvisorBookingChannel | undefined;
    }
  | { type: 'set-advisor'; payload: number | undefined }
  | { type: 'set-channel'; payload: BookingChannel }
  | { type: 'set-view'; payload: BookingView }
  | { type: 'set-personal'; payload: BookingPersonal }
  | { type: 'set-advisor-phonenumber'; payload: string }
  | { type: 'reset' };

export const reducer = (state: Booking, action: CalendarAction): Booking => {
  switch (action.type) {
    case 'set-office': {
      return {
        ...state,
        officeId: action.payload,
      };
    }
    case 'set-duration': {
      return {
        ...state,
        meetingDuration: action.payload,
      };
    }
    case 'set-date': {
      return {
        ...state,
        date: action.payload,
      };
    }
    case 'set-time': {
      return {
        ...state,
        time: action.payload,
      };
    }
    case 'set-advisor': {
      return {
        ...state,
        advisorId: action.payload,
      };
    }
    case 'set-channel': {
      return {
        ...state,
        channel: action.payload,
      };
    }
    case 'set-view': {
      return {
        ...state,
        view: action.payload,
      };
    }
    case 'set-available-channels': {
      return {
        ...state,
        availableChannels: action.payload,
      };
    }
    case 'set-personal': {
      return {
        ...state,
        personal: action.payload,
      };
    }
    case 'set-advisor-phonenumber': {
      return {
        ...state,
        phone: action.payload,
      };
    }
    case 'reset': {
      return INITIAL_STATE;
    }
    default:
      return { ...state };
  }
};
