import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
  response: {
    borderRadius: '23px 23px 23px 0px',
    padding: '24px',
    background: theme.palette.grey['100'],
    textAlign: 'center',
    '&.success': {},
    '&.error': {
      background: theme.palette.secondary.main,
    },
    '& .title': {
      textTransform: 'uppercase',
      fontWeight: 500,
      fontFamily: 'Scto Grotesk A',
      marginBottom: '16px',
    },
    '& .body': {
      marginBottom: '16px',
    },
  },
}));

export default useStyles;
