import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  'settings-bar': {
    fontFamily: 'Scto Grotesk A, Medium',
    fontSize: '16px',
    display: 'flex',
    textTransform: 'uppercase',
    flex: 1,
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.secondary.main,
    borderRadius: '23px',
    flexDirection: 'row',
    alignItems: 'center',
    height: '46px',
    '& > * ': {
      marginLeft: '32px',
    },
    position: 'relative',
    marginTop: '32px',
  },
  spacer: {
    flexGrow: 2,
  },
  nav: {
    display: 'flex',
  },
  select: {
    '& .MuiSelect-selectMenu': {
      boxShadow:
        'inset 2px 2px 1px #F7F7F7, -3px -3px 4px rgba(255, 255, 255, 0.7), 1px 3px 6px rgba(0, 0, 0, 0.08), 0px 16px 10px -10px rgba(0, 0, 0, 0.1)',
    },
  },
}));

export default useStyles;
