import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  activity: {
    zIndex: 9999,
    position: 'relative',
  },
  'activity-feed': {
    position: 'absolute',
    right: 0,
    height: '100%',
    marginTop: '85px',
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      marginTop: '68px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginTop: '85px',
    },
    width: '0px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.feed-open': {
      width: '472px',
      [theme.breakpoints.down(400)]: {
        width: 'calc(100vw - 60px)',
      },
      [theme.breakpoints.up(400)]: {
        width: '350px',
      },
      [theme.breakpoints.up('md')]: {
        width: '472px',
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    '&.nav-open': {
      width: '0px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    '&.viewed-user': {
      marginTop: '117px',
      [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
        marginTop: '100px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        marginTop: '117px',
      },
    },
  },
  [theme.breakpoints.up('xs')]: {
    activity: {
      display: 'flex',
      flexBasis: '0px',
      //marginTop: '85px',
      '&.open': {
        flexBasis: '360px',
        marginRight: '40px',
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    activity: {
      display: 'flex',
      flexBasis: '0px',
      '&.open': {
        flexBasis: '472px',
        marginRight: '0px',
      },
    },
  },
}));

export default useStyles;
