import React, { useReducer } from 'react';
import { Typography, Grid, Link } from '@mui/material';

import { LoginState, reducer } from '../state';

import useStyles from '../styles';

import Notification from '../../../../components/Notification';
import ArrowButton from '../../../../components/ArrowButton/index';
import LocalLogin from './localLogin';

const initialState: LoginState = {
  username: '',
  password: '',
  showPasswordField: true,
  showAzureInfo: false,
  error: '',
};

const Form = (): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { classes } = useStyles();

  return (
    <Grid
      className={classes['form-container']}
      justifyContent="space-between"
      container
      direction="column"
    >
      <Grid item>
        <Typography variant="h2" className={classes['form-heading']}>
          Kirjaudu <i>Sisään</i>
        </Typography>
        <div className={classes['login-fields-content']}>
          <Typography>
            Kirjaudu sisään
            <br />
            tunnistautumalla
          </Typography>
        </div>
      </Grid>
      <Grid item className={classes['login-actions-container']}>
        {process.env.NODE_ENV === 'production' ? (
          <div>
            <Link href="/auth/auth">
              <ArrowButton text="Tunnistaudu" />
            </Link>
          </div>
        ) : (
          <LocalLogin />
        )}
      </Grid>
      <Notification
        open={state.error !== ''}
        message={state.error}
        onClose={() => {
          dispatch({ type: 'set-error', payload: '' });
        }}
        variant={'error'}
      />
    </Grid>
  );
};

export default Form;
