import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: '10px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    },
  },
  card: {
    background: 'rgba(122, 184, 255, 0.4)',
    borderRadius: '30px',
    position: 'relative',
    overflow: 'visible',
    width: '100%',
    maxWidth: '0.5fr',
    height: '110px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '15px',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(122, 184, 255, 0.6)',
    },
    '&.summary': {
      pointerEvents: 'none',
      [theme.breakpoints.down('xs')]: {
        height: '165px',
      },
    },
    '& .left': {
      width: '80px',
      height: '100%',
    },
    '& .right': {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
      '& .contact': {
        display: 'flex',
        flexDirection: 'column',
        width: '0.5fr',
        top: '50%',
        [theme.breakpoints.down('xs')]: {
          marginBottom: '7px',
        },
      },
      '& .footer': {
        textTransform: 'uppercase',
      },
      '& .summaryContainer': {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
        width: '220px',
        height: '80px',
        gap: '3px',
        background: 'rgba(255, 255, 255, 0.4)',
        borderRadius: '30px',
        margin: '15px 20px 15px 15px',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          height: '60px',
          margin: '0 8px 0 0',
          borderRadius: '15px',
          padding: '10px',
        },
        '& .title': {
          fontSize: '16px',
          fontWeight: 500,
          textTransform: 'lowercase',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          [theme.breakpoints.down(400)]: {
            fontSize: '14px',
          },
          [theme.breakpoints.down(320)]: {
            fontSize: '13px',
          },
        },
        '& .footer': {
          fontSize: '14px',
          fontWeight: 400,
          textTransform: 'none',
        },
      },
    },
    '& .name': {
      fontFamily: 'Saol Display',
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    '& .body': {
      margin: '3px 0 3px 0',
    },
    '&. footer': {
      marginTop: '8px',
    },
    '&.selected': {
      background: theme.palette.lightBlue.dark,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginRight: '0px',
      width: '100%',
    },
  },
  'time-container': {
    marginTop: '16px',
  },
  time: {
    cursor: 'pointer',
    background: theme.palette.grey['100'],
    borderRadius: '23px',
    padding: '8px 16px',
    margin: '8px 16px 0px 0px',
    border: 'none',
    color: theme.palette.primary.main,
    '&.selected': {
      background: theme.palette.secondary.light,
    },
  },
  avatar: {
    width: '80px',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '30px 0 0 30px',
    flex: '0 0 auto',
    '& img': {
      objectFit: 'cover',
      width: '80px',
      height: '100%',
    },
  },
  contact: {
    marginTop: '16px',
  },
  'card-content': {
    marginTop: '244px',
    marginLeft: '32px',
  },
  footer: {
    background: theme.palette.primary.main,
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
    height: '64px',
    '& a': {
      color: theme.palette.secondary.main,
      width: '100%',
      height: '100%',
    },
  },
  advisorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchContainer: {
    display: 'flex',
    position: 'relative',
    width: '410px',
    maxWidth: '410px',
    marginBottom: '10px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  search: {
    border: 'none',
    borderBottom: '1px solid' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: '16px',
    width: '410px',
    maxWidth: '410px',
    paddingBottom: '7px',
    marginTop: '15px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&::placeholder': {
      color: theme.palette.primary.main,
      fontSize: '16px',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  searchIcon: {
    position: 'absolute',
    top: '25%',
    right: 0,
    width: '20px',
    height: '20px',
  },
  check: {
    position: 'absolute',
    display: 'flex',
    zIndex: 5,
    top: '-3px',
    right: '-4px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    fill: 'none',
    stroke: theme.palette.white,
    strokeWidth: '1px',
    width: '10.35px',
    height: '7.2px',
  },
}));

export default useStyles;
