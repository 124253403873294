import { DataSubscription } from './dataSubscription';
import { IUpdateRequestData, UpdateType } from '../types/api';
import { updateRequest } from '../utils/api';
import { getIdToken } from '../utils/firebase';

export type DataSourceUpdateType = UpdateType | 'activities';

export abstract class DataSource {
  abstract name: DataSourceUpdateType;
  /**
   * Subscribe to this `DataSource`. If parameters `uid` and `ownUid` are the same,
   * we are looking at the logged in user, if not, we are looking at someone else.
   * @param uid The currently viewed user's uid
   * @param ownUid The logged in user's uid
   * @param db The Firestore instance to use
   */
  abstract subscribe(
    uid: string,
    ownUid: string,
    db: firebase.default.firestore.Firestore
  ): DataSubscription;
  requestUpdate(payload: IUpdateRequestData, idToken: string): Promise<void> {
    return updateRequest(payload, idToken);
  }
  async getIdToken(): Promise<string | undefined> {
    return getIdToken();
  }
}
