import {
  combineReducers,
  createStore,
  compose,
  applyMiddleware,
  Middleware,
} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import {
  createMigrate,
  MigrationManifest,
  PersistConfig,
  PersistedState,
  persistReducer,
} from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { Logout, ChangeUser } from './base';

// States
import { State as UiState } from './ui/types';
import { State as CalendarState } from './calendar/types';
import { State as UserState } from './user/types';
import { State as ActivityState } from './activity/types';
//TODO: FIX THIS: import { State as InvestmentsState } from './investments/types';
//import { State as DocumentsState } from './documents/types';
import { State as ContentState } from './content/types';
import { State as LoadedState } from './loaded/types';
import { State as DataUpdatesState } from './dataUpdates/types';
import { State as AdminState } from './admin/types';
import { State as ReportState } from './reports/types';

// Reducers
import { uiReducer } from './ui/reducers';
import { calendarReducer } from './calendar/reducers';
import { userReducer } from './user/reducers';
import { activityReducer } from './activity/reducers';
//TODO: FIX THIS: import { investmentsReducer } from './investments/reducers';
//import { documentReducer } from './documents/reducers';
import { contentReducer } from './content/reducers';
import { loadedReducer } from './loaded/reducers';
import { dataUpdatesReducer } from './dataUpdates/reducers';
import { adminReducer } from './admin/reducers';
import { reportsReducer } from './reports/reducers';

import {
  State as InvestmentsState,
  reducers as investmentsReducer,
} from './investments';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

/*
  NOTE! When you modify the state structure, you should increase
  the persistConfig's version and set the key of the object below to the same version.

  This is because when redux-persist's persisted state in session storage is loaded in the app start,
  the version numbers are compared and if they are the same, the persisted state is put into use as is,
  even if the current state model is different. This may cause all kinds of problems when the state 
  is used by the application. So by increasing the version number here, the migration is run on the persisted 
  state in the browser before using it, because the version number stored in localstorage is going 
  to be smaller.  

  The simple migration below just empties the state because we don't have anything in the state that 
  needs to be kept, and redux-persist's createMigrate doesn't work well with typescript, so more 
  complicated migrations need patching to the package and/or complicated typescript helper types.

  Unfortunately migrations don't work backwards, so in development you may need to clean session storage
  if you get errors when changing to a lower version.

  TL;DR: Increase the variable `stateVersion` below by one when the state model changes
*/
const stateVersion = 3;

const migrations: MigrationManifest = {
  [stateVersion]: (state: PersistedState): PersistedState => {
    return {
      _persist: state?._persist || { version: 0, rehydrated: false },
    };
  },
};

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: sessionStorage,
  blacklist: ['activity', 'ui', 'user', 'loaded', 'dataUpdates', 'admin'],
  version: stateVersion,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true }),
};

const uiPersistConfig: PersistConfig<UiState> = {
  key: 'ui',
  storage: sessionStorage,
  blacklist: ['isLoading', 'viewedUser', 'pageScrolledDown'],
};
export type RootState = {
  ui: UiState & PersistPartial;
  calendar: CalendarState;
  user: UserState;
  activity: ActivityState;
  investments: InvestmentsState;
  //TODO: FIX THIS: investments: InvestmentsState;
  //documents: DocumentsState;
  content: ContentState;
  loaded: LoadedState;
  dataUpdates: DataUpdatesState;
  admin: AdminState;
  reports: ReportState;
};

export function logoutAction(): Logout {
  return {
    type: 'LOGOUT',
  };
}

export function changeUserAction(): ChangeUser {
  return {
    type: 'CHANGE_USER',
  };
}

export const rootReducer = combineReducers<RootState>({
  ui: persistReducer(uiPersistConfig, uiReducer),
  calendar: calendarReducer,
  user: userReducer,
  activity: activityReducer,
  investments: investmentsReducer,
  //TODO: FIX THIS: investments: investmentsReducer,
  //documents: documentReducer,
  content: contentReducer,
  loaded: loadedReducer,
  dataUpdates: dataUpdatesReducer,
  admin: adminReducer,
  reports: reportsReducer,
});

export type AppDispatch = typeof store.dispatch;

const persisted = persistReducer(persistConfig, rootReducer);

const middlewares: Middleware[] = [];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middlewares.push(logger);
}
const setReduxDevTools = () =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (window as never).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (window as never).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : compose;

const devTools =
  process.env.NODE_ENV === 'development' ? setReduxDevTools() : compose;

export const store = createStore(
  persisted,
  compose(applyMiddleware(...middlewares), devTools)
);
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
