/* eslint-disable @typescript-eslint/ban-ts-comment */

import { createTheme, Theme } from '@mui/material/styles';
// set default theme settings https://material-ui.com/customization/default-theme/
import { fiFI } from '@mui/material/locale';
import { fiFI as dateFI } from '@mui/x-date-pickers/locales';

import AlexandriaFonts from './fonts';

interface InvestmentColor {
  main: string;
  light: string;
  dark: string;
}

interface InvestmentColors {
  funds: InvestmentColor;
  structures: InvestmentColor;
  insurances: InvestmentColor;
  'corporate-financing': InvestmentColor;
}
interface ContentColors {
  article: string;
  video: string;
  newsletter: string;
  podcast: string;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    orange: Palette['primary'];
    pink: Palette['primary'];
    blue: Palette['primary'];
    lightBlue: Palette['primary'];
    green: Palette['primary'];
    yellow: Palette['primary'];
    lightGreen: Palette['primary'];
    lightRed: Palette['primary'];
    brown: Palette['primary'];
    white: string;
    investment: InvestmentColors;
    content: ContentColors;
  }
  interface PaletteOptions {
    orange: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    lightBlue: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    white: string;
    investment: InvestmentColors;
    content: ContentColors;
  }
}

export enum AlexandriaButtonVariants {
  CornerMoveLeft = 'corner-move-left',
  CornerMoveRight = 'corner-move-right',
  Light = 'light',
  Green = 'green',
  Red = 'red',
  DarkBlue = 'dark-blue',
  Grey = 'grey',
}

export interface AlexandriaButtonVariantOverrides {
  [AlexandriaButtonVariants.CornerMoveLeft]: true;
  [AlexandriaButtonVariants.CornerMoveRight]: true;
  [AlexandriaButtonVariants.Light]: true;
  [AlexandriaButtonVariants.Green]: true;
  [AlexandriaButtonVariants.Red]: true;
  [AlexandriaButtonVariants.DarkBlue]: true;
  [AlexandriaButtonVariants.Grey]: true;
}

declare module '@mui/material/Button/Button' {
  interface ButtonPropsVariantOverrides
    extends AlexandriaButtonVariantOverrides {}
}

export enum AlexandriaPaperVariants {
  SpeechBubble = 'speech-bubble',
  SpeechBubbleFlipped = 'speech-bubble-flipped',
  Clickable = 'clickable',
  BigShadow = 'big-shadow',
  BottomBorderShadow = 'bottom-border-shadow',
}

export interface AlexandriaPaperVariantOverrides {
  [AlexandriaPaperVariants.SpeechBubble]: true;
  [AlexandriaPaperVariants.SpeechBubbleFlipped]: true;
  [AlexandriaPaperVariants.Clickable]: true;
  [AlexandriaPaperVariants.BigShadow]: true;
  [AlexandriaPaperVariants.BottomBorderShadow]: true;
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides
    extends AlexandriaPaperVariantOverrides {}
}

declare module '@mui/material' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    smPlus: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
    tabletPlus: true;
    wide: true;
    widePlus: true;
    fullSize: true;
  }
}
const theme = createTheme();

const palette = {
  content: {
    article: '#FF6063',
    video: '#7AB8FF',
    newsletter: '#FF992A',
    podcast: '#FFB6B6',
  },
  investment: {
    funds: { main: '#7AB8FF', light: '#AFEAFF', dark: '#7AB8FF' },
    structures: { main: '#FF992A', light: '#FFCA5D', dark: '#FF992A' },
    insurances: { main: '#FFB6B6', light: '#FFE9E8', dark: '' },
    'corporate-financing': { main: '#449F6B', light: '#87DBB1', dark: '' },
  },
  primary: {
    main: '#000450', // Blue
    light: '#002388', // Blue highlight
    dark: '#000029', // Blue shadow
    contrastText: '#FF6063',
  },
  secondary: {
    main: '#FF6063', // Red
    light: '#FF938D', // Red highlight
    dark: '#C62936', // Red shadow
    contrastText: '#000450',
  },
  info: {
    main: '#FFB6B6',
    contrastText: '#000450',
  },
  text: {
    primary: '#000450',
    secondary: '#FF6063',
  },
  orange: {
    main: '#FF992A', // Orange
    light: '#FFCA5D', // Orange highlight
    dark: '#C66A00', // Orange shadow
  },
  pink: {
    main: '#FFB6B6', // Pink
    light: '#FFE9E8', // Pink highlight
    dark: '#CB8686', // Pink shadow
  },
  blue: {
    light: '#afeaff',
  },
  lightBlue: {
    main: '#D7F4FF', // Light blue
    light: '#EBFAFF', // Light blue highlight
    dark: '#7AB8FF', // Light blue shadow
  },
  yellow: {
    main: '#FFF4DF',
    dark: '#FFE4AE',
  },
  brown: { main: '#c66a00' },
  green: {
    main: '#007040', // Green
    light: '#449F6B', // Green blue highlight
    dark: '#004318', // Green blue shadow
  },
  lightGreen: {
    main: 'rgba(135, 219, 177, 0.5)',
    dark: '#87DBB1',
  },
  lightRed: {
    main: 'rgba(255, 233, 232, 1)',
    dark: '#FFB6B6',
  },
  grey: {
    '100': '#F7F7F7', // Grey highlight
    '200': '#EEEEEE', // Grey highlight II,
    '300': '#DDDDDD', // Grey midtone
    '400': '#CCCCCC', // Grey shadow,
    '500': '#BBBBBB', // Grey shadow II
    '600': '#777777', // Grey Dark
    '700': '#555555',
  },
  white: '#FFFFFF',
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF',
  },
};

const fonts = {
  sctoGroteskA: ['Scto Grotesk A'].join(','),
  saolDisplay: ['Saol Display'].join(','),
};

const elevatedCardBaseStyling = {
  boxShadow: '0px 20px 24px -24px rgba(0, 0, 0, 0.1)',
  border: `2px solid ${palette.grey['100']}`,
  backgroundColor: 'white',
};

const cornerButtonBaseStyling = {
  backgroundColor: palette.white,
  width: '4rem',
  height: '4rem',
  '&:hover': {
    backgroundColor: palette.lightBlue.main,
    '& > svg': {
      fill: palette.white,
    },
  },
};

const outlinedButtonBaseStyling = {
  padding: '0.5rem 2.5rem',
  '& svg': {
    marginRight: '0.5rem',
  },
  '&:hover svg ': {
    stroke: palette.white,
  },
  '&:disabled': {
    '& .MuiButton-startIcon': {
      '& > svg > path': {
        opacity: 0.2,
      },
    },
  },
};

const paperContainerBorderRadius = '24px';

const defaultTheme: Theme = createTheme({
  palette,
  typography: {
    fontFamily: fonts.sctoGroteskA,
    h1: {
      fontFamily: fonts.saolDisplay,
      fontSize: '5rem',
      overflowWrap: 'anywhere',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.9rem',
      },
      color: palette.text.primary,
    },
    h2: {
      fontFamily: fonts.saolDisplay,
      fontSize: '3rem',
      color: palette.text.primary,
    },
    h3: {
      fontFamily: fonts.saolDisplay,
      fontSize: '1.5rem',
      color: palette.text.primary,
    },
    h4: {
      fontFamily: fonts.saolDisplay,
      fontSize: '2rem',
      color: palette.text.primary,
    },
    subtitle1: {
      fontFamily: fonts.sctoGroteskA,
      textTransform: 'uppercase',
      fontSize: '1.1rem',
      color: palette.text.primary,
    },
    subtitle2: {
      fontFamily: fonts.sctoGroteskA,
      fontSize: '0.8rem',
      color: palette.text.primary,
    },
    caption: {
      fontFamily: fonts.sctoGroteskA,
      fontSize: '1rem',
      color: palette.grey['600'],
    },
  },
  transitions: {
    easing: {
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      leavingScreen: 200,
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'tablet', 'lg', 'wide', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      smPlus: 720, // avoid using
      md: 960,
      tablet: 1024, // avoid using
      tabletPlus: 1144, // avoid using
      lg: 1280,
      wide: 1500, // avoid using
      widePlus: 1620, // avoid using
      xl: 1500,
      fullSize: 1920,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': AlexandriaFonts,
        '@global': {
          '.duotone': {
            position: 'relative',
            '& img.duotone-img': {
              mixBlendMode: 'lighten',
              filter: 'grayscale(1)',
            },
          },
          '.duotone::before': {
            backgroundColor: '#000450',
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          },
          '.duotone::after': {
            backgroundColor: '#FF6063',
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            mixBlendMode: 'darken',
            position: 'absolute',
            top: 0,
            left: 0,
          },
          '.duotone.red::before': {
            backgroundColor: '#FF6063',
          },
          '.duotone.red::after': {
            backgroundColor: '#FF938D',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: paperContainerBorderRadius,
          backgroundColor: palette.grey['100'],
          height: '100%',
        },
      },
      variants: [
        {
          props: {
            variant: 'elevation',
          },
          style: { ...elevatedCardBaseStyling },
        },
        {
          props: {
            variant: AlexandriaPaperVariants.Clickable,
          },
          style: {
            ...elevatedCardBaseStyling,
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',
            '&:hover': {
              transform: 'scale(105%)',
            },
          },
        },
        {
          props: {
            variant: AlexandriaPaperVariants.BigShadow,
          },
          style: {
            ...elevatedCardBaseStyling,
            boxShadow: '0px 40px 50px -55px rgba(0, 0, 0, 0.5)',
          },
        },
        {
          props: {
            variant: AlexandriaPaperVariants.BottomBorderShadow,
          },
          style: {
            ...elevatedCardBaseStyling,
            boxShadow: '0px 6px 6px -4px rgba(0, 0, 0, 0.1)',
          },
        },
        {
          props: {
            variant: AlexandriaPaperVariants.SpeechBubble,
          },
          style: {
            borderBottomLeftRadius: 0,
          },
        },
        {
          props: {
            variant: AlexandriaPaperVariants.SpeechBubbleFlipped,
          },
          style: {
            borderBottomRightRadius: 0,
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: '0px',
          padding: '0px',
          border: '0px',
          background: 'transparent',
          boxShadow: 'none',
          flexDirection: 'column',
          '&:before': {
            background: 'transparent',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0px',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          '&.Mui-expanded': {
            minHeight: 0,
          },
          '& .MuiAccordionSummary-content': {
            margin: '0 0 1rem 0',
            flexGrow: 0,
            '&.Mui-expanded': {
              margin: '0 0 1rem 0',
            },
          },
        },
        expandIconWrapper: {
          marginLeft: '0.5rem',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexDirection: 'column',
          padding: '0px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          fontSize: '1rem',
        },
      },
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            padding: '0.5rem 2.5rem',
            border: `2px solid ${palette.primary.main}`,
            '&:hover': {
              border: `2px solid ${palette.primary.dark}`,
            },
          },
        },
        {
          props: {
            variant: 'contained',
          },
          style: {
            fontWeight: 600,
            textTransform: 'uppercase',
            fontSize: '1.2 rem',
          },
        },
        {
          props: {
            variant: AlexandriaButtonVariants.Light,
          },
          style: {
            ...outlinedButtonBaseStyling,
            height: '3.5rem',
            padding: '0 32px',
            color: palette.text.primary,
            backgroundColor: palette.lightBlue.main,
            '&:hover': {
              backgroundColor: palette.lightBlue.dark,
              color: palette.white,
            },
          },
        },
        {
          props: {
            variant: AlexandriaButtonVariants.CornerMoveLeft,
          },
          style: {
            borderRadius: '0 1.5rem 0 0',
            boxShadow: '4px -4px 12px -8px rgba(0, 0, 0, 0.16)',
            ...cornerButtonBaseStyling,
          },
        },
        {
          props: {
            variant: AlexandriaButtonVariants.CornerMoveRight,
          },
          style: {
            borderRadius: '1.5rem 0 0 0',
            boxShadow: '-6px -6px 16px -8px rgba(0, 0, 0, 0.24)',
            ...cornerButtonBaseStyling,
          },
        },
        {
          props: {
            variant: AlexandriaButtonVariants.Green,
          },
          style: {
            ...outlinedButtonBaseStyling,
            '&:hover': {
              backgroundColor: palette.lightGreen.dark,
              color: palette.white,
            },
            backgroundColor: palette.lightGreen.main,
            border: `2px solid ${palette.lightGreen.dark}`,
          },
        },
        {
          props: {
            variant: AlexandriaButtonVariants.Red,
          },
          style: {
            ...outlinedButtonBaseStyling,
            '&:hover': {
              backgroundColor: palette.lightRed.dark,
              color: palette.white,
            },
            backgroundColor: palette.lightRed.main,
            border: `2px solid ${palette.lightRed.dark}`,
          },
        },
        {
          props: {
            variant: AlexandriaButtonVariants.DarkBlue,
          },
          style: {
            ...outlinedButtonBaseStyling,
            color: palette.white,
            '&:hover': {
              backgroundColor: palette.primary.light,
            },
            backgroundColor: palette.primary.main,
            '&:disabled': {
              backgroundColor: palette.grey[400],
            },
          },
        },
        {
          props: {
            variant: AlexandriaButtonVariants.Grey,
          },
          style: {
            ...outlinedButtonBaseStyling,
            '&:hover': {
              backgroundColor: palette.grey[300],
            },
            color: palette.primary.main,
            backgroundColor: palette.grey[200],
          },
        },
      ],
    },
    MuiListItem: {
      variants: [
        {
          props: {
            // @ts-ignore
            variant: 'warning',
          },
          style: {
            color: palette.pink.dark,
            backgroundColor: palette.pink.main,
            borderBottom: `${palette.pink.dark} thin solid`,
          },
        },
      ],
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: palette.primary.main,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1rem',
          color: theme.palette.grey[600],
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderTop: `2px solid ${theme.palette.primary.dark}`,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          color: palette.grey[200],
          '&.Mui-active': {
            color: palette.primary.main,
            text: {
              fill: palette.white,
            },
          },
          '&.Mui-completed': {
            color: palette.green.light,
            text: {
              fill: palette.white,
            },
          },
        },
        text: {
          fill: palette.primary.main,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: { zIndex: 1000000 },
        paper: {
          border: '8px solid white',
          backgroundImage: `linear-gradient(${palette.white}, ${palette.grey[100]})`,
          borderRadius: paperContainerBorderRadius,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '32px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 1000010,
        },
      },
    },
    /* TODO: is this needed?
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: '1000010 !important',
        },
      },
    }, */
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: palette.grey[500],
        },
      },
    },
    MuiDatePickerToolbar: {
      styleOverrides: {
        root: {
          display: 'none', // DatePickerToolbar of DatePicker is hidden here
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 1000100,
        },
      },
    },
  },
  fiFI,
  dateFI,
});

export default defaultTheme;
