import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Nav from './nav';

import { getRelativeNav } from '../../routes/navItems';
import { NavSpec } from '../../types/local';
import useStyles from './styles';
import { useIsImpersonating } from '../../hooks';
import { filterNavItemsForImpersonating } from '../Nav/utils';

/**
 * Bar to show sub navigation for a page
 */
const SettingsBar = (props: {
  title?: string;
  showNav?: boolean;
  customNav?: NavSpec[];
  hideRelNav?: boolean;
  stickLastToRight?: boolean;
}): React.ReactElement => {
  const {
    title,
    showNav,
    customNav = [],
    hideRelNav,
    stickLastToRight,
  } = props;
  const { classes } = useStyles();
  const location = useLocation();
  const isImpersonating = useIsImpersonating();

  const relNav = showNav ? getRelativeNav(location) : [];
  const navItems = hideRelNav ? [] : relNav;
  const combinedNavItems = useMemo(
    () =>
      filterNavItemsForImpersonating(
        [...customNav, ...navItems],
        isImpersonating
      ),
    [isImpersonating]
  );
  return (
    <div className={classes['settings-bar']}>
      {title && <div>{title}</div>}
      <Nav
        items={combinedNavItems}
        submenu
        stickLastToRight={stickLastToRight}
      />
      <div className={classes.spacer}></div>
    </div>
  );
};

export default SettingsBar;
