import { ActionTypes, Actions, Activity, State } from './types';

export function addActivity(activity: Activity): Actions {
  return {
    type: ActionTypes.ADD_ACTIVITY,
    payload: activity,
  };
}

export function setActivities(activities: State['activities']): Actions {
  return {
    type: ActionTypes.SET_ACTIVITIES,
    payload: activities,
  };
}

export function setBookings(meetings: State['bookings']): Actions {
  return {
    type: ActionTypes.SET_BOOKINGS,
    payload: meetings,
  };
}
