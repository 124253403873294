import React from 'react';

import { Typography } from '@mui/material';
import { Button } from '../../Button';

import useStyles from '../../styles';
import { ContentItemProps } from '..';

export const Meeting = (props: ContentItemProps): React.ReactElement => {
  const { classes, cx } = useStyles();
  const { title, body, header, linkTo } = props;
  return (
    <div className={cx(classes['item-root'], 'meeting')}>
      <div className={classes['content-container']}>
        <div className={cx(classes.content, 'meeting')}>
          <div className={cx('header', classes.header)}>{header}</div>
          <div className="title">
            <Typography variant="h3">{title}</Typography>
          </div>
          <div className="body">{body}</div>
        </div>
        <div className={cx(classes.button, 'meeting')}>
          <Button to={linkTo} />
        </div>
      </div>
    </div>
  );
};
