import useSWR from 'swr';
import { useState } from 'react';
import { getProductListing, type ProductListItem } from '../utils/productData';
import { SWR_OPTIONS } from './common';

export const useProductListing = (productCategory: number | null = null) => {
  const [category, setCategory] = useState<number | null>(productCategory);

  const { data, isLoading, error } = useSWR<ProductListItem[]>(
    `${category || -1}`,
    getProductListing,
    { ...SWR_OPTIONS }
  );

  return {
    category,
    setCategory,
    data: data || [],
    isLoading,
    error,
  };
};
