import {
  AddBookingRequest,
  CalendarDatesByOfficePayload,
  CalendarSlotsByOfficeDatePayload,
  GDPRDataRequest,
} from '../types/abc';
import {
  IUpdateRequestData,
  ContactUs,
  NewUserSubmitData,
  UserBasicInfo,
  HasAcceptedTOSResponse,
  UserUpdateData,
  LogInfo,
} from '../types/api';
import { createURL } from './common';
import { getIdToken } from './firebase';

interface HttpError extends Error {
  statusCode: number;
  data: unknown;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const getErrorMessageFromResponse = async (response: Response) => {
  const contentType = response.headers.get('content-type');

  let errorData;

  if (contentType?.includes('application/json')) {
    // Attempt to parse as JSON
    errorData = await response.json();
  } else {
    // Fallback to text if not JSON
    errorData = await response.text();
  }

  return errorData;
};

export async function fetchWithErrors(
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> {
  const response = await fetch(input, init);

  if (!response.ok) {
    const errorData = await getErrorMessageFromResponse(response);
    if (errorData?.errorType === 'INFO_NOT_UP_TO_DATE') {
      window.history.pushState({}, '', '/paivita_tiedot');
      return response;
    }
    const error: HttpError = {
      name: 'HttpError',
      message: errorData,
      statusCode: response.status,
      data: errorData,
    };
    throw error;
  }

  return response;
}

export const updateRequest = async (
  payload: IUpdateRequestData,
  idToken: string
): Promise<void> => {
  console.log('Sending update request for:', payload.target);
  await fetchWithErrors(`${apiUrl}/update`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const updateGroups = async (
  idToken: string,
  azureAccessToken: string
): Promise<void> => {
  console.log('Updating groups for user');
  await fetchWithErrors(`${apiUrl}/updateGroups`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      azureAccessToken,
    }),
  });
};

export const fetchMorningStarData = async (idToken: string, ISIN: string) => {
  return await fetchWithErrors(`${apiUrl}/securityDetail/${ISIN}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const sendGDPRDataRequest = async (
  idToken: string,
  payload: GDPRDataRequest
): Promise<Response> => {
  return await fetchWithErrors(`${apiUrl}/gdpr/data-request`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const getOpenDatesByOffice = async (
  idToken: string | undefined,
  payload: CalendarDatesByOfficePayload
) => {
  const payloadToString = Object.keys(payload).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: payload[curr as keyof CalendarDatesByOfficePayload]?.toString(),
    };
  }, {});

  return await fetchWithErrors(
    `${apiUrl}/calendar/open-dates-by-office?${new URLSearchParams(
      payloadToString
    )}`,
    {
      method: 'GET',
      headers: {
        Authorization: idToken ? `Bearer ${idToken}` : '',
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getOpenDatesByOffice_v2 = async (
  idToken: string | undefined,
  payload: CalendarDatesByOfficePayload
) => {
  const payloadToString = Object.keys(payload).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: payload[curr as keyof CalendarDatesByOfficePayload]?.toString(),
    };
  }, {});

  return await fetchWithErrors(
    `${apiUrl}/calendar/open-dates-by-office-v2?${new URLSearchParams(
      payloadToString
    )}`,
    {
      method: 'GET',
      headers: {
        Authorization: idToken ? `Bearer ${idToken}` : '',
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getSlotsByOfficeDate = async (
  idToken: string | undefined,
  payload: CalendarSlotsByOfficeDatePayload
) => {
  const payloadToString = Object.keys(payload).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]:
        payload[curr as keyof CalendarSlotsByOfficeDatePayload]?.toString(),
    };
  }, {});

  return await fetchWithErrors(
    `${apiUrl}/calendar/slots-by-office-date?${new URLSearchParams(
      payloadToString
    )}`,
    {
      method: 'GET',
      headers: {
        Authorization: idToken ? `Bearer ${idToken}` : '',
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getSlotsByOfficeDate_v2 = async (
  idToken: string | undefined,
  payload: CalendarSlotsByOfficeDatePayload
) => {
  const payloadToString = Object.keys(payload).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]:
        payload[curr as keyof CalendarSlotsByOfficeDatePayload]?.toString(),
    };
  }, {});

  return await fetchWithErrors(
    `${apiUrl}/calendar/slots-by-office-date-v2?${new URLSearchParams(
      payloadToString
    )}`,
    {
      method: 'GET',
      headers: {
        Authorization: idToken ? `Bearer ${idToken}` : '',
        'Content-Type': 'application/json',
      },
    }
  );
};

export const addBookingRequest = async (
  idToken: string | undefined,
  payload: AddBookingRequest
): Promise<Response> => {
  return await fetchWithErrors(`${apiUrl}/calendar/add-booking`, {
    method: 'POST',
    headers: {
      Authorization: idToken ? `Bearer ${idToken}` : '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const contactUsRequest = async (
  idToken: string | undefined,
  payload: ContactUs
): Promise<Response> => {
  return await fetchWithErrors(`${apiUrl}/contact`, {
    method: 'POST',
    headers: {
      Authorization: idToken ? `Bearer ${idToken}` : '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const getCorporateFinancingLoanStatus = async (
  idToken: string | undefined,
  loanId: number,
  uid: string | null
): Promise<Response> => {
  const url = createURL(`${apiUrl}/corporate/loan-status/${loanId}`, {
    uid: uid,
  });
  return await fetchWithErrors(url, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};

export const getCorporateFinancingAccount = async (
  idToken: string | undefined,
  uid: string | null
): Promise<Response> => {
  const url = createURL(`${apiUrl}/corporate/account`, {
    uid: uid,
  });
  return await fetchWithErrors(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getCorporateFinancingCashBalance = async (
  idToken: string | undefined,
  uid: string | null
): Promise<Response> => {
  const url = createURL(`${apiUrl}/corporate/balance`, {
    uid: uid,
  });
  return await fetchWithErrors(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getCorporateFinancingScheduledWithdrawal = async (
  idToken: string | undefined,
  uid: string | null
): Promise<Response> => {
  const url = createURL(`${apiUrl}/corporate/getscheduled-withdraw`, {
    uid: uid,
  });
  return await fetchWithErrors(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const deleteCorporateFinancingScheduledWithdrawal = async (
  idToken: string | undefined,
  uid: string | null
): Promise<Response> => {
  const url = createURL(`${apiUrl}/corporate/delete-scheduled-withdraw`, {
    uid: uid,
  });
  return await fetchWithErrors(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getCorporateFinancingDepositInfo = async (
  idToken: string | undefined,
  uid: string | null
): Promise<Response> => {
  const url = createURL(`${apiUrl}/corporate/deposit-info`, {
    uid: uid,
  });
  return await fetchWithErrors(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const setCorporateFinancingAutoInvest = async (
  payload: {
    investingActive: boolean;
    uid: string | null;
  },
  idToken: string | undefined
) => {
  await fetchWithErrors(`${apiUrl}/corporate/auto-invest`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const initiateCorporateFinancingWithdraw = async (
  payload: {
    sum: number;
    uid: string | null;
    superAdvisor: string | null;
  },
  idToken: string | undefined
) => {
  await fetchWithErrors(`${apiUrl}/corporate/withdraw`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const initiateCorporateFinancingScheduledWithdraw = async (
  payload: {
    targetSum: number;
    uid: string | null;
    superAdvisor: string | null;
  },
  idToken: string | undefined
) => {
  await fetchWithErrors(`${apiUrl}/corporate/scheduled-withdraw`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const getInvestorReports = async (
  idToken: string | undefined,
  uid: string | null
) => {
  const url = createURL(`${apiUrl}/report/corporate/investorReports`, {
    uid: uid,
  });
  return await fetchWithErrors(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  }).then((data) => data.json());
};

// TODO: does this even work with query parameters + being post?
export const setBenefitProgramConsent = async (
  payload: {
    hasConsent: boolean;
    uid: string | null;
  },
  idToken: string | undefined
) => {
  try {
    const res = await fetchWithErrors(
      createURL(`${apiUrl}/benefit-program/update-consent`, {
        uid: payload.uid,
      }),
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    );
    return { result: res, error: false };
  } catch (err) {
    return { error: err as Error };
  }
};

export const getExpenses = async (productId: number, amount: number | '') => {
  const idToken = await getIdToken();

  return await fetchWithErrors(
    `${apiUrl}/store/expences/${productId}/${amount}`,
    {
      method: 'GET',
      headers: {
        Authorization: idToken ? `Bearer ${idToken}` : '',
        'Content-Type': 'application/json',
      },
    }
  ).then((data) => data.json());
};

export const postLog = async (
  impersonatingUid: string | number | null
): Promise<Response> => {
  const idToken = await getIdToken();

  return await fetchWithErrors(`${apiUrl}/logging/log-impersonating-started`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid: impersonatingUid }),
  });
};

export const submitNewUser = async (payload: NewUserSubmitData) => {
  const idToken = await getIdToken();

  return await fetchWithErrors(`${apiUrl}/user/new-user`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const getUserBasicInfo = async (
  uid: string | null
): Promise<UserBasicInfo> => {
  const idToken = await getIdToken();

  const url = createURL(`${apiUrl}/user`, {
    uid,
  });

  return await fetchWithErrors(url, {
    method: 'GET',
    headers: {
      Authorization: idToken ? `Bearer ${idToken}` : '',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const checkUserHasAcceptedNewestTOS = async (
  uid: string | null
): Promise<HasAcceptedTOSResponse> => {
  const idToken = await getIdToken();

  const url = createURL(`${apiUrl}/user/tos-check`, {
    uid,
  });

  return await fetchWithErrors(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const updateUserInfo = async (payload: UserUpdateData) => {
  const idToken = await getIdToken();

  return await fetchWithErrors(`${apiUrl}/user/update-user`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const sendAbcLogAction = async (
  logInfo: LogInfo,
  idToken: string | undefined
) => {
  try {
    const res = await fetchWithErrors(`${apiUrl}/abc-logger`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logInfo),
    });
    return { result: res, error: false };
  } catch (err) {
    return { error: err as Error };
  }
};
