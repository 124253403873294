import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  'page-content': {},
  summary: {
    marginTop: '48px',
  },
  'title-content': {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  'title-components': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: '16px',
    },
    marginTop: '-16px',
    '& > *': {
      marginTop: '16px',
    },
  },
  'center-content': {},
  'loading-indicator': {
    justifyContent: 'center',
    display: 'flex',
  },
  'no-data': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '& h3': {
      marginBottom: '1em',
    },
  },
  'content-container': {
    marginTop: '48px',
  },
  alert: {
    padding: '1rem',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    fontSize: '1rem',
    fontWeight: 500,
    borderRadius: '1rem',
    marginBottom: '2rem',
    '& p': {
      marginTop: 0,
    },
    '& p:last-child': {
      margin: 0,
    },
  },
}));

export default useStyles;
