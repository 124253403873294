import React from 'react';
import { MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Select from '../../../../components/Select';

import { CalendarAction } from '../../../Calendar/Form/state';
import useStyles from './styles';
import { Office } from '../../../../types/abc';

export const getOfficeName = (o: Office, withAddress?: boolean) => {
  return withAddress
    ? `${o.ToimistoNimi}, ${o.Katuosoite1.split(',')[0]}`
    : o.ToimistoNimi;
};

const SelectOffice = (props: {
  value: number | undefined;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down(400));

  const { classes } = useStyles();
  const offices = useSelector((state: RootState) => state.content.offices);
  const { value, dispatch } = props;
  const user = useSelector((state: RootState) => state.user.fsUser);
  const usersOffice = user?.toimistoID
    ? offices.find((v) => v.ToimistoID === user.toimistoID)
    : undefined;
  return usersOffice ? (
    <div className={classes.ownOffice}>{`${usersOffice.ToimistoNimi}, ${
      usersOffice.Katuosoite1.split(',')[0]
    }`}</div>
  ) : (
    <Select
      className={classes.white}
      multiple={false}
      value={value?.toString() || ''}
      placeholder="Valitse toimisto"
      onUpdate={(values: string) => {
        dispatch({ type: 'set-office', payload: parseInt(values, 10) });
      }}
    >
      {offices.map((o) => (
        <MenuItem key={o.ToimistoID} value={o.ToimistoID.toString()}>
          {matchesMobile ? getOfficeName(o) : getOfficeName(o, true)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectOffice;
