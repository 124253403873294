import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store';
import { setNavigation } from '../../store/ui/actions';

import ActivityHeader from '../ActivityFeed/Header';
import Logo from './Logo';
import Nav from '../Nav';
import { HorizontalNav } from '../../routes/navItems';

import useStyles from './styles';
import { ReactComponent as Menu } from '../../images/svg/menu_light.svg';
import { ReactComponent as MenuClose } from '../../images/svg/menu_close.svg';
import UserSearch from './UserSearch';
import WebStoreButton from './WebStoreButton';
import { useIsRegistered } from '../../hooks/useIsRegistered';
import { useHasAllRequiredInfo } from '../../hooks/useHasAllRequiredInfo';

export const Header = (): React.ReactElement => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const isActivityFeedOpen = useSelector(
    (state: RootState) => state.ui.isActivityFeedOpen
  );
  const isNavigationOpen = useSelector(
    (state: RootState) => state.ui.isNavigationOpen
  );
  const isPageScolledDown = useSelector(
    (state: RootState) => state.ui.pageScrolledDown
  );
  const user = useSelector((state: RootState) => state.user.user);
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUser);
  const isRegistered = useIsRegistered();
  const hasAllRequiredInfo = useHasAllRequiredInfo();

  return (
    <>
      {user && isRegistered && hasAllRequiredInfo && (
        <div
          className={cx(classes['header-container'], {
            open: isNavigationOpen,
            'scrolled-down': isPageScolledDown,
          })}
        >
          <div
            className={cx(classes.header, {
              open: isNavigationOpen,
              'viewed-user': viewedUser !== null,
            })}
          >
            <div
              className={cx(classes['logo-container'], {
                open: isNavigationOpen,
              })}
            >
              <button
                className={cx(classes.burger, { open: isNavigationOpen })}
                onClick={() => dispatch(setNavigation(!isNavigationOpen))}
                aria-label="Päävalikko"
              >
                {isNavigationOpen ? <MenuClose /> : <Menu />}
              </button>

              <Logo />
            </div>

            <Nav navItems={HorizontalNav} type="horizontal" />
            <WebStoreButton />

            <UserSearch
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              className={cx(classes.search, { open: isNavigationOpen })}
            />

            <div
              className={cx(classes['activity-header'], {
                'feed-open': isActivityFeedOpen,
                'nav-open': isNavigationOpen,
              })}
            >
              <ActivityHeader
                className={cx({ 'nav-open': isNavigationOpen })}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
