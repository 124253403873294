import { makeStyles } from 'tss-react/mui';

import { SpinnerProps } from './index';
import { keyframes } from 'tss-react';

const frames = Array(160)
  .fill(null)
  .reduce((prev: unknown, _curr, i) => {
    const n = i + 1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    prev[`&:nth-of-type(${n})`] = {
      animationDelay: `${0 - 0.0025 * n}s`,
    };
    return prev;
  }, {});

const sizeUnit = 32;

const getWidth = (variant: SpinnerProps['variant']) => {
  if (variant === 'large') return '8px';
  else if (variant === 'medium') return '4px';
  else return '1px';
};

const getBg = (
  color: SpinnerProps['color'],
  variant: SpinnerProps['variant']
) => {
  if (color) return color;
  else if (variant === 'large') return '#ff6063';
  else return '#000450';
};

const useStyles = makeStyles<SpinnerProps>()((_t, { variant, color }) => ({
  container: {
    position: 'relative',
    '&.large': {
      width: sizeUnit * 4,
      height: sizeUnit * 4,
    },
    '&.medium': {
      width: sizeUnit * 2,
      height: sizeUnit * 2,
    },
    '&.small': {
      width: sizeUnit,
      height: sizeUnit,
    },
    '& .lds-bg-ring': {
      position: 'absolute',
      borderRadius: '50%',
      '&.large': {
        width: sizeUnit * 4,
        height: sizeUnit * 4,
        border: '8px solid #000450',
      },
      '&.medium': {
        width: sizeUnit * 2,
        height: sizeUnit * 2,
        border: '4px solid #cccccc',
      },
      '&.small': {
        display: 'none',
      },
    },

    '& .lds-roller': {
      position: 'absolute',
      '&.large': {
        width: sizeUnit * 4,
        height: sizeUnit * 4,
        bottom: -sizeUnit * 2,
      },
      '&.medium': {
        width: sizeUnit * 2,
        height: sizeUnit * 2,
        bottom: -sizeUnit,
      },
      '&.small': {
        width: sizeUnit,
        height: sizeUnit,
        bottom: -sizeUnit / 2,
      },
      '& div': {
        animation: `${keyframes`
          0% {
            transform: rotate(90deg);
          }
          100% {
            transform: rotate(810deg);
          }
        `}  2s cubic-bezier(0.66, 0, 0.33, 1) infinite`,
        transformOrigin: '50% 50%',
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: getWidth(variant),
          height: getWidth(variant),
          borderRadius: '50%',
          background: getBg(color, variant),
        },
        ...frames,
      },
    },
  },
}));

export default useStyles;
