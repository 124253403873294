import React from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { ReactComponent as WebStoreIcon } from '../../../images/svg/briefcase.svg';
import { Button } from '@mui/material';
import { AlexandriaButtonVariants } from '../../../theme';

type WebStoreButtonProps = {
  items?: number;
  action?: () => void;
};

const WebStoreButton: React.FC<WebStoreButtonProps> = ({
  items = 0,
  action,
}) => {
  const navigate = useNavigate();

  return (
    <Badge
      badgeContent={items}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Button
        variant={AlexandriaButtonVariants.Light}
        onClick={action || (() => navigate('/sijoita'))}
        startIcon={<WebStoreIcon />}
      >
        Sijoita
      </Button>
    </Badge>
  );
};

export default WebStoreButton;
