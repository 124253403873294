import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import MiddleLayout from '../layouts/Middle';
import PrivateRoute from './privateRoute';
import ErrorView from '../views/ErrorView';
import NotFound from '../views/NotFound';
import Loading from '../views/Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { isOnPage, isOnPublicRoute } from '../utils/routes';
import { useIsRegistered } from '../hooks/useIsRegistered';
import GeneralRoute from './general';
import { useHasAllRequiredInfo } from '../hooks/useHasAllRequiredInfo';
import { FSGroups } from '../types/firestore';

/**
 * Lazy load main routes (code splitting)
 */
const Main = React.lazy(() => import('../views/Main'));
const InvestmentsRoute = React.lazy(() => import('./investments'));
const MeetingsRoute = React.lazy(() => import('./meetings'));
const Reports = React.lazy(() => import('../views/Reports'));
const Admin = React.lazy(() => import('../views/Admin'));
const Calendar = React.lazy(() => import('../views/Calendar'));
const ContentRoute = React.lazy(() => import('./content'));
const ContactUs = React.lazy(() => import('../views/ContactUs'));
const BenefitProgram = React.lazy(() => import('../views/BenefitProgram'));
const WelcomePage = React.lazy(() => import('../views/Welcome'));
const WebStoreRoute = React.lazy(() => import('./webstore'));
const UserInfoRoute = React.lazy(() => import('./userInfo'));

const MainRoute = (): React.ReactElement => {
  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const isRegistered = useIsRegistered();
  const hasAllRequiredInfo = useHasAllRequiredInfo();

  const shouldGoToRegisterPage = fsUser && !isRegistered;
  const shouldGoToEditInfoPage = fsUser && isRegistered && !hasAllRequiredInfo;

  return (
    <MiddleLayout>
      <ErrorBoundary fallback={<ErrorView />}>
        <Suspense fallback={<Loading />}>
          <>
            {/* redirect user to registration page if not registered but logged in */}
            {!isOnPage('tervetuloa') && shouldGoToRegisterPage ? (
              <Navigate to={'/tervetuloa'} />
            ) : null}
            {/* redirect user to edit info page if missing required info */}
            {!isOnPage('paivita_tiedot') && shouldGoToEditInfoPage ? (
              <Navigate to={'/paivita_tiedot'} />
            ) : null}
            <InvestmentsRoute />
            <MeetingsRoute />
            <ContentRoute />
            {isRegistered && <WebStoreRoute />}
            <UserInfoRoute />
            <Routes>
              <Route path="/" element={<Main />}></Route>
              <Route path="/varaa_aika" element={<Calendar />}></Route>
              <Route path="/raportit" element={<Reports />}></Route>
              <Route path="/sisallot" element={<ContentRoute />}></Route>
              <Route path="/ota_yhteytta" element={<ContactUs />}></Route>
              <Route path="/etuohjelma" element={<BenefitProgram />}></Route>
              <Route
                path="/paivita_tiedot"
                element={
                  // edit info page can only be accessed if user does not have hasAllRequiredInfo
                  shouldGoToEditInfoPage ? (
                    <WelcomePage isRegistered />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              ></Route>
              <Route
                path="/tervetuloa"
                element={
                  // welcome page can only be accessed if user is not registered
                  shouldGoToRegisterPage ? <WelcomePage /> : <Navigate to="/" />
                }
              ></Route>
              <Route
                path="/hallinta"
                element={
                  <PrivateRoute roles={[FSGroups.admin]} component={Admin} />
                }
              />
              <Route
                path="*"
                element={isOnPublicRoute() ? null : <NotFound />}
              />
            </Routes>
            <GeneralRoute />
          </>
        </Suspense>
      </ErrorBoundary>
    </MiddleLayout>
  );
};
export default MainRoute;
