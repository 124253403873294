import { useEffect } from 'react';
import firebase from 'firebase/app';
import { DataSource, DataSourceUpdateType } from './dataSource';
import { DataSubscription } from './dataSubscription';
import { useLogger } from '../utils/logger';

type DataLoaderSubscriptionType = DataSourceUpdateType | 'all';

const logger = useLogger('DataLoader');

/**
 * A hook for using `DataLoader`
 * @param dataSource
 */
export const useDataLoader = (
  ...dataSources: DataLoaderSubscriptionType[]
): void => {
  useEffect(() => {
    try {
      const subscriptions = dataSources
        .map((dataSource) =>
          DataLoader.instance.subscribeToDataSource(dataSource)
        )
        .reduce((acc, curr) => acc.concat(curr), []);

      return () => {
        subscriptions.forEach((s) =>
          DataLoader.instance.unsubscribeFromDataSource(s)
        );
      };
    } catch (error) {
      console.error(error);
    }
  }, []);
};

/**
 * A singleton class for data loading
 */
export class DataLoader {
  private static _instance: DataLoader;
  private uid?: string;
  private ownUid?: string;
  private db?: firebase.firestore.Firestore;
  private initialized = false;

  private dataSources: DataSource[] = [];

  private subscribedDataSources: DataSubscription[] = [];

  private pendingSubscriptions: DataSourceUpdateType[] = [];

  private constructor() {
    // Create a getter for DataLoader when developing
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).$getDataLoader = () => DataLoader._instance;
    }
  }

  /**
   * Gets the DataLoader singleton
   */
  public static get instance(): DataLoader {
    if (!DataLoader._instance) {
      DataLoader._instance = new DataLoader();
    }
    return DataLoader._instance;
  }

  /**
   * Logger for DataLoader.
   */
  log(...args: unknown[]): void {
    logger.log(...args);
  }

  public addDataSources(dataSources: DataSource[]): void {
    this.dataSources = dataSources;
  }

  /**
   * Initialize the DataLoader with user's data and run all pending subscriptions
   * @param db Firebase db
   * @param dataSources The data sources to use
   * @param uid User's uid
   * @param viewedUid The viewed user's uid, if there is one
   */
  public initialize(
    db: firebase.firestore.Firestore,
    uid: string,
    viewedUid?: string
  ): void {
    if (this.initialized) {
      // If we are already initilized, we need to clear existing subscriptions
      this.logout();
    }

    this.ownUid = uid;

    // If the viewedUid is given, we can initialize dataloader with it directly
    if (viewedUid) {
      this.uid = viewedUid;
    }

    // If uid is already set, then we are viewing someone else's data
    if (!this.uid) {
      this.uid = uid;
    }
    this.db = db;

    this.initialized = true;

    this._initialize();
  }

  private _initialize() {
    if (this.uid !== this.ownUid) {
      this.log('Initializing with other user:', this.uid);
    }
    this.log('Initialize, pending actions:', this.pendingSubscriptions.length);
    for (
      let source = this.pendingSubscriptions.shift();
      source !== undefined;
      source = this.pendingSubscriptions.shift()
    ) {
      this.subscribeToDataSource(source);
    }
  }

  /**
   * Adds a pending subscription, which is added when `DataLoader` is initialized
   * @param subscription
   */
  private addPendingAction(
    subscription: DataLoaderSubscriptionType
  ): DataLoaderSubscriptionType {
    this.log('Added pending data source subscription:', subscription);
    this.pendingSubscriptions.push(subscription as DataSourceUpdateType);
    return subscription;
  }

  private subscribeForData(source: DataSource): DataSubscription {
    if (!this.initialized || !this.uid || !this.db) {
      throw new Error('DataLoader not initialized correctly');
    }

    this.log('Subscribing to data source:', source.name);
    const existingSubscription = this.subscribedDataSources.find(
      (s) => s.name === source.name
    );

    if (existingSubscription) {
      existingSubscription.addListener();
      return existingSubscription;
    }

    const subscription = source.subscribe(
      this.uid as string,
      this.ownUid as string,
      this.db as firebase.firestore.Firestore
    );
    this.subscribedDataSources.push(subscription);
    return subscription;
  }

  /**
   * Subscribes to a data source
   * @param source
   */
  public subscribeToDataSource(
    source: DataLoaderSubscriptionType
  ): DataLoaderSubscriptionType[] {
    if (!this.initialized) {
      return [this.addPendingAction(source)];
    }

    if (source === 'all') {
      return this.dataSources.map(
        (ds) => this.subscribeForData(ds)?.name || null
      );
    }
    const ds = this.dataSources.find((ds) => ds.name === source);

    if (!ds) {
      throw new Error(`DataLoader: Data source ${source} is not allowed`);
    }
    return [this.subscribeForData(ds).name || null];
  }

  /**
   * Unsubscribe from data source
   * @param source
   * @param force Forcefully unsibscribe even if there are still listeners
   */
  public unsubscribeFromDataSource(
    source: DataLoaderSubscriptionType,
    force = false
  ): void {
    // Unsubscribe from the source(s) and remove from subscribesDataSources
    if (source === 'all') {
      this.subscribedDataSources.forEach((s) => s.unsubscribe(force));
      this.subscribedDataSources = [];
    } else {
      const indexToRemove = this.subscribedDataSources.findIndex(
        (s) => s.name === source
      );
      if (indexToRemove >= 0) {
        const toRemove =
          this.subscribedDataSources[indexToRemove].unsubscribe(force);
        if (toRemove) {
          this.subscribedDataSources.splice(indexToRemove, 1);
        }
      }
    }
  }

  /**
   * Clear all subscriptions and options
   */
  public logout(): void {
    if (!this.initialized) return;
    this.log('Logout - Clearing subscriptions');
    this.initialized = false;
    this.db = undefined;
    this.uid = undefined;
    this.ownUid = undefined;
    this.pendingSubscriptions = [];
    this.unsubscribeFromDataSource('all', true);
  }

  public destroy(): void {
    this.initialized = false;
    this.dataSources = [];
    this.db = undefined;
    this.uid = undefined;
    this.ownUid = undefined;
    this.pendingSubscriptions = [];
    this.unsubscribeFromDataSource('all', true);
  }

  public clearViewedUser(): void {
    if (!this.initialized || this.uid === this.ownUid) return;
    this.log('Changing to view current user');
    this.uid = this.ownUid;
    this.refreshDataSources();
  }

  public changeViewToUser(uid: string): void {
    if (!this.initialized || this.uid === uid) return;
    this.log('Changing to view user:', uid);
    this.uid = uid;
    this.refreshDataSources();
  }

  private refreshDataSources() {
    this.log('Refreshing data sources for viewed user');
    this.pendingSubscriptions = this.subscribedDataSources.map((d) => d.name);
    this.unsubscribeFromDataSource('all', true);
    this._initialize();
  }
}
