import useSWR from 'swr';
import { get } from './http';
import { SWR_OPTIONS } from './common';
import type { Category } from '../components/WebStore/KnowledgeQuiz/QuizContext';
import { useKnowledgeLevel } from './useKnowledgeLevel';

export const useKnowledgeTests = () => {
  const swr = useSWR<{
    surveys: Category[];
  }>(`${process.env.REACT_APP_API_URL}/store/survey/status`, get, SWR_OPTIONS);

  const knowledgeLevel = useKnowledgeLevel();

  const isValid = (productCategoryId: number): boolean =>
    (swr.data?.surveys || []).some(
      (survey) =>
        survey.TuoteryhmaID === productCategoryId &&
        survey.Tietamysta &&
        survey.TietamysVoimassa &&
        new Date(survey.TietamysVoimassa) > new Date()
    );

  const getCategory = (categoryId: number): Category | undefined =>
    (swr.data?.surveys || []).find(
      (survey) => survey.TuoteryhmaID === categoryId
    );

  const refresh = () => {
    swr.mutate();
    knowledgeLevel.refresh();
  };

  return {
    swr,
    isValid,
    getCategory,
    refresh,
  };
};
