import React from 'react';
import { Button } from '@mui/material';
import { ArrowRight } from '@phosphor-icons/react';

const ArrowButton = (props: { text: string; onClick?: () => void }) => {
  const { text, onClick } = props;
  return (
    <Button
      variant="contained"
      aria-label={text}
      onClick={onClick}
      endIcon={<ArrowRight size={26} />}
      size="large"
      fullWidth
    >
      {text}
    </Button>
  );
};

export default ArrowButton;
