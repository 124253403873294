import { useSelector } from 'react-redux';
import firebase from 'firebase/app';
import {
  getViewedUser,
  getIdToken,
  checkIsAlexandriaEmployee,
} from './firebase';
import { RootState, store, logoutAction } from '../store';
import { FSAdvisor, FSUser } from '../types/firestore';
import { updateGroups } from './api';
import { setFSUser, setUser, setUsersAdvisor } from '../store/user/actions';
import { setLoading, setViewedUser } from '../store/ui/actions';
import { DataLoader } from '../data-loader';
import { useLogger } from './logger';
import { get } from '../hooks/http';

export const isUserInGroup = (user: FSUser, group: string): boolean => {
  return user.groups?.includes(group) || false;
};

const extractName = (email: string) =>
  email
    .replace(/@.*$/, '')
    .toLowerCase()
    .replace(/\./, ' ')
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));

export const useDisplayName = (): string | null | undefined => {
  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const authUser = useSelector((state: RootState) => state.user.user);

  if (fsUser?.kokonimi) return fsUser?.kokonimi;
  if (authUser?.email) return extractName(authUser?.email);
  return '';
};

export const useUserHasIncentiveClass = (): boolean => {
  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUser);
  const activeUser = viewedUser?.user ?? fsUser;
  return (activeUser?.kannustinluokka ?? 0) !== 0;
};

export const useViewedUserId = (): string | null => {
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUserUid);
  return viewedUser ? viewedUser : null;
};

export const useAdvisorView = (): boolean | undefined => {
  const fsUser = useSelector((state: RootState) => state.user.fsUser);
  const viewedUser = useSelector((state: RootState) => state.ui.viewedUserUid);

  return checkIsAlexandriaEmployee(fsUser) && !viewedUser;
};

/**
 * Does things that need to be done on login
 * @param firebaseUser The user gotten from login
 * @returns An unsubscribe function that unsubscribes from any subscriptions created for the user
 */
export const login = async (
  firebaseUser: firebase.User,
  accessToken: string
): Promise<() => void> => {
  const db = firebase.firestore();

  const token = await firebaseUser.getIdToken();
  const { uid, email } = firebaseUser;

  const loginLogger = useLogger('Auth');
  loginLogger.log('Logged in user', email);

  // Set update ABC timestamp to history - e.g. fore refetch of relevant info via backend middleware
  try {
    await get(`${process.env.REACT_APP_API_URL}/user/refresh`);
  } catch (e) {
    console.log('Force refresh failed, data might not be up to date', e);
  }

  /**
   * User might have arrived via redirect (Azure AD)
   */
  if (accessToken) {
    // TODO: this probably does not work?
    updateGroups(token, accessToken as string);
  }

  store.dispatch(setUser(firebaseUser));

  // If viewedUserUid is set, we need to set the viewedUser,
  const viewedUserUid = store.getState().ui.viewedUserUid;
  if (viewedUserUid) {
    const viewedUser = await getViewedUser(viewedUserUid);
    if (viewedUser) {
      store.dispatch(setViewedUser(viewedUser));
    }
  }
  DataLoader.instance.initialize(
    db,
    firebaseUser.uid,
    viewedUserUid || undefined
  );

  // Subscribe to "global" data sources here
  DataLoader.instance.subscribeToDataSource('user-access-to-users');
  DataLoader.instance.subscribeToDataSource('global-content');
  DataLoader.instance.subscribeToDataSource('frontpage-content');
  DataLoader.instance.subscribeToDataSource('custom-pages-content');
  DataLoader.instance.subscribeToDataSource('activities-content');
  DataLoader.instance.subscribeToDataSource('data-updates');

  const docs = db.collection('users').doc(uid);

  const unsubscribe = docs.onSnapshot(
    async (snapshot) => {
      console.log('Received user snapshot');
      const idToken = await getIdToken();
      if (!idToken) return;

      const fsUser = snapshot.data() as FSUser | undefined;

      if (fsUser) {
        store.dispatch(setFSUser(fsUser));

        if (fsUser.neuvonantajaID && !checkIsAlexandriaEmployee(fsUser)) {
          // get user's personal advisor
          const advisorDoc = await db
            .collection('advisors')
            .doc(fsUser.neuvonantajaID.toString())
            .get();
          store.dispatch(setUsersAdvisor(advisorDoc.data() as FSAdvisor));
        }

        store.dispatch(setLoading(false));
      }
    },
    (err) => {
      loginLogger.error(`Encountered error: ${err}`);
    }
  );

  return unsubscribe;
};

/**
 * Does things that need to be done on logout
 */
export const logout = (): void => {
  useLogger('Auth').log('User logged out');
  store.dispatch(logoutAction());
  store.dispatch(setLoading(false));
  DataLoader.instance.logout();
};
