export enum FeatureName {
  BenefitProgram = 'benefitProgram',
  ServiceLevels = 'serviceLevels',
  Sentry = 'SENTRY',
  CorporateFinancingChart = 'corporateFinancingChart',
}

export const featureIsEnabled = (feature: FeatureName): boolean => {
  return (process.env.FEATURE_FLAGS ?? '')
    .toUpperCase()
    .includes(feature.toUpperCase());
};
