import useSWR from 'swr';
import { get } from './http';
import { SWR_OPTIONS } from './common';
import type { ProductCategory } from './types';

// Encapsulate DB id:s to one place
const Categories: Record<'FUND' | 'STRUCTURED', number> = {
  FUND: 15,
  STRUCTURED: 21,
};

const flatten = (
  categories: Record<string, ProductCategory>
): ProductCategory[] =>
  Object.values(categories || {}).flatMap((category) => [
    category,
    ...flatten(category.childs),
  ]);

export const useProductData = () => {
  const { data, isLoading, error } = useSWR<Record<string, ProductCategory>>(
    `${process.env.REACT_APP_API_URL}/store/product-categories`,
    get,
    {
      ...SWR_OPTIONS,
      suspense: false,
    }
  );

  const getCategory = (
    id: number
  ): Record<string, ProductCategory> | undefined =>
    flatten(data || {})
      .filter((category) => category.id === id)
      .map((category) => ({ [category.id]: category }))
      .pop();

  const getSubCategories = (
    category: number
  ): Record<string, ProductCategory> | undefined => {
    return getCategory(category)?.[category]?.childs;
  };

  return {
    Categories,
    getCategory,
    getSubCategories,
    isLoading,
    error,
  };
};
