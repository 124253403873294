import { AuthUser } from '../../types/firestore';
import { Logout } from '../base';

export enum ActionTypes {
  SET_ADMIN_USERS = 'SET_ADMIN_USERS',
  ADD_ADMIN_USERS = 'ADD_ADMIN_USERS',
  UPDATE_ADMIN_USER = 'UPDATE_ADMIN_USER',
  SET_ADMIN_USERS_LOADING = 'SET_ADMIN_USERS_LOADING',
  SET_ADMIN_USER_GROUPS = 'SET_ADMIN_USER_GROUPS',
  REMOVE_ADMIN_USER = 'REMOVE_ADMIN_USER',
}

export interface State {
  users: AuthUser[];
  usersGroups: Record<string, string[] | undefined>;
  usersLoading: boolean;
}

interface SetUsers {
  type: typeof ActionTypes.SET_ADMIN_USERS;
  payload: State['users'];
}

interface AddUsers {
  type: typeof ActionTypes.ADD_ADMIN_USERS;
  payload: State['users'];
}

interface UpdateUser {
  type: typeof ActionTypes.UPDATE_ADMIN_USER;
  payload: State['users'][0];
}

interface SetUsersLoading {
  type: typeof ActionTypes.SET_ADMIN_USERS_LOADING;
  payload: State['usersLoading'];
}

interface SetUserGroups {
  type: typeof ActionTypes.SET_ADMIN_USER_GROUPS;
  payload: State['usersGroups'];
}

interface RemoveUser {
  type: typeof ActionTypes.REMOVE_ADMIN_USER;
  payload: State['users'][0];
}

export type Actions =
  | Logout
  | SetUsers
  | AddUsers
  | UpdateUser
  | SetUsersLoading
  | SetUserGroups
  | RemoveUser;
