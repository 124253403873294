import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  white: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiSelect-select': {
      background: theme.palette.white,
    },
  },
  ownOffice: {
    background: theme.palette.white,
    borderRadius: '23px 23px 23px 0px',
    padding: '16px',
  },
}));

export default useStyles;
