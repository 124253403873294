import { makeStyles } from 'tss-react/mui';
import Decoration from '../../../images/svg/bubble-decoration-up-gray.svg';
import { Theme } from '@mui/material/styles';
const useStyles = makeStyles()((theme: Theme) => ({
  item: {
    border: 'none',
    color: theme.palette.primary.main,
    fontSize: '16px',
    borderRadius: '16px',

    '&:hover, &[data-focus]': {
      backgroundColor: theme.palette.grey['200'],
      boxShadow:
        ' -3px -3px 4px rgba(255, 255, 255, 0.7), 1px 3px 6px rgba(0, 0, 0, 0.08), 0px 16px 10px -10px rgba(0, 0, 0, 0.1)',
    },
  },
  itemTextPrimary: {
    fontWeight: 500,
  },
  itemTextSecondary: {
    color: theme.palette.primary.main,
  },
  container: {
    minWidth: '4rem',
    width: '100%',
    '&.focused': {
      minWidth: '16rem',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '90px',
      position: 'absolute',
      '&.focused': {
        width: '60vw',
        marginLeft: '16px',
      },
      marginLeft: 0,
    },
    [theme.breakpoints.down(400)]: {
      width: '50px',
    },
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'list-container': {
    position: 'fixed',
    width: '260px',
    paddingLeft: 0,
    '&:before': {
      content: '""',
      display: 'block',
      backgroundImage: `url(${Decoration})`,
      width: '46px',
      height: '23px',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  list: {
    height: '200px !important',
    backgroundColor: theme.palette.grey['100'],
    borderRadius: '16px',
    boxShadow: '8px 16px 16px #00000015',
  },
  textField: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    '& input::placeholder': {
      opacity: 1,
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
