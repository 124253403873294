import React from 'react';

import useStyles from '../styles';

/**
 *  Response component to show on success or error
 */
const Response = (props: { success: boolean; children: React.ReactNode }) => {
  const { classes, cx } = useStyles();
  const { children, success } = props;
  return (
    <div className={cx(classes.response, success ? 'success' : 'error')}>
      {children}
    </div>
  );
};

export default Response;
