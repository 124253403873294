import React from 'react';

import useStyles from './styles';

import { ReactComponent as Up } from '../../../images/svg/up_small.svg';
import { ReactComponent as Down } from '../../../images/svg/down_small.svg';
import { ReactComponent as UpSelected } from '../../../images/svg/up_small.svg';
import { ReactComponent as DownSelected } from '../../../images/svg/down_small.svg';

const MenuIcon = (props: {
  open: boolean;
  selected: boolean;
}): React.ReactElement => {
  const { classes } = useStyles();
  const { open, selected } = props;
  if (selected) {
    return open ? (
      <button className={classes.button}>
        <UpSelected />
      </button>
    ) : (
      <button className={classes.button}>
        <DownSelected />
      </button>
    );
  }
  return open ? (
    <button className={classes.button}>
      <Up />
    </button>
  ) : (
    <button className={classes.button}>
      <Down />
    </button>
  );
};

export default MenuIcon;
