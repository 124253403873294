import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Page from '../Page';
import RichText from '../../components/RichText';
import { subscribeToContentIfAnonymous } from '../../utils/firebase';

const TermsOfUse = (): React.ReactElement => {
  const termsOfUse = useSelector(
    (state: RootState) => state.content.globalContent?.termsOfUse
  );

  useEffect(() => {
    subscribeToContentIfAnonymous();
  }, []);

  return (
    <Page title="Käyttöehdot">
      <RichText body={termsOfUse?.body.json} links={termsOfUse?.body.links} />
    </Page>
  );
};

export default TermsOfUse;
