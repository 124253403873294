import { useSelector } from 'react-redux';
import { RootState } from '../store';
import {
  checkIsAlexandriaEmployee,
  checkIsRegistered,
} from '../utils/firebase';

/**
 * Check if user is registered, always returns true if user is Alexandria employee
 * @returns boolean
 */
export const useIsRegistered = (): boolean => {
  const user = useSelector((state: RootState) => state.user.fsUser);
  return checkIsAlexandriaEmployee(user) || checkIsRegistered(user);
};
