import { ActionTypes, Actions, FundsState } from './types';

export function setFundsPosition(activity: FundsState['positions']): Actions {
  return {
    type: ActionTypes.SET_POSITION,
    payload: activity,
  };
}

export function setFundsTransaction(
  activity: FundsState['transactions']
): Actions {
  return {
    type: ActionTypes.SET_TRANSACTION,
    payload: activity,
  };
}

export function clearFunds(): Actions {
  return {
    type: ActionTypes.CLEAR_FUNDS,
  };
}

export function setSecurityDetails(
  details: FundsState['securityDetail']
): Actions {
  return {
    type: ActionTypes.SET_SECURITY_DETAILS,
    payload: details,
  };
}

export function addSecurityDetails(
  details: FundsState['securityDetail']
): Actions {
  return {
    type: ActionTypes.ADD_SECURITY_DETAILS,
    payload: details,
  };
}

export function setFundsSecurityInfo(
  info: FundsState['securityInfo']
): Actions {
  return {
    type: ActionTypes.SET_SECURITY_INFO,
    payload: info,
  };
}

export function addFundsSecurityInfo(
  info: FundsState['securityInfo']
): Actions {
  return {
    type: ActionTypes.ADD_SECURITY_INFO,
    payload: info,
  };
}
