import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Typography, Button } from '@mui/material';

import Page from '../../Page';
import { FormatDate } from '../../../utils/date';

import { RootState } from '../../../store';
import NotFound from '../../NotFound';
import Markdown from '../../../components/Markdown';

import useStyles from './styles';
import { Page as PageType } from '../../../types/contentful';
import { getPage } from '../../../utils/contentful';
import Loading from '../../Loading';
import useStylesButton from '../../../components/Button/styles';

import { ReactComponent as Backward } from '../../../images/svg/backward_blue.svg';

export interface ArticleProps {
  slug?: string;
  page?: PageType;
}

const Article = (props: ArticleProps): React.ReactElement => {
  const article = useSelector((state: RootState) =>
    state.content.content.find((a) => a.slug === props.slug)
  );
  const [page, setPage] = useState<PageType | undefined>(undefined);
  const [body, setBody] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const { classes } = useStyles();
  const { classes: buttonClasses } = useStylesButton('');

  useEffect(() => {
    if (article) {
      setBody(article.body || '');
      setLoading(false);
    } else if (props.page) {
      setPage(props.page);
      setBody(props.page.body);
      setLoading(false);
    } else if (props.slug) {
      getPage(props.slug).then((p) => {
        setPage(p);
        setBody(p.body);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [article]);

  if (loading) {
    return <Loading />;
  }

  return article ? (
    <Page title={article.title}>
      <div className={classes.text}>
        <picture>
          <source
            srcSet={`${article.image?.url}?w=1000&fm=webp`}
            type="image/webp"
          />
          <source
            srcSet={`${article.image?.url}?w=1000&fm=jpg`}
            type="image/jpeg"
          />
          <img
            src={`${article.image?.url}?w=1000&fm=jpg`}
            alt={article.title}
            style={{
              width: '100%',
              height: '400px',
              objectFit: 'cover',
              borderRadius: '32px',
            }}
            loading="lazy"
          />
        </picture>

        <Typography variant="body2">
          {article.publicationDate && FormatDate(article.publicationDate)}
        </Typography>
        <Typography variant="h3">{article.previewBody}</Typography>
        {body && <Markdown markdown={body} />}
        <Button
          component={Link}
          to="/sisallot"
          key={article.sys.id}
          className={classes.button}
          classes={{ root: buttonClasses.root }}
        >
          <Backward className={'button-icon'} />
          <span className={'button-label'}>Takaisin sisältöihin</span>
        </Button>
      </div>
    </Page>
  ) : page ? (
    <Page title={page.title}>
      <picture>
        <source
          srcSet={`${page.image?.url}?w=1000&fm=webp`}
          type="image/webp"
        />
        <source srcSet={`${page.image?.url}?w=1000&fm=jpg`} type="image/jpeg" />
        <img
          src={`${page.image?.url}?w=1000&fm=jpg`}
          alt={page.title}
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
            borderRadius: '32px',
          }}
          loading="lazy"
        />
      </picture>
      <div className={classes.text}>
        <Typography variant="h3">{page.leadParagraph}</Typography>
        {body && <Markdown markdown={body} />}
      </div>
    </Page>
  ) : (
    <NotFound />
  );
};

export default Article;
