import { makeStyles } from 'tss-react/mui';
import Decoration from '../../images/svg/bubble-decoration-up-lightred.svg';
import { Theme } from '@mui/material';

const menuPadding = 32;
const useStyles = makeStyles()((theme: Theme) => ({
  ul: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '& li': {
      listStyleType: 'none',
      textDecoration: 'none',
      '& span': {
        display: 'flex',
        flex: 1,
      },
    },
  },
  drawer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  'selected-path': {
    background: theme.palette.secondary.light,
    borderRadius: '0 0 36px 0',
    paddingRight: '32px',
    [theme.breakpoints.down(400)]: {
      paddingRight: '20px',
    },
  },
  'drawer-ul': {
    '& li': {
      listStyleType: 'none',
      textDecoration: 'none',
      '& span': {
        paddingLeft: menuPadding + 'px',
      },
    },
  },
  'child-menu': {
    '&.drawer': {
      '& li': {
        textTransform: 'none',
        '&.selected-path': {
          '&:before': {
            content: '""',
            borderBottom: '1px solid #01094E',
            bottom: '50%',
            position: 'absolute',
            width: menuPadding + 'px',
          },
        },
        '&.selected': {
          '&:before': {
            content: '""',
            borderBottom: '1px solid #01094E',
            bottom: '50%',
            position: 'absolute',
            width: menuPadding + 'px',
          },
        },
        '& span': {
          paddingLeft: menuPadding * 2 + 'px',
        },
      },
      '& ul > li': {
        '&.selected-path': {
          '&:before': {
            content: '""',
            borderBottom: '1px solid #01094E',
            bottom: '50%',
            position: 'absolute',
            width: menuPadding * 2 + 'px',
          },
        },
        '&.selected': {
          '&:before': {
            content: '""',
            borderBottom: '1px solid #01094E',
            bottom: '50%',
            position: 'absolute',
            width: menuPadding * 2 + 'px',
          },
        },
        '& span': {
          paddingLeft: menuPadding * 3 + 'px',
          '& ul > li': {
            '&.selected-path': {
              '&:before': {
                content: '""',
                borderBottom: '1px solid #01094E',
                bottom: '50%',
                position: 'absolute',
                width: menuPadding * 3 + 'px',
              },
            },
            '&.selected': {
              '&:before': {
                content: '""',
                borderBottom: '1px solid #01094E',
                bottom: '50%',
                position: 'absolute',
                width: menuPadding * 3 + 'px',
              },
            },
            '& span': {
              paddingLeft: menuPadding * 4 + 'px',
            },
          },
        },
      },
    },
    '&.horizontal': {
      '& li': {
        textTransform: 'none',
      },
    },
  },
  popper: {
    '&:before': {
      content: '""',
      display: 'block',
      backgroundImage: `url(${Decoration})`,
      width: '46px',
      height: '23px',
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    '& .popper-paper': {
      borderRadius: '36px',
      background: theme.palette.secondary.light,
      width: '204px',
      boxShadow: '8px 16px 16px #00000015',
      '& ul > li': {
        textAlign: 'center',
        '& a': {
          borderRadius: '23px',
          height: '46px',
          lineHeight: '46px',
        },
        '&.selected-path': {
          background: theme.palette.secondary.light,
          borderRadius: '36px',
          fontWeight: 500,
          '& a': {
            boxShadow:
              ' -3px -3px 4px rgba(255, 255, 255, 0.7), 1px 3px 6px rgba(0, 0, 0, 0.08), 0px 16px 10px -10px rgba(0, 0, 0, 0.1)',
          },
        },
        '&.selected a': {
          boxShadow:
            ' -3px -3px 4px rgba(255, 255, 255, 0.7), 1px 3px 6px rgba(0, 0, 0, 0.08), 0px 16px 10px -10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
}));

export default useStyles;
