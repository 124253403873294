import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as Star48 } from '../../../images/svg/star_48.svg';
import { ReactComponent as Star24 } from '../../../images/svg/star.svg';
import { ReactComponent as Star12 } from '../../../images/svg/star_small.svg';
import { ReactComponent as Star16 } from '../../../images/svg/star_16.svg';
import { ReactComponent as Star32 } from '../../../images/svg/star_32.svg';

import useStyles from './styles';

const Welcome = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesWIDE = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.wide)
  );
  const matchesXS = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );

  const getStar = () => {
    if (matchesXS) {
      return <Star12 />;
    } else if (matchesWIDE) {
      return <Star16 />;
    }
    return <Star24 />;
  };

  const getLargeStar = () => {
    if (matchesXS) {
      return <Star24 />;
    } else if (matchesWIDE) {
      return <Star32 />;
    }
    return <Star48 />;
  };

  return (
    <div className={classes.container}>
      <div className={classes.decorations}>
        <div className="star">{getStar()}</div>
        <div className="star-large">{getLargeStar()}</div>
      </div>
      <div className={classes.title}>
        <Typography variant="h1" color={theme.palette.text.secondary}>
          Tervetuloa
        </Typography>
      </div>
    </div>
  );
};

export default Welcome;
