import { ActionTypes, Actions, State } from './types';

export function setAbcReports(abcReports: State['abcReports']): Actions {
  return {
    type: ActionTypes.SET_ABC_REPORTS,
    payload: abcReports,
  };
}

export function setUtmostReports(
  utmostReports: State['utmostReports']
): Actions {
  return {
    type: ActionTypes.SET_UTMOST_REPORTS,
    payload: utmostReports,
  };
}
