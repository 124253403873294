import React from 'react';

import List from '@mui/material/MenuList';
import MuiPopper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';

import useStyles from '../styles';

interface IPopper {
  anchorEl: HTMLElement | null;
  onClickAway: (event?: MouseEvent | TouchEvent) => void;
  children: React.ReactElement[] | null;
  id: string;
}

const Popper = ({
  anchorEl,
  onClickAway,
  children,
  id,
}: IPopper): React.ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <MuiPopper
      id={id}
      style={{ zIndex: 99999 }}
      popperOptions={{
        strategy: 'fixed',
      }}
      open={anchorEl !== null}
      anchorEl={anchorEl}
      disablePortal
      className={classes.popper}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Paper className="popper-paper">
          <List
            autoFocusItem={anchorEl !== null}
            className={cx(classes['child-menu'], 'horizontal')}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Escape') {
                onClickAway();
              }
            }}
          >
            {children}
          </List>
        </Paper>
      </ClickAwayListener>
    </MuiPopper>
  );
};

export default Popper;
