import React, { useContext, useEffect, useRef, useState } from 'react';

import MuiStepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import MuiStepLabel from '@mui/material/StepLabel';

import Button from '../../../../components/Button';
import { CalendarContext, Step } from '../.';

import { ReactComponent as Forward } from '../../../../images/svg/arrow_right.svg';
import { ReactComponent as Backward } from '../../../../images/svg/arrow_left.svg';

import useStyles from './styles';

const Stepper = (props: {
  steps: Step[];
  content: React.ReactElement[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: () => void;
  handleReset: () => void;
}): React.ReactElement => {
  const { classes, cx } = useStyles();
  const { steps, activeStep, setActiveStep, handleSubmit, handleReset } = props;
  const { anonymous } = useContext(CalendarContext);

  /** Scrolls to the top of the form */
  const scrollToTop = () => {
    let scrollElement;
    if (anonymous) {
      scrollElement = document.getElementById('stepper-content');
    } else {
      scrollElement = document.getElementById('scroll-root');
    }

    if (!scrollElement) return;

    const elementPosition = stepperRef.current?.offsetTop;
    if (!elementPosition) return;

    const offsetPosition = elementPosition;
    scrollElement.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    scrollToTop();
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };
  const reset = () => {
    setActiveStep(0);
    handleReset();
  };
  const stepperRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const [buttonsLoose, setButtonsLoose] = useState(false);

  /** Check if buttons are loose from their position (because of position: sticky) */
  const checkButtons = () => {
    const rect = buttonsRef.current?.getBoundingClientRect();
    const docHeight = document.documentElement.offsetHeight;
    if (rect) {
      const difference = docHeight - rect.bottom;
      if (difference <= 17) {
        setButtonsLoose(true);
      } else if (buttonsLoose) {
        setButtonsLoose(false);
      }
    }
  };

  useEffect(() => {
    checkButtons();
  }, []);

  return (
    <>
      <div
        id="stepper-content"
        className={classes.content}
        onWheel={checkButtons}
      >
        {props.content[activeStep]}
      </div>
      <div className={cx(classes.buttons, { loose: false })} ref={buttonsRef}>
        {activeStep > 0 && activeStep !== 3 ? (
          <Button className={classes.button} onClick={handleBack}>
            <Backward className={cx('button-icon', 'back')} />
            <span className="button-label">Edellinen</span>
          </Button>
        ) : activeStep === 3 ? (
          <Button className={classes.button} onClick={reset}>
            <Backward className={cx('button-icon', 'back')} />
            <span className="button-label">Palaa alkuun</span>
          </Button>
        ) : (
          <div />
        )}
        <MuiStepper
          className={classes.stepper}
          activeStep={activeStep}
          ref={stepperRef}
        >
          {steps.map((step, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <MuiStep
                className={cx(
                  classes.step,
                  { active: activeStep === index },
                  { completed: stepProps.completed }
                )}
                key={index}
                {...stepProps}
              >
                <MuiStepLabel {...labelProps}>{step.label}</MuiStepLabel>
              </MuiStep>
            );
          })}
        </MuiStepper>
        {activeStep < steps.length - 1 && (
          <Button
            className={classes.button}
            disabled={!steps[activeStep].isValidStep}
            onClick={handleNext}
          >
            <span className="button-label">Seuraava</span>
            <Forward className="button-icon" />
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button
            className={classes.button}
            disabled={!steps[2].isValidStep}
            onClick={() => {
              handleSubmit();
              scrollToTop();
            }}
          >
            <span className="button-label">Tee varaus</span>
          </Button>
        )}
      </div>
    </>
  );
};

export default Stepper;
