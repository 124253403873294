import type { BookingChannel, Timestamp } from '../types/local';

export enum Tuotetyyppi {
  Muut = 0,
  Strukturoidut = 6,
  Rahastot = 8,
  /** not in use? */
  Vakuutus = 7,
  'Käteinen vakuutuksessa' = 9,
  Yrityslaina = 10,
  Indeksi = 11,
}

/** Omasalkku_Vakuutukset */
export interface InsuranceOverview {
  HetuTaiYTunnus: string;
  Vakuutustyyppi: number;
  Vakuutusnumero: string;
  VakuutuksenNimi: string;
  Voimaantulopvm: Timestamp;
  Maksuera: number;
  MaksueriaVuodessa: number;
  SijoitettuPaaoma: number;
  Arvo: number;
  Takaisinostoarvo: number;
  Valuutta: string;
  Tila: string;
}

/** Omasalkku_Vakuutusomistukset */
export interface InsurancePosition {
  Vakuutustyyppi: number;
  Vakuutusnumero: string;
  ISIN: string;
  Tuote: string;
  Kpl: number;
  Arvo: number;
  Valuutta: string;
  Arvostuspvm: Timestamp;
  URL: string;
  Tuotetyyppi: Tuotetyyppi;
}

export enum Tuoteryhma {
  Funds = 1,
  Structures = 2,
  CorporateFinancing = 6,
}

/** Omasalkku_AsiakkaanRaportit */
export interface Report {
  HetuTaiYTunnus: string;
  Tuoteryhma: Tuoteryhma;
  TuoteryhmaSelite: string;
  Vuosi: number;
  Vuosiraportti: boolean;
  Vuosiraportti_Puuttuu_Selite: string;
  KulutJaVeloitukset: boolean;
  KulutJaVeloitukset_Puuttuu_Selite: string;
  RahamyllyKulutJaVeloituksetUrl?: string;
}

export interface Office {
  Katuosoite1: string;
  Katuosoite2: string;
  Postinumero: string;
  Postitoimipaikka: string;
  Puhelinnumero: string;
  ToimistoID: number;
  ToimistoNimi: string;
}
enum GdprDataType {
  CSV = 1,
  PDF = 3,
}

export interface Slot {
  Aika: string;
  NeuvonantajaID: number;
  NeuvonantajaNimi: string;
}

export interface GDPRDataRequest {
  email: string;
  phone: string;
  dataType: GdprDataType;
}

export interface CalendarDatesByOfficePayload {
  office?: number;
  advisor?: number;
  channel?: BookingChannel;
}

export interface CalendarSlotsByOfficeDatePayload {
  office?: number;
  date?: string | null;
  advisor?: number;
  channel?: BookingChannel;
}

export interface AddBookingRequest {
  surname: string;
  firstnames: string;
  phone: string;
  email: string;
  advisorId: number;
  officeId: number;
  meetingTime: string;
  meetingDuration: string;
  messageForAdvisor: string;
  channel: BookingChannel;
  hash?: string;
}

interface IObjectKeys {
  [key: string]: string | number | undefined;
}

export interface ExpensesCumulative extends IObjectKeys {
  Jarjestys: number;
  TuottoOlettamaSarakeOtsikko: string;
  TuottoOlettamaRiviOtsikko: string;
  Vuosituotto1SarakeOtsikko: string;
  Vuosituotto1Summa1?: number;
  Vuosituotto1Summa2?: number;
  Vuosituotto2SarakeOtsikko?: string;
  Vuosituotto2Summa1?: number;
  Vuosituotto2Summa2?: number;
  Vuosituotto3SarakeOtsikko?: string;
  Vuosituotto3Summa1?: number;
  Vuosituotto3Summa2?: number;
}

export interface ExpensesDetailed extends IObjectKeys {
  LuokkaID: number;
  LuokkaSarakeOtsikko: string;
  LuokkaRiviOtsikko: string;
  Tyyppi: string;
  Esimerkki: string;
  EnsimmainenVuosiSarakeOtsikko: string;
  EnsimmainenVuosiProsentti: number;
  EnsimmainenVuosiSumma: number;
  SeuraavatVuodetSarakeOtsikko: string;
  SeuraavatVuodetProsentti: number;
  SeuraavatVuodetSumma: number;
  ViimeinenVuosiSarakeOtsikko: string;
  ViimeinenVuosiProsentti: number;
  ViimeinenVuosiSumma: number;
  Jarjestys: number;
}

export interface ExpensesSummary extends IObjectKeys {
  LuokkaID: number;
  LuokkaSarakeOtsikko: string;
  LuokkaRiviOtsikko: string;
  EnsimmainenVuosiSarakeOtsikko: string;
  EnsimmainenVuosiProsentti: number;
  EnsimmainenVuosiSumma: number;
  SeuraavatVuodetSarakeOtsikko: string;
  SeuraavatVuodetProsentti: number;
  SeuraavatVuodetSumma: number;
  ViimeinenVuosiSarakeOtsikko: string;
  ViimeinenVuosiProsentti: number;
  ViimeinenVuosiSumma: number;
  Jarjestys: number;
}

export interface ExpensesText extends IObjectKeys {
  Konteksti: string;
  Otsikko: number;
  Jarjestys: number;
  Teksti: string;
}

export type Expenses = {
  cumulative: ExpensesCumulative[];
  detailed: ExpensesDetailed[];
  summary: ExpensesSummary[];
  text: ExpensesText[];
};

export type Purchase = {
  ToimeksiantoID: number;
  Asiakasnumero: number;
  TuoteID: number;
  TuoteNimi: string;
  ISIN: string;
  MaksutiliIBAN: string;
  MaksutiliBIC: string;
  Viitenumero: number;
  MaksuSaaja: string;
  Kokonaissumma: number;
  Merkintapalkkiosumma: number;
  MerkintaSumma: number;
  MerkintaKpl: number;
  Peruttu: boolean;
  AsiakasHaluaaPerua: boolean;
  AsiakasHaluaaPeruaAikaleima: string;
  AsiakasHaluaaPeruaPerustelu: string;
  Maksettu: boolean;
  Peruttavissa: boolean;
  Valmis: boolean;
  Loppuunkasitelty: boolean;
  Luotu: string;
  VaatiiTilinavausValtakirjan: boolean;
  Sailytystilinumero: string;
  TilaTeksti: string;
  TilaOhje: string;
  virtualBarcode: string;
  ToimeksiantoPvm: string;
  Merkintapaikka: string;
};
