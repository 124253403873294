import { useLogger } from '../utils/logger';
import { DataSourceUpdateType } from './dataSource';

export class DataSubscription {
  public name: DataSourceUpdateType;
  private listenerCount = 1;
  private _unsubscribe: () => void;

  constructor(name: DataSourceUpdateType, unsubscribe: () => void) {
    this.name = name;
    this._unsubscribe = unsubscribe;
  }

  /**
   * Unsubscribe from this subscription
   * @param force Forcefully unsibscribe even if there are still listeners
   * @returns `boolean` indicating if there are no listeners left
   */
  public unsubscribe(force: boolean): boolean {
    const logger = useLogger('DataLoader');

    if (force) {
      logger.log('Forcefully removed all subscriptions from:', this.name);
      this.listenerCount = 0;
      this._unsubscribe();
      return true;
    }
    if (this.listenerCount > 1) {
      logger.log('Removed 1 subscription from:', this.name);

      this.listenerCount--;
      return false;
    }
    logger.log('Removed last subscription from:', this.name);
    this._unsubscribe();
    return true;
  }

  public addListener(): void {
    this.listenerCount++;
  }
}
