import { useKnowledgeTests } from './useKnowledgeTests';
import { useKnowledgeLevel } from './useKnowledgeLevel';
import { useIsImpersonating } from './useIsImpersonating';
import { useFirestoreUser } from './useFirestoreUser';
import type { ProductDetails } from '../utils/productData';
import { checkIsAlexandriaEmployee } from '../utils/firebase';

export const useCanInvest = (
  instrument: ProductDetails,
  amount: number | ''
): {
  ok: boolean;
  missingKnowledge: boolean;
  minInvestAmount: number;
  investment: number;
  fee: number;
  total: number;
  tooltip: string;
} => {
  const isFund = instrument.__typename === 'FundProduct';
  const minInvestAmount = isFund
    ? instrument.minimumInvestmentAmount.valueNumber
    : instrument.minimumSubscriptionUnits;

  const isAboveMinimumInvestment = !!amount && amount >= minInvestAmount;

  const isKnowledgeTestDone = useKnowledgeTests().isValid(
    instrument.knowledgeProductGroupId
  );

  const hasKnowledgeLevel =
    useKnowledgeLevel().level >= instrument.requiredKnowledge;

  const isImpersonating = useIsImpersonating();

  const investAmount = Number(amount) || 0;

  const fee =
    investAmount *
    (isFund
      ? instrument.entryFee.valueNumber / 100
      : instrument.subscriptionFee);

  const investment = isFund
    ? investAmount - fee
    : investAmount * instrument.subscriptionPrice;

  const total = isFund ? Number(amount) : investment + fee;

  const fsUser = useFirestoreUser();

  const { katuosoite, maakoodi } = fsUser!;
  const addressData = { katuosoite, maakoodi };
  const missingAddressData = Object.values(addressData).some(
    (element) => !element
  );
  const isFinnishAddress = addressData.maakoodi === 'FI';

  const isNotAlexandriaEmployee = !checkIsAlexandriaEmployee(fsUser);

  const tooltip = String(
    [
      [
        isNotAlexandriaEmployee,
        'Ostaminen ei ole mahdollista AD-kirjautuneelle käyttäjälle',
      ],
      [
        isAboveMinimumInvestment,
        'Antamasi merkintäsumma on alle minimimerkintäsumman',
      ],
      [isKnowledgeTestDone, 'Ei riittävää tietämystä tuotteen ostamiseen'],
      [
        hasKnowledgeLevel,
        'Arviomme, että sinulla ei ole riittävää yleistä  sijoitustietämystä ja -kokemusta, jolloin tämä tuote ei ole sinulle asianmukainen. Mikäli sinulla on kuitenkin sijoitustietämystä ja/tai -kokemusta jostain muista sijoitusluokista , esim. pörssiosakkeista, joukkorahastoista tai vakuutuksista, käy suorittamassa kokemus- ja tietämystestit ko. sijoitusluokista. Huomioimme asianmukaisuusarvioinnissa sijoituskokemuksesi ja -tietämyksesi kaikista eri sijoitusluokista.',
      ],
      [!missingAddressData, 'Osoitetietoja puuttuu'],
      [isFinnishAddress, 'Osoite ei ole Suomessa'],
      [!isImpersonating, 'Ostaminen ei ole mahdollista toisen puolesta'],
      [false, ''],
    ].find(([condition]) => !condition)?.[1]
  );

  return {
    ok:
      isNotAlexandriaEmployee &&
      isKnowledgeTestDone &&
      hasKnowledgeLevel &&
      isAboveMinimumInvestment &&
      !missingAddressData &&
      isFinnishAddress &&
      !isImpersonating,
    missingKnowledge:
      !isKnowledgeTestDone && isAboveMinimumInvestment && !isImpersonating,
    minInvestAmount,
    investment,
    fee,
    total,
    tooltip,
  };
};
