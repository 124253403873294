import React, { useState } from 'react';

import { Button } from '@mui/material';

import { CalendarAction } from '../../../Calendar/Form/state';
import { BookingChannel } from '../../../../types/local';

import useStyles from './styles';

export const ChannelToLabel = (channel: BookingChannel): string => {
  switch (channel) {
    case BookingChannel.Video:
      return 'Verkkotapaaminen';
    case BookingChannel.Office:
      return 'Toimistolla';
    case BookingChannel.Phone:
      return 'Puhelimitse';
    default:
      return '';
  }
};

const SelectChannel = (props: {
  value: BookingChannel;
  dispatch: React.Dispatch<CalendarAction>;
}): React.ReactElement => {
  const { value, dispatch } = props;
  const { classes, cx } = useStyles();
  const [selectedChannel, setSelectedChannel] = useState<BookingChannel>(value);

  const handleButtonChange = (channel: BookingChannel) => {
    setSelectedChannel(channel);
    dispatch({
      type: 'set-channel',
      payload: parseInt(channel.toString()),
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'set-channel',
      payload: parseInt(event.target.value),
    });
  };

  return (
    <>
      <div
        aria-label="tapaamisen-kanava"
        onChange={handleChange}
        className={classes.buttons}
      >
        <Button
          className={cx(classes.button, {
            active: selectedChannel === BookingChannel.Video,
          })}
          onClick={() => handleButtonChange(BookingChannel.Video)}
        >
          {ChannelToLabel(BookingChannel.Video)}
        </Button>
        <Button
          className={cx(classes.button, {
            active: selectedChannel === BookingChannel.Office,
          })}
          onClick={() => handleButtonChange(BookingChannel.Office)}
        >
          {ChannelToLabel(BookingChannel.Office)}
        </Button>
        <Button
          className={cx(classes.button, {
            active: selectedChannel === BookingChannel.Phone,
          })}
          onClick={() => handleButtonChange(BookingChannel.Phone)}
        >
          {ChannelToLabel(BookingChannel.Phone)}
        </Button>
      </div>

      {/*
      <RadioGroup
        aria-label="tapaamisen-kanava"
        name="meeting-channel"
        value={value}
        onChange={handleChange}
        style={{ paddingLeft: '8px' }}
      >
        {availableChannels?.Kanava_Video === true ?
        <FormControlLabel
          value={BookingChannel.Video}
          control={<Radio />}
          label={ChannelToLabel(BookingChannel.Video)}
        /> : advisorId === undefined ? (
        <FormControlLabel
          value={BookingChannel.Video}
          control={<Radio />}
          label={ChannelToLabel(BookingChannel.Video)}
        />
          ) : null}
        {availableChannels?.Kanava_Toimisto === true ?
        <FormControlLabel
          value={BookingChannel.Office}
          control={<Radio />}
          label={ChannelToLabel(BookingChannel.Office)}
        /> : advisorId === undefined ? (
        <FormControlLabel
          value={BookingChannel.Office}
          control={<Radio />}
          label={ChannelToLabel(BookingChannel.Office)}
        />
        ) : null}
        {availableChannels?.Kanava_Puhelin === true ?
        <FormControlLabel
          value={BookingChannel.Phone}
          control={<Radio />}
          label={ChannelToLabel(BookingChannel.Phone)}
        /> : advisorId === undefined ? (
        <FormControlLabel
          value={BookingChannel.Phone}
          control={<Radio />}
          label={ChannelToLabel(BookingChannel.Phone)}
        />
          ) : null}
      </RadioGroup>
      */}
    </>
  );
};

export default SelectChannel;
