import { getIdToken } from '../utils/firebase';

export const getCommonHeaders = async () => ({
  headers: {
    Authorization: `Bearer ${await getIdToken()}`,
    'Content-Type': 'application/json',
  },
});

const handler = async (request: Promise<Response>) => {
  const response = await request;
  if (!response.ok) {
    throw new Error(`Request failed with HTTP-${response.status}`);
  }
  return response.json();
};

export const get = async <T>(url: string): Promise<T> =>
  handler(fetch(url, { ...(await getCommonHeaders()) }));

export const post = async <T, R>(
  url: string,
  { arg: data }: { arg: T }
): Promise<R> =>
  handler(
    fetch(url, {
      ...(await getCommonHeaders()),
      method: 'POST',
      body: JSON.stringify(data),
    })
  );
