import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  'header-container': {
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'Scto Grotesk A, Medium',
    fontSize: '16px',
    borderRadius: '0px 0px 0px 46px',
    transition: theme.transitions.create(
      ['width', 'border-radius', 'margin-top', 'height'],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }
    ),
    '&.open': {
      [theme.breakpoints.up('xs')]: {
        width: 'calc(100vw - 60px)',
      },
      [theme.breakpoints.up(400)]: {
        width: '350px',
      },
      [theme.breakpoints.up('md')]: {
        width: '472px',
      },
    },
    '&.closed': {
      [theme.breakpoints.up('xs')]: {
        borderRadius: '12px 0px 0px 12px',
        width: '51px',
        marginTop: '16px',
        height: '52px',
      },
      [theme.breakpoints.up('md')]: {
        width: '254px',
        marginTop: '0px',
        height: '85px',
        borderRadius: '0px 0px 0px 46px',
      },
      justifyContent: 'center',
      marginLeft: '12px',
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: '0px',
      borderRadius: '0px 0px 0px 46px',
      height: '68px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
      height: '85px',
      borderRadius: '0px 0px 0px 46px',
    },
  },
  number: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    position: 'absolute',
    left: '0',
    top: '30px',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    [theme.breakpoints.up('xs')]: {
      top: '-8px',
      left: '8px',
    },
    [theme.breakpoints.up('md')]: {
      top: '30px',
      left: '0px',
    },
  },
  button: {
    fontSize: '16px',
    paddingRight: '16px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  'closed-button': {
    display: 'flex',
  },
  'small-star': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  'header-title': {
    color: theme.palette.info.main,
    paddingLeft: '30px',
  },
  'header-settings-container': {
    display: 'flex',
    alignItems: 'center',
  },
  'header-settings': {
    display: 'flex',
    margin: '0px 15px 0px 0px',
  },
  'feed-open': {
    color: theme.palette.secondary.main,

    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    [theme.breakpoints.up('xs')]: {
      marginTop: '1px',
      marginLeft: '1px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '17px',
      marginLeft: '42px',
    },
  },
  'feed-closed': {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('xs')]: {
      marginTop: '1px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '17px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    'close-feed': {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    'close-feed': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
