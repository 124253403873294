import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, useTheme, useMediaQuery } from '@mui/material';

import { setActivityFeed } from '../../../store/ui/actions';
import { RootState } from '../../../store';
import useStyles from './styles';

import { ReactComponent as Arrow } from '../../../images/svg/forward.svg';
import { ReactComponent as Star } from '../../../images/svg/star.svg';
import { ReactComponent as StarSmall } from '../../../images/svg/star_small.svg';
import { getUpcomingBookings } from '../../../utils/booking';

interface HeaderProps {
  className?: string;
}

const Header = ({ className }: HeaderProps): React.ReactElement => {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state.ui.isActivityFeedOpen);
  const itemsAmount = useSelector(
    (state: RootState) =>
      state.activity.activities.length +
      (getUpcomingBookings(state.activity.bookings) || []).length
  );
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div
      className={cx(
        classes['header-container'],
        className,
        open ? 'open' : 'closed'
      )}
    >
      {!open && itemsAmount > 0 && (
        <div className={classes.number}>{itemsAmount}</div>
      )}
      <div className={cx(classes.header, open ? 'open' : 'closed')}>
        <div
          className={open ? classes['feed-open'] : classes['feed-closed']}
          onClick={() => dispatch(setActivityFeed(!open))}
        >
          <div>
            <div className={classes['small-star']}>
              <StarSmall />
            </div>

            <div className={classes['closed-button']}>
              {matchesMD && (
                <Button className={classes.button} color="inherit">
                  Ajankohtaista
                </Button>
              )}

              <Star />
            </div>
          </div>
          {open && (
            <div>
              <div style={{ height: '12px' }}></div>
              <Button
                className={classes.button}
                color="inherit"
                endIcon={<Arrow />}
              >
                Sulje
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
