import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  nav: {},
  container: {
    overflowY: 'hidden',
    flexGrow: 1,
  },
  ul: {
    display: 'flex',
    paddingLeft: '0px',
    flexWrap: 'wrap',
    rowGap: '3rem',
    '& li': {
      '&:first-child': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      width: 'unset',
      listStyleType: 'none',
      textDecoration: 'none',
      '&.selected-path': {
        background: theme.palette.secondary.light,
        borderRadius: '36px',
        fontWeight: 500,
      },
      '&.selected': {
        background: theme.palette.secondary.light,
        borderRadius: '36px',
      },
      '& span': {
        flex: 1,
        display: 'flex',
      },
    },
  },
}));

export default useStyles;
