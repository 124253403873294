import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 0px 0px 32px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down(400)]: {
      margin: '6px 0 16px 0',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      justifyContent: 'space-around',
      flexDirection: 'column',
    },
  },
  button: {
    border: 'transparent',
    color: theme.palette.secondary.main,
    display: 'flex',
    textAlign: 'center',
    width: 'auto',
    whiteSpace: 'nowrap',
    padding: '0px 16px',
    marginRight: '16px',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: '0px',
    },
    [theme.breakpoints.down(330)]: {
      padding: '0px 8px',
    },
  },
  paper: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '600px',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '26px',
    outline: 'none',
    '& .section': {
      marginTop: '32px',
    },
  },
}));

export default useStyles;
