import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Article from '../Content/Article';
import LoadingIndicator from '../../components/LoadingIndicator';
import { subscribeToContentIfAnonymous } from '../../utils/firebase';

const Accessibility = (): React.ReactElement => {
  const accessibilityPolicy = useSelector(
    (state: RootState) => state.content.globalContent?.accessibilityPage
  );

  useEffect(() => {
    subscribeToContentIfAnonymous();
  }, []);

  return accessibilityPolicy ? (
    <Article page={accessibilityPolicy} />
  ) : (
    <LoadingIndicator variant="medium" />
  );
};

export default Accessibility;
