import React, { useEffect, useState } from 'react';
import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown';
import { Document } from '@contentful/rich-text-types';

import RichText from '../RichText';

interface MarkdownProps {
  markdown: string;
}

/**
 * Component for rendering markdown (which is first transformed into rich text and then into react components)
 */
const MarkDown = ({ markdown }: MarkdownProps): React.ReactElement | null => {
  const [body, setBody] = useState<Document | null>(null);

  useEffect(() => {
    const renderRichText = async () => {
      // @ts-expect-error parameter passed to callback is annotated as 'MarkdownNode',
      // but the actual parameter received doesn't match that type.
      const doc = await richTextFromMarkdown(markdown, (node: never) => {
        if (node['type'] === 'image') {
          return {
            nodeType: 'embedded-asset-block',
            content: [],
            data: {
              image: {
                url: node['url'] || '',
                alt: node['alt'] || 'image',
              },
              target: {
                sys: {
                  type: 'Link',
                  linkType: 'Asset',
                  id: node['url'] || '',
                },
              },
            },
          };
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (node['type'] === 'html' && node['value'].includes('iframe')) {
            return {
              nodeType: 'iframe',
              content: [],
              data: {
                iframe: node['value'],
              },
            };
          }
        }

        return null;
      });

      setBody(doc);
    };

    renderRichText();
  }, [markdown]);

  return body ? <RichText body={body} /> : null;
};

export default MarkDown;
