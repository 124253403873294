import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  author: {
    backgroundColor: 'transparent',
    paddingLeft: '8px',
    paddingTop: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  avatar: {
    width: '70px',
    height: '70px',
    border: `solid ${theme.palette.info.main} 4px`,
  },
  'author-info': {
    paddingLeft: '14px',
    color: 'white',
  },
  'author-name': {
    // color: theme.palette.info.main,
  },
  'author-additional-info': {
    color: 'white',
    '& a': {
      color: 'white',
    },
    [theme.breakpoints.down(400)]: {
      width: '100%',
    },
  },
}));

export default useStyles;
