import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<boolean | undefined>()((theme, anonymous) => ({
  'field-heading': {
    marginBottom: '20px',
    fontSize: '28px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  'field-subheading': {
    marginBottom: '14.5px',
    fontFamily: 'Saol Display',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  'summary-heading': {
    marginBottom: '20px',
    fontFamily: 'Saol Display',
    fontSize: '22px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  'field-title': {
    textTransform: 'uppercase',
    marginBottom: '16px',
    fontWeight: 500,
  },
  'form-container': {
    position: 'relative' as const,
    background: theme.palette.white,
    padding: '24px',
    borderRadius: 0,
    flex: 1,
    marginRight: '32px',
    display: 'flex',
    flexDirection: 'column' as 'column' | 'row',
    [theme.breakpoints.down(theme.breakpoints.values.wide)]: {
      marginRight: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    minHeight: anonymous ? '100vh' : 'inherit',
    maxHeight: anonymous ? '100vh' : 'inherit',
  },
  step1: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      flex: 1,
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
  },
  step2: {
    '& .top-container': {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down(theme.breakpoints.values.smPlus)]: {
        flexDirection: 'column',
      },
    },
  },
  step3: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
  },
  step4: {
    display: 'flex',
    flexDirection: 'column',
    margin: '36px 6px 6px 6px',
  },
  'form-item': {
    marginBottom: '15px',
    '&.flex': {
      flex: 1,
    },
  },
  description: {
    fontSize: '16px',
    marginBottom: '17px',
  },
  summaryDescription: {
    marginBottom: '20px',
    fontSize: '20px',
  },
  avatar: {
    width: '140px',
    height: '140px',
    borderRadius: '100%',
    marginBottom: '20px',
    overflow: 'hidden',
    flex: '0 0 auto',
    '& img': {
      width: '140px',
      height: '140px%',
    },
  },
}));

export default useStyles;
