import { makeStyles } from 'tss-react/mui';
import { getColor } from '../Table/utils';

const useStyles = makeStyles<string | undefined>()((theme, color) => ({
  root: {
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: getColor(color, theme),
    borderColor: getColor(color, theme),
    padding: '0 2rem',
    borderRadius: '23px',
    height: '46px',
    color: theme.palette.primary.main,
    fontFamily: 'Scto Grotesk A',
    overflow: 'hidden',
    '&.calendar': {
      padding: 0,
      border: 0,
      borderRadius: 0,
      borderBottom: '1px solid',
      '& .MuiInputBase-input': {
        fontSize: '20px',
        opacity: 1,
        fontWeight: 400,
        padding: '10px 0 0 0 !important',
        color: theme.palette.primary.main,
      },
    },
    '& input[type="search"]': {
      color: theme.palette.secondary.main,
    },
  },
  input: {
    '&::placeholder': {
      color: theme.palette.primary.main,
    },
    color: theme.palette.primary.main,
  },
  dummy: {},
  focused: {
    borderColor: getColor(color, theme),
  },
  error: {
    borderBottom: '1px solid #B8383C !important',
    color: '#B8383C !important',
  },
}));

export default useStyles;
