import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  'field-heading': {
    marginBottom: '20px',
    fontSize: '28px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  'field-title': {
    textTransform: 'uppercase',
    marginBottom: '16px',
    fontWeight: 500,
  },
  'summary-container': {
    width: '100%',
    marginBottom: '30px',
    '&.compact': {
      marginBottom: '0px',
    },
  },
  'form-item': {
    marginBottom: '16px',
  },
  cardSummary: {
    position: 'absolute',
  },
}));

export default useStyles;
