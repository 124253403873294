import {
  RahamyllyLoanReport,
  RahamyllyLoanStatus,
} from '../../../types/rahamylly';
import { ActionTypes, Actions, CorporateFinancingState } from './types';

export function setInvestments(
  investments: CorporateFinancingState['investments']
): Actions {
  return {
    type: ActionTypes.SET_INVESTMENTS,
    payload: investments,
  };
}

export function setBalance(
  balance: CorporateFinancingState['balance']
): Actions {
  return {
    type: ActionTypes.SET_BALANCE,
    payload: balance,
  };
}

export function setLoanStatus(status: RahamyllyLoanStatus[]): Actions {
  return {
    type: ActionTypes.SET_LOAN_STATUSES,
    payload: status,
  };
}

export function addLoanStatus(status: RahamyllyLoanStatus): Actions {
  return {
    type: ActionTypes.ADD_LOAN_STATUS,
    payload: status,
  };
}

export function addLoanReports(reports: RahamyllyLoanReport[]): Actions {
  return {
    type: ActionTypes.ADD_LOAN_REPORTS,
    payload: reports,
  };
}

export function setBalanceTimestamp(
  timestamp: CorporateFinancingState['balanceTimestamp']
): Actions {
  return {
    type: ActionTypes.SET_BALANCE_TIMESTAMP,
    payload: timestamp,
  };
}

export function setInvestmentsTimestamp(
  timestamp: CorporateFinancingState['investmentsTimestamp']
): Actions {
  return {
    type: ActionTypes.SET_INVESTMENTS_TIMESTAMP,
    payload: timestamp,
  };
}

export function setAccount(
  account: CorporateFinancingState['account']
): Actions {
  return {
    type: ActionTypes.SET_ACCOUNT,
    payload: account,
  };
}

export function setCashBalance(
  cashBalance: CorporateFinancingState['cashBalance']
): Actions {
  return {
    type: ActionTypes.SET_CASH_BALANCE,
    payload: cashBalance,
  };
}

export function setScheduledWithdrawalAmount(
  scheduledWithdrawalAmount: CorporateFinancingState['scheduledWithdrawalAmount']
): Actions {
  return {
    type: ActionTypes.SET_SCHEDULED_WITHDRAWAL_AMOUNT,
    payload: scheduledWithdrawalAmount,
  };
}

export function setDepositInfo(
  depositInfo: CorporateFinancingState['depositInfo']
): Actions {
  return {
    type: ActionTypes.SET_DEPOSIT_INFO,
    payload: depositInfo,
  };
}
