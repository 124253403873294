import { StructuredInvestment } from '../../../types/contentful';
import { FaPosition, FaTransaction } from '../../../types/FA';
import { FSReport, FSStructureSecurityData } from '../../../types/firestore';
import { ChangeUser, Logout } from '../../base';

export enum ActionTypes {
  SET_OVERVIEW = 'SET_STRUCTURES_OVERVIEW',
  SET_TRANSACTION = 'SET_STRUCTURES_TRANSACTION',
  SET_REPORT = 'SET_REPORT',
  CLEAR_STRUCTURES = 'CLEAR_STRUCTURES',
  SET_SECURITY = 'SET_SECURITY',
  ADD_SECURITY = 'ADD_SECURITY',
  SET_STRUCTURES_SECURITY_INFO = 'SET_STRUCTURES_SECURITY_INFO',
  ADD_STRUCTURES_SECURITY_INFO = 'ADD_STRUCTURES_SECURITY_INFO',
}

export interface StructuresState {
  positions: FaPosition[] | null;
  transactions: FaTransaction[] | null;
  reports: FSReport[] | null;
  securities: FSStructureSecurityData[] | null;
  securityInfo: StructuredInvestment[] | null;
}

interface SetOverviewPositions {
  type: typeof ActionTypes.SET_OVERVIEW;
  payload: StructuresState['positions'];
}

interface SetStructureTransactions {
  type: typeof ActionTypes.SET_TRANSACTION;
  payload: StructuresState['transactions'];
}

interface SetStructureReports {
  type: typeof ActionTypes.SET_REPORT;
  payload: StructuresState['reports'];
}

interface ClearStructures {
  type: typeof ActionTypes.CLEAR_STRUCTURES;
}

interface SetStructureSecurity {
  type: typeof ActionTypes.SET_SECURITY;
  payload: StructuresState['securities'];
}

interface AddStructureSecurity {
  type: typeof ActionTypes.ADD_SECURITY;
  payload: StructuresState['securities'];
}

interface SetStructureSecurityInfo {
  type: typeof ActionTypes.SET_STRUCTURES_SECURITY_INFO;
  payload: StructuresState['securityInfo'];
}

interface AddStructureSecurityInfo {
  type: typeof ActionTypes.ADD_STRUCTURES_SECURITY_INFO;
  payload: StructuresState['securityInfo'];
}

export type Actions =
  | Logout
  | ChangeUser
  | SetOverviewPositions
  | SetStructureTransactions
  | SetStructureReports
  | ClearStructures
  | SetStructureSecurity
  | AddStructureSecurity
  | SetStructureSecurityInfo
  | AddStructureSecurityInfo;
