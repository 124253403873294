import React, { forwardRef } from 'react';
import Input, { InputProps } from '@mui/material/Input';

import useStyles from './styles';

const TextField = forwardRef((props: InputProps, ref): React.ReactElement => {
  const { color } = props;
  const { classes } = useStyles(color);
  return (
    <>
      <Input disableUnderline={true} classes={classes} {...props} ref={ref} />
    </>
  );
});

TextField.displayName = 'TextField';

export default TextField;
