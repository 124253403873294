import React, { ReactElement } from 'react';
import { Link, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const LoginHelp = (): ReactElement => {
  const { classes } = useStyles();

  return (
    <Link
      href={'/static/pdfs/kirjautumisohje.pdf'}
      target={'_blank'}
      classes={{ root: classes.link }}
      id="login-help-link"
    >
      Avaa tästä ohje Omasalkun käyttöönottoon
    </Link>
  );
};

export default LoginHelp;
