import React, { useReducer } from 'react';
import { Link } from '@mui/material';
import Header from './Header';
import LoginBox from './LoginBox';
import Welcome from './Welcome';

import useStyles from './styles';
import Modal from '../../components/Modal';
import { FooterInfo } from '../../components/Footer';

interface ModalState {
  modalOpen: boolean;
  modalState: string;
  content: React.ReactElement;
}

export type ModalAction =
  | { type: 'open' }
  | { type: 'close' }
  | { type: 'clear' }
  | { type: 'set-content'; payload: React.ReactElement };

function reducer(state: ModalState, action: ModalAction): ModalState {
  switch (action.type) {
    case 'open':
      return { ...state, modalOpen: true };
    case 'close':
      return { ...state, modalOpen: false };
    case 'clear':
      return { ...initialState() };
    case 'set-content':
      return { ...state, content: action.payload };
    default:
      return state;
  }
}

const initialState = (modalOpen?: boolean): ModalState => ({
  modalOpen: modalOpen || false,
  modalState: 'input',
  content: <></>,
});

export const Login = (): React.ReactElement => {
  const { classes } = useStyles();

  const [state, dispatch] = useReducer(reducer, initialState(false));

  function handleOnClose() {
    dispatch({ type: 'close' });
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Header />
        <div className={classes['content-container']}>
          <Welcome />
          <LoginBox />
        </div>
        <div className={classes.footer}>
          <div className="legal">
            <Link href="/yleista/tietosuoja">Tietosuoja</Link>
            <Link href="/yleista/kayttoehdot">Käyttöehdot</Link>
            <Link href="/yleista/saavutettavuus">Saavutettavuus</Link>
          </div>
          <FooterInfo />
        </div>
      </div>
      <Modal open={state.modalOpen} onClose={handleOnClose}>
        {state.content}
      </Modal>
    </div>
  );
};
