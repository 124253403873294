import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from '../styles';
import { ReactComponent as AlexandriaLogo } from '../../../images/svg/alexandria_header_sin.svg';

const Logo = (): React.ReactElement | null => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <button
      className={classes.logo}
      onClick={() => navigate('/')}
      aria-label="Etusivulle"
    >
      <AlexandriaLogo />{' '}
    </button>
  );
};

export default Logo;
