import { FaMarketData, FaPosition, FaTransaction, FaSecurityInfo } from './FA';
import { PortfolioPosition, Transaction as FundTransaction } from './difs';
import { InsuranceOverview, InsurancePosition, Report } from './abc';
import { IUpdateTypes } from './api';
import { UtmostReport } from './utmost';

/** The used user groups from Azure AD */
export enum FSGroups {
  admin = 'Extranetadmin',
  superAdvisor = 'Omasalkku_Backoffice',
  advisor = 'Neuvonantajat',
}

export interface FSReport {
  fileName: string;
  filePath: string;
  status: 'generating' | 'ready' | 'error';
}

/**
 * Overview positions from FireStore
 */
export interface FSOverviewPosition {
  positions: FaPosition[];
}

export interface FSStructureTransaction {
  transactions: FaTransaction[];
}

export interface FSStructureReport {
  reports: FSReport[];
}

export interface FSStructureSecurityData {
  marketdata: FaMarketData[];
  security: FaSecurityInfo;
}
export interface FSStructureSecurities {
  securities: FSStructureSecurityData[];
}

export interface FSPortfolioPosition {
  positions: PortfolioPosition[];
}

export interface FSFundTransaction {
  transactions: FundTransaction[];
}

export interface FSInsuranceOverview {
  positions: InsuranceOverview[];
}

export interface FSInsurancePosition {
  positions: InsurancePosition[];
}

export interface FSReports {
  reports: Report[];
  t?: number;
}

/**
 * User from FireStore
 */
export interface FSUser {
  hetu: string;
  eid?: string;
  etunimet: string;
  sukunimi: string;
  kokonimi: string;
  neuvonantajaID: number;
  toimistoID: number;
  groups?: string[];
  accessToUsers?: string[];
  suostumusAsiakasetuohjelmaan?: boolean;
  asiakasStatus?: CustomerStatus;
  kannustinluokka?: number;
  accessToUsersUpdateTime?: number;
  userFinishedRegistrationTS?: number;
  hasAllRequiredInfo?: boolean;
  sahkopostiosoite: string;
  puhelinnumero?: string;
  asiakasnumero?: number;
  oikeustoimikelpoinen: boolean;
  uid: string;
  hash?: string;
  keywords: string[];
  hasAcceptedNewestTOS: boolean;
  katuosoite: string;
  postinumero: string;
  postitoimipaikka: string;
  maa: string; // empty string for finland
  maakoodi: string; // ISO 3166-1 A2
  kotikuntanimi: string;
  kotikuntakoodi: string;
  email?: string;
}

export enum CustomerStatus {
  noInfo = 0,
  notContacted = 1,
  contacted = 2,
  reached = 3,
  upcomingOnboarding = 4,
  reservedOnboarding = 5,
  completedOnboarding = 6,
  reservedMeeting = 7,
  completedMeeting = 8,
  hadMeetingNotSurveyed = 9,
  hadMeetingIsSurveyed = 10,
  withNoInvestments = 11,
  withInvestments = 12,
  withInvestmentsDuringPastYear = 13,
  withInvestmentsDuringPast2Years = 14,
  withInvestmentsSincePast2Years = 15,
}

export interface ADGroup {
  displayName: string;
  name: string;
  id: string;
}

export interface FSAdvisor {
  Koulutukset: string;
  NeuvonantajaID: number;
  NeuvonantajaNimi: string;
  Puhelinnumero: string;
  Sahkopostiosoite: string;
  Titteli: string;
  TittelinTarkenne: string;
  ToimistoID: number;
  Kanava_Puhelin: boolean;
  Kanava_Toimisto: boolean;
  Kanava_Video: boolean;
  Kuva?: string;
}

export type DataUpdateType = {
  updating: boolean;
  t?: number;
};

export type DataUpdates = {
  [k in keyof IUpdateTypes]: DataUpdateType;
};

export interface AuthUserMetadata {
  lastSignInTime: string;
  creationTime: string;
}

export interface AuthUserProviderData {
  uid: string;
  email: string;
  providerId: string;
}

/**
 * Interface representing a user in firebase auth.
 */
export interface AuthUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  disabled: boolean;
  displayName?: string;
  metadata: AuthUserMetadata;
  tokensValidAfterTime: string;
  providerData: AuthUserProviderData[];
  groups?: string[];
}

export type FsUtmostReports = {
  reports: UtmostReport[];
  updatedAt: number;
};
