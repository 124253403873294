import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { checkIsAlexandriaEmployee } from '../utils/firebase';

/**
 * Check if user has all required info, always returns true if user is Alexandria employee
 * @returns boolean
 */
export const useHasAllRequiredInfo = (): boolean => {
  const user = useSelector((state: RootState) => state.user.fsUser);
  return checkIsAlexandriaEmployee(user) || !!user?.hasAllRequiredInfo;
};
