import { Location } from 'history';
import { NavSpec } from '../types/local';
import { FSGroups } from '../types/firestore';

export const FullNav: NavSpec[] = [
  {
    to: '/',
    label: 'Yhteenveto',
  },
  {
    to: '/sijoitukset',
    label: 'Sijoitukset',
    children: [
      {
        to: '/rahastot',
        label: 'Rahastot',
        children: [
          {
            to: '/omistukset',
            label: 'Omistukset',
          },
          {
            to: '/tapahtumat',
            label: 'Tapahtumat',
          },
        ],
      },
      {
        to: '/strukturoidut',
        label: 'Strukturoidut',
        children: [
          {
            to: '/omistukset',
            label: 'Omistukset',
          },
          {
            to: '/tapahtumat',
            label: 'Tapahtumat',
          },
          {
            to: '/tuotonmaksut',
            label: 'Tuotonmaksut',
          },
        ],
      },
      {
        to: '/vakuutukset',
        label: 'Vakuutukset',
        children: [
          {
            to: '/omistukset',
            label: 'Omistukset',
          },
        ],
      },
      {
        to: '/yrityslainat',
        label: 'Yrityslainat',
        children: [
          {
            to: '/omistukset',
            label: 'Omistukset',
          },
          {
            to: '/tapahtumat',
            label: 'Tapahtumat',
          },
          {
            to: '/siirrot',
            label: 'Siirrot',
          },
          {
            to: '/hallitse',
            label: 'Tee nostopyyntö',
          },
        ],
      },
    ],
  },
  {
    to: '/raportit',
    label: 'Raportit',
  },
  {
    to: '/varaa_aika',
    label: 'Varaa aika',
  },
  {
    to: '/omat_tiedot',
    label: 'Omat tiedot',
    hideIfAdUserAndNotImpersonating: true,
    hideIfImpersonating: true,
    children: [
      {
        to: '/tietamystestit',
        label: 'Tietämystestit',
        hideIfImpersonating: true,
      },
      {
        to: '/asiakkuus',
        label: 'Asiakkuus',
      },
    ],
  },
  { to: '/sisallot', label: 'Sisällöt' },
  { to: '/etuohjelma', label: 'Etuohjelma' },
  { to: '/ota_yhteytta', label: 'Ota yhteyttä' },
  {
    to: '/sijoita',
    label: 'Sijoita',
    children: [
      {
        to: '/rahastot',
        label: 'Rahastot',
      },
      {
        to: '/strukturoidut',
        label: 'Strukturoidut',
      },
    ],
  },
  {
    to: '/hallinta',
    label: 'Hallinta',
    requiredRoles: [FSGroups.admin],
  },
].filter(Boolean) as NavSpec[];

export const HorizontalNav: NavSpec[] = [
  {
    // This element exists for responsiveness
    // Navi items may wrap away from view automatically
    to: '/',
    label: '',
  },
  {
    to: '/sijoitukset',
    label: 'Sijoitukset',
    children: [
      {
        to: '/rahastot',
        label: 'Rahastot',
      },
      {
        to: '/strukturoidut',
        label: 'Strukturoidut',
      },
      {
        to: '/vakuutukset',
        label: 'Vakuutukset',
      },
      {
        to: '/yrityslainat',
        label: 'Yrityslainat',
      },
    ],
  },
  {
    to: '/raportit',
    label: 'Raportit',
  },
  {
    to: '/varaa_aika',
    label: 'Varaa aika',
  },
  {
    to: '/omat_tiedot',
    label: 'Omat tiedot',
    hideIfAdUserAndNotImpersonating: true,
    hideIfImpersonating: true,
  },
  { to: '/etuohjelma', label: 'Etuohjelma' },
  { to: '/ota_yhteytta', label: 'Ota yhteyttä' },
];

/**
 * Returns child navigation relative to the current location
 */
export const getRelativeNav = (location: Location<unknown>): NavSpec[] => {
  const paths = location.pathname.slice(1).split('/');

  const childNav = paths.reduce((prev, current) => {
    const filtered = prev.filter((n) => n.to.includes('/' + current));

    if (filtered.length > 0 && filtered[0].children !== undefined) {
      const children = filtered[0].children.map((c) => {
        return {
          ...c,
          to: filtered[0].to + c.to,
        };
      });
      return children;
    }
    return prev;
  }, FullNav);

  return childNav;
};

export const filterByIncentiveClassStatus = (
  incentiveClassActive: boolean,
  nav: NavSpec[]
): NavSpec[] => {
  if (incentiveClassActive) return nav;
  return nav.filter((navItem) => !['Etuohjelma'].includes(navItem.label));
};

const truthy = <T>(value: T): value is NonNullable<T> => !!value;

export const findByLocation = (location: string): NavSpec[] =>
  [FullNav.find((nav) => nav.to === location)].filter(truthy);
